import React, { useContext, useEffect, useState } from "react";
import { GridActionsCellItem, GridValueGetterParams } from "@mui/x-data-grid";
import SearchForm from "../../../purchase/FormviewPay";
import { AuthContext, useAuth } from "../../../../middlewares/auth";
import DataTable from "../../../../components/DataTable/DataTable";
import HttpService from "../../../../services/http";
import { usePanelPage } from "../../panelPage";
import { Modal } from "react-bootstrap";
import PatientAppointment from "./components/PatientAppointment";
import SearchPatient from "./components/FormAppointmentPatient";

const PanelPatient = () => {
  const [showNew, setShowNew] = useState(false);
  const { state } = useAuth();
  const { specialistSelected } = usePanelPage();
  const [idSpecialist, setIdSpecialist] = useState<number | null | undefined>(
    state?.specialist ? state?.id : state?.secretary ? specialistSelected : null
  );
  const [selectedParams, setSelectedParams] = useState<any>(null);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState<{
    field: string;
    value: string;
  } | null>(null);
  function formaterNumberToCurrency(number: number | string): string {
    const formatter = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 2,
    });

    return formatter.format(Number(number));
  }
  useEffect(() => {
    const fetchData = async () => {
      const info = {
        specialistId: idSpecialist,
      };
      try {
        const response = await HttpService.post(
          "specialists/getPatientsAppointment",
          info
        );
        const { data } = response;
        setOriginalData(data);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching payment data:", error);
      }
    };

    fetchData();
  }, [idSpecialist]);
  useEffect(() => {
    setIdSpecialist(
      state?.specialist
        ? state?.id
        : state?.secretary
        ? specialistSelected
        : null
    );
  }, [state?.specialist, state?.secretary, specialistSelected]);
  const columns = [
    {
      field: "documentType.name",
      headerName: "tipo de documento",
      width: 250,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.documentType.name}`;
      },
    },

    { field: "document", headerName: "documento", width: 250 },
    {
      field: "first_name",
      headerName: "nombres y apellidos",
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.first_name} ${params.row.last_name}`;
      },
    },
    {
      field: "email",
      headerName: "Correo Electrónico",
      width: 250,
    },
    {
      field: "phone",
      headerName: "Télefono",
      width: 250,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Citas",
      width: 80,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={
            <i
              className="bi bi-card-list"
              style={{ fontSize: "50px", color: "#0b4e8a" }}
            />
          }
          label="Citas"
          onClick={() => {
            setShowNew(true);
            setSelectedParams(params.row);
          }}
        />,
      ],
    },
  ];
  const handleSearch = ({ field, value }: { field: string; value: string }) => {
    const newFilteredData = originalData.filter(
      (item: { [x: string]: string }) =>
        (item[field] as string)
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
    );

    setFilteredData(newFilteredData);
    setSearchCriteria({ field, value });
  };

  const handleClose = () => {
    setShowNew(false);
    setSelectedParams(null); // Limpiar los datos del parámetro cuando se cierra el modal
  };
  const modalAppointments = (params: any) => {
    return (
      <Modal
        centered
        show={showNew}
        onHide={handleClose}
        className="modalAppointmentPatients"
      >
        <Modal.Header closeButton>
          <Modal.Title>Citas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showNew && (
            <PatientAppointment data={params} handleAction={handleClose} />
          )}{" "}
          {/* Pasar los datos del parámetro al componente del modal */}
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <div className="data-table-view-pay m-5">
        <div className="container-form">
          <SearchPatient
            idSpecialist={idSpecialist}
            setFilteredData={setFilteredData}
          />
        </div>
        <DataTable
          columns={columns}
          data={filteredData}
          width="80%"
          height="80%"
        />
      </div>
      {modalAppointments(selectedParams)}
    </>
  );
};

export default PanelPatient;
