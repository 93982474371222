import { useContext, useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row, Table } from "react-bootstrap";
import ReactQuill from "react-quill";
import { IWarning, Message } from "../../../../../components/message";
import { useFetchProceduresTypes } from "../../../../../hooks/useFetchProceduresTypes";
import {
  IImageVideo,
  IProcedure,
  emptyProcedure,
} from "../../../../procedure/interface";
import useFetchProcedure from "../hooks/useFetchProcedure";
import { PanelProcedureContext } from "../panelProcedures";

import HttpService from "../../../../../services/http";

import "react-quill/dist/quill.snow.css";

function EditorContainer(): JSX.Element {
  const {
    editMode,
    setEditMode,
    idProcedure: id,
    setIdProcedure,
  }: {
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    idProcedure: string | undefined;
    setIdProcedure: (idProcedure: string | undefined) => void;
  } = useContext(PanelProcedureContext);

  const [refreshProcedure, setRefreshProcedure] = useState<boolean>(false);
  const { procedure, setProcedure, loading, setLoading, error } =
    useFetchProcedure(id, editMode, refreshProcedure);
  const { types, loading: loadingTypes } = useFetchProceduresTypes();

  const [imageToLoad, setImageToLoad] = useState({
    image: undefined as File | undefined,
    name: "",
    main: false,
  });

  const [images, setImages] = useState<IImageVideo[]>(procedure?.images || []);
  const [videos, setVideos] = useState<IImageVideo[]>(procedure?.video || []);

  const [warning, setWarning] = useState<IWarning>({
    show: false,
    message: "",
  });

  useEffect(() => {
    if (error) {
      setEditMode(false);
      setIdProcedure(undefined);
    }
  }, [error]);

  useEffect(() => {
    const { image, main } = imageToLoad;
    if (image) {
      if (main) handleSaveImageProcedure();
      else handleSaveImageGallery();
    }
  }, [imageToLoad?.image]);

  useEffect(() => {
    setImages(procedure?.images || []);
    setVideos(procedure?.video || []);
  }, [procedure?.images, procedure?.video]);

  function handleSave() {
    setLoading(true);
    const info: IProcedure = { ...emptyProcedure, ...procedure };
    if (doValidation(info)) {
      HttpService.post("procedures/setProcedure", {
        data: { ...info },
      })
        .then((result) => {
          const { status, data } = result;
          if (status) {
            if (videos) {
              Promise.all(getAllPromiseVideo())
                .then((results) => {
                  const allOk = results?.every((result) => result.status);
                  if (allOk)
                    setWarning({
                      show: true,
                      message: "Almacenamiento exitoso.",
                    });
                  else
                    setWarning({
                      show: true,
                      message:
                        "Se almacenaron los registros pero con algunos problemas.",
                    });
                  setLoading(false);
                  setRefreshProcedure((prev) => !prev);
                })
                .catch(() => {
                  setWarning({
                    show: true,
                    message:
                      "No se pudo guardar la información del procedimiento.",
                  });
                  setLoading(false);
                });
            } else {
              setLoading(false);
              setIdProcedure(data?.idProcedure);
              setWarning({ show: true, message: "Almacenamiento exitoso." });
              setRefreshProcedure((prev) => !prev);
            }
          } else
            setWarning({
              show: true,
              message: "No se pudo guardar la información del procedimiento.",
            });
        })
        .catch(() => {
          setWarning({
            show: true,
            message: "No se pudo guardar la información del procedimiento.",
          });
          setLoading(false);
        });
    } else setLoading(false);
  }

  function doValidation(info: IProcedure) {
    function isEmptyString(string: string | null | undefined) {
      return !string || string === "";
    }

    function isEmptySelect(option: number | undefined) {
      return option === -1 || !option;
    }

    if (isEmptyString(info?.name)) displayWarning("Debe darle un nombre.");
    else if (isEmptySelect(info?.procedure_type))
      displayWarning("Debe seleccionar el tipo.");
    else return true;
  }

  function handleSaveImageProcedure() {
    setLoading(true);
    handleSaveImageLoad()
      .then((result) => {
        setImageToLoad({
          image: undefined as File | undefined,
          name: "",
          main: false,
        });
        const { status, data } = result;
        if (status) {
          HttpService.post("procedures/setProcedureImage", {
            id,
            image: data,
          })
            .then((result) => {
              setLoading(false);
              const { status } = result;
              if (status) {
                setWarning({ show: true, message: "Imagen guardada." });
                setRefreshProcedure((prev) => !prev);
              } else
                setWarning({
                  show: true,
                  message: "No se pudo guardar la imagen.",
                });
            })
            .catch(() => {
              setWarning({
                show: true,
                message: "No se pudo guardar la imagen.",
              });
              setLoading(false);
            });
        } else
          setWarning({ show: true, message: "No se pudo guardar la imagen." });
      })
      .catch(() => {
        setWarning({
          show: true,
          message: "No se pudo guardar la imagen.",
        });
        setLoading(false);
      });
  }

  async function handleSaveImageLoad() {
    const apiResponse = await HttpService.post(
      "procedures/setProcedure/image",
      {
        image: imageToLoad?.image,
        name: imageToLoad?.name,
        id,
      },
      { ["Content-Type"]: "multipart/form-data;" }
    );
    return apiResponse;
  }

  function getAllPromiseVideo() {
    return videos?.map((video) => handleSaveGallery({ video }));
  }

  function handleSaveImageGallery() {
    setLoading(true);
    handleSaveImageLoad()
      .then((result) => {
        const { status, data } = result;
        const image: IImageVideo = {
          name: imageToLoad?.name,
          url: data,
        };
        setImageToLoad({
          image: undefined as File | undefined,
          name: "",
          main: false,
        });
        if (status) {
          handleSaveGallery({ image })
            .then((result) => {
              const { status } = result;
              setLoading(false);
              if (status) {
                setWarning({ show: true, message: "Imagen guardada." });
                setRefreshProcedure((prev) => !prev);
              } else
                setWarning({
                  show: true,
                  message: "No se pudo guardar la imagen.",
                });
            })
            .catch(() => {
              setWarning({
                show: true,
                message: "No se pudo guardar la imagen.",
              });
              setLoading(false);
            });
        } else
          setWarning({ show: true, message: "No se pudo guardar la imagen." });
      })
      .catch(() => {
        setWarning({
          show: true,
          message: "No se pudo guardar la imagen.",
        });
        setLoading(false);
      });
  }

  async function handleSaveGallery(data: {
    image?: IImageVideo | undefined;
    video?: IImageVideo | undefined;
  }) {
    const apiResponse = await HttpService.post(
      "procedures/setProcedureGallery",
      {
        image: data?.image,
        video: data?.video,
        id,
      }
    );
    return apiResponse;
  }

  async function handleDeleteGallery(data: {
    image?: {
      id: number;
    };
    video?: {
      id: number;
    };
  }) {
    const apiResponse = await HttpService.post(
      "procedures/deleteProcedureGallery",
      {
        image: data?.image,
        video: data?.video,
        id,
      }
    );
    return apiResponse;
  }

  function deleteImageGallery(idImage: number) {
    if (idImage !== -1) {
      setLoading(true);
      const image = {
        id: idImage,
      };
      handleDeleteGallery({ image })
        .then((result) => {
          const { status } = result;
          setLoading(false);
          if (status) {
            setWarning({ show: true, message: "Imagen eliminada." });
            setRefreshProcedure((prev) => !prev);
          } else
            setWarning({
              show: true,
              message: "No se pudo eliminar la imagen.",
            });
        })
        .catch(() => {
          setWarning({
            show: true,
            message: "No se pudo eliminar la imagen.",
          });
          setLoading(false);
        });
    }
  }

  function deleteVideoGallery(idVideo: number) {
    if (idVideo !== -1) {
      setLoading(true);
      const video = {
        id: idVideo,
      };
      handleDeleteGallery({ video })
        .then((result) => {
          const { status } = result;
          setLoading(false);
          if (status) {
            setWarning({ show: true, message: "Video eliminado." });
            setRefreshProcedure((prev) => !prev);
          } else
            setWarning({
              show: true,
              message: "No se pudo eliminar el video.",
            });
        })
        .catch(() => {
          setWarning({
            show: true,
            message: "No se pudo eliminar el video.",
          });
          setLoading(false);
        });
    }
  }

  function displayWarning(message = "") {
    setWarning({ show: true, message });
  }

  return (
    <>
      <Container hidden={!editMode}>
        <Row>
          <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
            <InputGroup>
              <InputGroup.Text
                onClick={() => {
                  if (!loading && !loadingTypes) {
                    setEditMode(false);
                    setIdProcedure(undefined);
                  }
                }}
              >{`<`}</InputGroup.Text>
              <InputGroup.Text>{`Procedimientos > Nuevo`}</InputGroup.Text>
            </InputGroup>
          </Col>
          <Col
            xs={{ offset: 6, span: 3 }}
            sm={{ offset: 6, span: 3 }}
            md={{ offset: 6, span: 3 }}
            lg={{ offset: 6, span: 3 }}
            xl={{ offset: 6, span: 3 }}
            xxl={{ offset: 6, span: 3 }}
          >
            <button
              disabled={loading || loadingTypes || id === "-1"}
              onClick={() => {
                const url = `${process.env.REACT_APP_URL_BASENAME}/procedure/${procedure.id}`;
                window.open(url, "_blank");
              }}
            >
              Previsualizar
            </button>
            <button disabled={loading || loadingTypes} onClick={handleSave}>
              Guardar
            </button>
          </Col>
        </Row>
        <div className="border-divider" />
        <Row>
          <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
            <Form.Label>Nombre:</Form.Label>
            <Form.Control
              type="text"
              disabled={loading || loadingTypes}
              value={procedure?.name || ""}
              onChange={(e) =>
                setProcedure((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
            <Form.Label>Tipo:</Form.Label>
            <Form.Select
              disabled={loading || loadingTypes}
              value={procedure?.procedure_type || -1}
              onChange={(e) =>
                setProcedure((prev) => ({
                  ...prev,
                  procedure_type: Number(e.target.value),
                }))
              }
            >
              <option value={-1}>Seleccione</option>
              {types.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Form.Select>
          </Col>
          {id ? (
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <Form.Label>Imagen</Form.Label>
              <Form.Control
                type="file"
                accept="image/png, image/jpeg"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const { files } = event.target;
                  const selectedFiles = files as FileList;
                  setImageToLoad({
                    image: selectedFiles[0] || undefined,
                    name: selectedFiles[0]?.name || procedure?.name || "",
                    main: true,
                  });
                  event.target.value = "";
                }}
              />
              {procedure?.image ? <Form.Label>Ver</Form.Label> : null}
            </Col>
          ) : null}
        </Row>
        <br />
        <Row>
          <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Row>
              <Form.Label>Descripción</Form.Label>
              <ReactQuill
                theme="snow"
                readOnly={loading || loadingTypes}
                value={procedure?.descripcion || ""}
                onChange={(value) =>
                  setProcedure((prev) => ({
                    ...prev,
                    descripcion: value,
                  }))
                }
              />
            </Row>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Row>
              <InputGroup>
                <InputGroup.Text>Imagenes</InputGroup.Text>
                {id ? (
                  <InputGroup.Text
                    onClick={() => {
                      if (!loading || !loadingTypes)
                        setImages((prevImages) => [
                          ...prevImages,
                          {
                            id: -1,
                            name: "",
                            procedure_id: Number(id),
                            url: "",
                          },
                        ]);
                    }}
                  >
                    +
                  </InputGroup.Text>
                ) : null}
              </InputGroup>
              <Table responsive bordered striped>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  {images?.map((image, indexImage) => (
                    <tr key={indexImage}>
                      <td>
                        {image?.id === -1 ? (
                          <Form.Control
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const { files } = event.target;
                              const selectedFiles = files as FileList;
                              if (selectedFiles) {
                                const file = selectedFiles[0];
                                const fileName = file?.name;
                                const fileExtension = fileName
                                  ?.split(".")
                                  ?.pop();
                                const fileNameWithoutExtension = fileName
                                  ?.split(".")
                                  ?.slice(0, -1)
                                  ?.join(".");

                                setImageToLoad({
                                  image: file,
                                  name: `${fileNameWithoutExtension}_${indexImage}.${fileExtension}`,
                                  main: false,
                                });
                              }
                              event.target.value = "";
                            }}
                            disabled={loading || loadingTypes}
                          />
                        ) : (
                          <Form.Label>{image?.name}</Form.Label>
                        )}
                      </td>
                      <td>
                        <button
                          disabled={loading || loadingTypes}
                          onClick={() => deleteImageGallery(image?.id || -1)}
                        >
                          X
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
            <Row>
              <InputGroup>
                <InputGroup.Text>Videos</InputGroup.Text>
                {id ? (
                  <InputGroup.Text
                    onClick={() => {
                      if (!loading || !loadingTypes)
                        setVideos((prevVideos) => [
                          ...prevVideos,
                          {
                            id: -1,
                            name: "",
                            procedure_id: Number(id),
                            url: "",
                          },
                        ]);
                    }}
                  >
                    +
                  </InputGroup.Text>
                ) : null}
              </InputGroup>
              <Table responsive bordered striped>
                <thead>
                  <tr>
                    <th>Url</th>
                    <th>Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  {videos?.map((video, indexVideo) => (
                    <tr key={indexVideo}>
                      <td>
                        {video?.id === -1 ? (
                          <Form.Control
                            type="url"
                            disabled={loading || loadingTypes}
                            value={video?.url || ""}
                            onChange={(event) => {
                              const { value } = event.target;
                              setVideos((prevVideos) => {
                                const copy = [...prevVideos];
                                copy[indexVideo].url = value;
                                return copy;
                              });
                            }}
                          />
                        ) : (
                          <Form.Label>{video?.url}</Form.Label>
                        )}
                      </td>
                      <td>
                        <button
                          disabled={loading || loadingTypes}
                          onClick={() => deleteVideoGallery(video?.id || -1)}
                        >
                          X
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </Col>
        </Row>
      </Container>
      {warning?.show ? (
        <Message
          show={warning.show}
          hide={() => setWarning((prev) => ({ ...prev, show: false }))}
          message={warning?.message}
        />
      ) : null}
    </>
  );
}

export default EditorContainer;
