import { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { IMessage, IWarning, Message } from "../../../../components/message";
import { useAuth } from "../../../../middlewares/auth";
import HttpService from "../../../../services/http";
import { Patient } from "../../../../types/patient";
import useFetchSpecialist from "../../../specialist/hooks/useFetchSpecialist";
import { usePanelPage } from "../../panelPage";
import GenderSection from "./components/list/GenderSection";
import LanguageSection from "./components/list/LanguageSection";
import RewardSection from "./components/list/RewardSection";
import SocialMediaSection from "./components/list/SocialMediaSection";
import StudySection from "./components/list/StudySection";
import useFetchGender from "./components/list/hooks/useFetchGender";
import useFetchLanguage from "./components/list/hooks/useFetchLanguage";
import SelectOptions from "./components/list/SelectOptions";
import useFetchGetListProcedure, {
  IOptions,
} from "./components/list/hooks/useFetchProcedures";
import {
  ILanguage,
  IProcedure,
  IReward,
  IStudy,
  INetwork
} from "../../../../interfaces/specialist/specialist.interface";
import ReactQuill from "react-quill";
import useFetchSocialMedia from "./components/list/hooks/useFetchSocialMedia";
import Loader from "../../../../components/Loader";

interface IProfileProps {
  hidden: boolean;
}
function PatientProfile(): JSX.Element {
  const { state } = useAuth();

  const [patient, setPatient] = useState<Patient>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    document: "",
  });
  const [sending, setSending] = useState<boolean>(false);
  const [warning, setMensaje] = useState<IWarning>({
    show: false,
    message: "",
  });

  useEffect(() => {
    HttpService.get(`patients/getPatient/${state?.userId}`).then((result) => {
      if (result?.status) setPatient(result?.data as Patient);
    });
  }, [state?.isLoged, state?.userId]);

  async function handleSave() {
    if (
      patient?.first_name?.trim() === "" ||
      patient?.last_name?.trim() === "" ||
      patient?.phone?.trim() === ""
    ) {
      setMensaje({
        show: true,
        message: "Todos los campos son obligarorios",
      });
      return false;
    }

    setSending(true);
    HttpService.post(`patients/update/${state?.userId}`, { ...patient })
      .then((result) => {
        if (result?.status)
          setMensaje({ show: true, message: "Datos actualizados." });
        setSending(false);
      })
      .catch(() => {
        setSending(false);
      });
  }

  return (
    <>
      <Container>
        <Row className="mt-3">
          <Col>
            <Form.Text muted className="registre-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-person-fill"
                viewBox="2 2 17 17"
              >
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              </svg>
              Informaci&oacute;n personal
            </Form.Text>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>Nombres *</Form.Label>
            <Form.Control
              disabled={sending}
              onChange={(event) =>
                setPatient((prev) => ({
                  ...prev,
                  first_name: event.target.value,
                }))
              }
              type="text"
              value={patient?.first_name}
            />
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>Apellidos *</Form.Label>
            <Form.Control
              disabled={sending}
              onChange={(event) =>
                setPatient((prev) => ({
                  ...prev,
                  last_name: event.target.value,
                }))
              }
              type="text"
              value={patient?.last_name}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>Correo *</Form.Label>
            <Form.Control disabled type="text" defaultValue={patient?.email} />
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>Teléfono/Celular *</Form.Label>
            <Form.Control
              disabled={sending}
              onChange={(event) =>
                setPatient((prev) => ({
                  ...prev,
                  phone: event.target.value,
                }))
              }
              type="text"
              value={patient?.phone}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>Identificación *</Form.Label>
            <Form.Control
              disabled
              type="text"
              defaultValue={patient?.document}
            />
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>Sexo *</Form.Label>
            <br />
            <Form.Check
              checked={patient?.gender_id === 6}
              readOnly
              label="Masculino"
              name="gender"
              type="radio"
            />
            <Form.Check
              checked={patient?.gender_id === 7}
              readOnly
              label="Femenino"
              name="gender"
              type="radio"
            />
          </Form.Group>
        </Row>
        <Row>
          <Col
            xs={{ offset: 10, span: 2 }}
            sm={{ offset: 10, span: 2 }}
            md={{ offset: 10, span: 2 }}
            lg={{ offset: 10, span: 2 }}
            xl={{ offset: 10, span: 2 }}
            xxl={{ offset: 10, span: 2 }}
          >
            <Button
              className="d-flex justify-content-center align-items-center"
              disabled={sending}
              onClick={handleSave}
            >
              <i className="bi bi-save2"></i>
              Guardar
            </Button>
          </Col>
        </Row>
      </Container>
      {warning?.show && (
        <Message
          show={warning?.show}
          message={warning?.message}
          hide={() => setMensaje({ show: false })}
        />
      )}
    </>
  );
}

function SpecialistSecretaryProfile(): JSX.Element {
  const { state } = useAuth();
  const { specialistSelected } = usePanelPage();
  const [warning, setMensaje] = useState<IMessage>({ show: false });
  const [load, setLoad] = useState<Boolean>(false);

  const [idSpecialist, setIdSpecialist] = useState<number | null | undefined>(
    state?.specialist ? state?.id : state?.secretary ? specialistSelected : null
  );
  const [specialistProcedure, setSpecialistProcedure] = useState<IOptions[]>(
    []
  );

  const { specialist, loading, error } = useFetchSpecialist(idSpecialist);
  const { gender } = useFetchGender();
  const { language } = useFetchLanguage();
  const { networks } = useFetchSocialMedia();
  const { procedures } = useFetchGetListProcedure();

  useEffect(() => {
    setIdSpecialist(
      state?.specialist
        ? state?.id
        : state?.secretary
        ? specialistSelected
        : null
    );
  }, [state?.specialist, state?.secretary, specialistSelected]);

  const [editedInfo, setEditedInfo] = useState(specialist);
  const [rewards, setRewards] = useState<IReward[]>([]);
  const [LanguageSelect, setLanguages] = useState<ILanguage[]>([]);
  const [studys, setStudys] = useState<IStudy[]>([]);
  const [socialMediaSelect, setSocialMedia] = useState<INetwork[]>([]
  );

  useEffect(() => {
    debugger;
    setEditedInfo(specialist);
    if (specialist && specialist.procedure && specialist.procedure.length > 0) {
      let datos: IOptions[] = specialist.procedure.map((option: any) => ({
        id: option.procedure_id ? option.procedure_id : option.id,
        name: option.procedure_id ? option.name : option.nametemp,
        id_origin: option.id,
      }));

      setSpecialistProcedure(datos);
      setRewards(specialist?.reward || []);
      setLanguages(specialist?.language || []);
      setStudys(specialist?.study || []);
      setSocialMedia(specialist?.network || [])
    }
  }, [specialist]);

  useEffect(() => {
    const fetchedProcedures: IProcedure[] = specialistProcedure.map(
      (option: any) => ({
        procedure_id: option.id < 0 ? null : option.id,
        name: option.id < 0 ? option.name : null,
        id: option.id < 0 ? -1 : option.id_origin,
        specialist_id:
          typeof specialist.id === "number"
            ? specialist.id
            : parseInt(specialist.id ?? "0", 10),
      })
    );
    setEditedInfo({
      ...editedInfo,
      procedure: fetchedProcedures,
    });
  }, [specialistProcedure]);

  useEffect(() => {
    setEditedInfo({
      ...editedInfo,
      reward: rewards,
    });
  }, [rewards]);

  useEffect(() => {
    setEditedInfo({
      ...editedInfo,
      study: studys,
    });
  }, [studys]);

  useEffect(() => {
    setEditedInfo({
      ...editedInfo,
      network: socialMediaSelect,
    });
  }, [socialMediaSelect]);

  useEffect(() => {
    setEditedInfo({
      ...editedInfo,
      language: LanguageSelect,
    });
  }, [LanguageSelect]);

  if (error)
    return (
      <Container>
        <h2>
          No se pudo cargar la información del especialista. Inténtalo más
          tarde.
        </h2>
      </Container>
    );

  if (loading)
    return (
      <Container>
        <h2>Consultando tu especialista favorito....</h2>
      </Container>
    );

  // Manejar cambios en el formulario
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEditedInfo({
      ...editedInfo,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    console.log("Datos editados:", editedInfo);
    setLoad(true);
    HttpService.post("specialist/setSpecialist", { data: editedInfo })

      .then((result) => {
        const { status, message } = result;
        setMensaje({ show: true, message });
        setLoad(false);
      })
      .catch(() => setLoad(false));
  };

  if (state?.secretary && !specialistSelected)
    return <h2>Debe seleccionar el especialista a modificar.</h2>;

  return (
    <>
      <Container className="container-perfil">
        <Row className="align-items-center">
          <Col>
            <h2 className="mt-4 title-form">
              <i className="bi bi-person-circle m-2"></i>Perfil
            </h2>
          </Col>
          <Col className="text-end">
            <Button
              form="profile"
              variant="primary"
              onClick={() => handleSubmit()}
            >
              Guardar Cambios
            </Button>
          </Col>
        </Row>

        <hr />
        <Col id="profile">
          <Row>
            <Col sm={4}>
              <Form.Group controlId="formTarjeta">
                <Form.Label className="title-form">
                  Tarjeta Profesional:
                </Form.Label>
                <Form.Control
                  type="text"
                  name="card_number"
                  value={editedInfo?.card_number ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="formNombre">
                <Form.Label className="title-form">Nombre:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={editedInfo?.name ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="formApellido">
                <Form.Label className="title-form">Apellido:</Form.Label>
                <Form.Control
                  type="text"
                  name="surname"
                  value={editedInfo.surname ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="formApellido">
                <Form.Label className="title-form">Genero:</Form.Label>
                <GenderSection data={gender} json={editedInfo} />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="formDireccion">
                <Form.Label className="title-form">
                  Dirección de oficina:
                </Form.Label>
                <Form.Control
                  type="text"
                  name="office_address"
                  value={editedInfo.office_address ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="formTelefonos">
                <Form.Label className="title-form">Teléfonos:</Form.Label>
                <Form.Control
                  type="text"
                  name="phones"
                  value={editedInfo.phones ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group controlId="formDescripcion">
                <Form.Label className="title-form">
                  Descripción breve:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="short_description"
                  value={editedInfo.short_description ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Form.Group controlId="formAbout">
                <Form.Label className="title-form">Acerca de mí:</Form.Label>
                <ReactQuill
                  theme="snow"
                  value={editedInfo.about ?? ""}
                  onChange={(value: any) =>
                    setEditedInfo((prev) => ({
                      ...prev,
                      about: value,
                    }))
                  }
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Label className="title-form">
                Seleccione o agrege procedimientos que realice:
              </Form.Label>
              <SelectOptions
                list={procedures}
                data={specialistProcedure}
                setData={setSpecialistProcedure}
              />
            </Col>
            <Col sm={4}>
              <LanguageSection
                list={language}
                Language={LanguageSelect}
                setLanguages={setLanguages}
                idSpecialist={specialist?.id}
              />
            </Col>

            <Col sm={4}>
              <RewardSection
                rewards={rewards}
                setRewards={setRewards}
                idSpecialist={specialist?.id}
              />
            </Col>
            <Col sm={4}>
              <StudySection
                studys={studys}
                setStudys={setStudys}
                idSpecialist={specialist?.id}
              />
            </Col>
            <Col sm={4}>
              <SocialMediaSection
               list={networks}
               socialMedia={socialMediaSelect}
               setSocialMedia={setSocialMedia}
               idSpecialist={specialist?.id}
               />
            </Col>
          </Row>
        </Col>
      </Container>

      {warning?.show ? (
        <Message
          show={warning?.show}
          message={warning?.message}
          hide={() => {
            if (warning?.hide) warning?.hide();
            else setMensaje({ show: false });
          }}
        />
      ) : null}
          <Loader status={load} />

    </>
  );
}

export default function PanelProfile({ hidden }: IProfileProps): JSX.Element {
  const { state } = useAuth();
  return (
    <Container hidden={hidden}>
      {state?.patient && <PatientProfile />}
      {(state?.secretary || state?.specialist) && (
        <SpecialistSecretaryProfile />
      )}
    </Container>
  );
}
