import { EventClickArg } from "@fullcalendar/core";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Placeholder from "../../../../../components/placeholder/placeholder";
import ScheduleAppointment from "../../scheduleappointment/ScheduleAppointment";

interface InfoEventCalendaryProps {
  open: boolean;
  eventInfo: EventClickArg | undefined;
  eventsSource: any[];
  handleClose: any;
}

export default function InfoEventCalendar({
  handleClose,
  open,
  eventInfo,
  eventsSource,
}: InfoEventCalendaryProps): JSX.Element {
  const [appointment, setAppointment] = useState<any>(null);

  useEffect(() => {
    debugger
    if (eventInfo)
      setAppointment(
        eventsSource?.find((value) => value.id === eventInfo?.event?.id) ?? null
      );
  }, [eventInfo]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      show={open}
      onHide={handleClose}
      className="modalAppointment"
    >
      <Modal.Header closeButton>
        <Modal.Title>Información de la cita</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!appointment && <Placeholder />}
        {appointment && (
          <ScheduleAppointment
          appointmentId={appointment.id}
            handleAction={(action: any, message: any) =>
              handleClose(action, message)
            }
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
