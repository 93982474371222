import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface SearchFormProps {
  fields: { value: string; name: string }[];
  onSearch: (criteria: { field: string; value: string }) => void;
}

const SearchForm: React.FC<SearchFormProps> = ({ fields, onSearch }) => {
  const [selectedField, setSelectedField] = useState(fields[0].name);
  const [selectedValue,setSelectedValue] = useState(fields[0].value)
  const [searchValue, setSearchValue] = useState("");
  const { i18n, t } = useTranslation()
  const handleSearch = () => {
    // Lógica para realizar la búsqueda según el campo y el valor proporcionado
    onSearch({ field: selectedValue, value: searchValue });
  };

  return (
    <Form >
      <Row>
        <Col>
          <Form.Group controlId="selectField">
            <Form.Label>{t('selectField')}</Form.Label>
            <Form.Control
              as="select"
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.target.value)}
            >
              {fields.map((field) => (
                <option key={field.value} value={field.value}>
                  {field.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="searchValue">
            <Form.Label>{t('searchValue')}</Form.Label>
            <Form.Control
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Button variant="primary" onClick={handleSearch} className="button-search-viewPay">
            {t('search')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchForm;
