import { useEffect, useState } from "react";
import Select from "react-select";
import { ISpecialist } from "../../../../../../interfaces/specialist/specialist.interface";
import { styleselectreact } from "../../../../../../styles";
import SelectOptions from "./SelectOptions";

const GenderSection = ({ data, json }: { data: any; json: ISpecialist }) => {
  const [selected, setSelected] = useState<any>(null);
  const selectOptions = data.map((option: any) => ({
    value: option.id,
    label: option.name,
  }));

  useEffect(() => {
    if (json.genders && Object.keys(json.genders).length > 0) {
      const preselectedGender = {
        value: json.genders.id,
        label: json.genders.name,
      };
      setSelected(preselectedGender);
    }
  }, [json.genders]);

  return (
    <>
    <Select
      id="gender"
      value={selected}
      onChange={setSelected}
      options={selectOptions}
      placeholder="Seleccionar"
      noOptionsMessage={({ inputValue }) => {
        return inputValue
          ? "No se encontraron opciones"
          : "Ingrese un valor para comenzar la búsqueda";
      }}
      styles={styleselectreact}
    />
    </>
    
  );
};

export default GenderSection;
