import { useEffect, useState } from "react";
import HttpService from "../services/http";

export interface IProcedureType {
  id: number;
  name: string;
  icon: string;
}

export function useFetchProceduresTypes() {
  const [types, setTypes] = useState<IProcedureType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    HttpService.post("domains/getDomainForType", { id: 7 })
      .then((result) => {
        const data = result?.data as IProcedureType[];
        setTypes(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return { types, loading };
}
