import React, { useContext } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useSearchParams } from "react-router-dom";
import { Md5 } from "ts-md5";
import logo from "../../assets/images/logo/Logo_Blanco.svg";
import { getStatus } from "../../services/payment";
import { AuthContext } from "../../middlewares/auth";
import { useTranslation } from "react-i18next";

function roundHalfToEven(txValue: string | null) {
  if (!txValue) return "0";

  if (String(txValue).includes(".")) {
    const decimals = String(txValue).split(".")[1];
    const factor = Math.pow(10, 1);
    if (Number(decimals[0]) % 2 === 0 && Number(decimals[1]) === 5)
      return Math.floor(Number(txValue) * factor) / factor;
    else if (Number(decimals[0]) % 2 !== 0 && Number(decimals[1]) === 5)
      return Math.ceil(Number(txValue) * factor) / factor;
    else return Number(txValue).toFixed(1);
  }
  return txValue;
}

function ResponseSitepage(): JSX.Element {
  const [searchParams] = useSearchParams();
  const context = useContext(AuthContext);
  const { state } = useLocation();
  const merchantId = searchParams.get("merchantId");
  const referenceCode = searchParams.get("referenceCode");
  const currency = searchParams.get("currency");
  const transactionState = Number(
    searchParams?.get("transactionState") ?? "104"
  );
  const TX_VALUE = searchParams.get("TX_VALUE");
  const newValue = roundHalfToEven(TX_VALUE);
  const signature = searchParams.get("signature");

  const transactionId = searchParams.get("transactionId");

  const [message, setMessage] = React.useState<string>(
    "Verificación pendiente."
  );
  const { i18n, t } = useTranslation()
  console.log(context)
  return (
    <>
      <div className="pageResponsePayu pageResponseSite ">
        <div className="imagen-container">
          <img src={logo} alt="logo"></img>
          <label className="response-title">{t('bookingInfo')}</label>
        </div>
        <Container fluid className="response-container">
          <Row>
            <Col className="text-center">
              <h3>{t('appointmentPendingValidationMessage')}</h3>
              <p>{t('confirmationMessage')}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <p className="mb-0 bold text-center">{t('specialistData')}:</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={6}>
              <Form.Label className="bold p-2">{t('firstName')}: </Form.Label>
              <Form.Label>
                {state.stateLocation.specialist.name +
                  " " +
                  state.stateLocation.specialist.surname}
              </Form.Label>
            </Col>

            <Col sm={6} md={6}>
              <Form.Label className="bold p-2">{t('address')}: </Form.Label>
              <Form.Label>
                {state.stateLocation.specialist.office_address}
              </Form.Label>
            </Col>

        
          </Row>
          <Row>
            <Col>
              {" "}
              <p className="mb-0 bold text-center">{t('appointmentData')}:</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={6}>
              <Form.Label className="bold p-2">{t('date')+" "+t('and')+" "+t('time')}: </Form.Label>
              <Form.Label>
                {state.stateLocation.selectAppointment.selectedDate?.$D}/
                {state.stateLocation.selectAppointment?.selectedDate?.$M + 1}/
                {state.stateLocation.selectAppointment?.selectedDate?.$y} -{" "}
                {state.stateLocation.selectAppointment?.selectedHour}
              </Form.Label>
            </Col>

            <Col sm={6} md={6}>
              <Form.Label className="bold p-2">{t('totalValue')}: </Form.Label>
              <Form.Label>{new Intl.NumberFormat("es-ES", {
                            minimumFractionDigits: 0,
                          }).format(parseFloat(state.stateLocation.price))}</Form.Label>
            </Col>

            <Col sm={6} md={6}>
              <Form.Label className="bold p-2">{t('currency')}: </Form.Label>
              <Form.Label>{state.stateLocation.currency}</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <p className="mb-0 bold text-center">{t('patientData')}:</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={6}>
              <Form.Label className="bold p-2">{t('firstName')}: </Form.Label>
              <Form.Label>
                {state.payerInfo.name + " " + state.payerInfo.lastName}
              </Form.Label>
            </Col>
            <Col sm={6} md={6}>
              <Form.Label className="bold p-2">{t('phone')}: </Form.Label>
              <Form.Label>{state.payerInfo.phone}</Form.Label>
            </Col>
            <Col sm={12} md={12}>
              <Form.Label className="bold p-2">{t('email')}: </Form.Label>
              <Form.Label>{state.payerInfo.email}</Form.Label>
            </Col>
          </Row>
          <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                {context.state?.isLoged ? (
                  <Form.Label className="bold p-2">
                    {t('pendingAppointmentMessage')}
                    <a href="/panel/mydate">  {t('here')}</a>
                  </Form.Label>) : (
                    <Form.Label className="bold p-2">
                    {t('pendingAppointmentMessageNotLogin')}
                    <a href="/login"> {t('here')}</a>
                  </Form.Label>
                  )}
                </Col>
               
              </Row>
          <Row className="mt-2">
            <Col className="text-center">
              <a href="/">{t('backToHome')}</a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ResponseSitepage;
