import { useEffect, useState } from "react";
import HttpService from "../../../../../../../services/http";

export interface ILanguage{
  id: number;
  name: string;
}

export default function useFetchLanguage() {
  const [language, setLanguage] = useState<ILanguage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    HttpService.post("domains/getDomainForType", { id: 4 })
      .then((result) => {
        const data = result?.data as ILanguage[];
        setLanguage(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return { language, loading };
}
