import { useContext, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { Md5 } from "ts-md5";
import logo from "../../assets/images/logo/Logo_Blanco.svg";
import { getStatus } from "../../services/payment";
import { AuthContext } from "../../middlewares/auth";
import { useTranslation } from "react-i18next";

function roundHalfToEven(txValue: string | null) {
  if (!txValue) return "0";

  if (String(txValue).includes(".")) {
    const decimals = String(txValue).split(".")[1];
    const factor = Math.pow(10, 1);
    if (Number(decimals[0]) % 2 === 0 && Number(decimals[1]) === 5)
      return Math.floor(Number(txValue) * factor) / factor;
    else if (Number(decimals[0]) % 2 !== 0 && Number(decimals[1]) === 5)
      return Math.ceil(Number(txValue) * factor) / factor;
    else return Number(txValue).toFixed(1);
  }
  return txValue;
}

function Responsepage(): JSX.Element {
  const [searchParams] = useSearchParams();
  const context = useContext(AuthContext);
  const { i18n, t } = useTranslation()
  const merchantId = searchParams.get("merchantId");
  const referenceCode = searchParams.get("referenceCode");
  const currency = searchParams.get("currency");
  const transactionState = Number(
    searchParams?.get("transactionState") ?? "104"
  );
  const TX_VALUE = searchParams.get("TX_VALUE");
  const newValue = roundHalfToEven(TX_VALUE);
  const signature = searchParams.get("signature");

  const [newSignature] = useState<string>(
    Md5.hashStr(
      `${process.env.REACT_APP_PAYU_API_KEY}~${merchantId}~${referenceCode}~${newValue}~${currency}~${transactionState}`
    )
  );

  const transactionId = searchParams.get("transactionId");

  const [message, setMessage] = useState<string>("Verificación pendiente.");

  async function handleStatus() {
    const result = await getStatus({ referenceCode, transactionId });
    if (result && result?.data?.state_pol) {
      switch (result?.data?.state_pol) {
        case "4":
          setMessage("Transacción aprobada.");
          break;
        case "6":
          setMessage("Transacción rechazada.");
          break;
        case "104":
          setMessage("Se ha producido un error.");
          break;
        case "7":
          setMessage("Pago pendiente.");
          break;
        default:
          break;
      }
    }
  }

  return (
    <>
      <div className="pageResponsePayu">
        <div className="imagen-container">
          <img src={logo}></img>
          <label className="response-title">{t('transactionSummary')}</label>
        </div>
        <Container fluid className="response-container">
          {signature === newSignature ? (
            <>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Label className="bold p-2">
                  {('transactionStatus')}:{" "}
                  </Form.Label>
                  <Form.Label>{message}</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Label className="bold p-2">
                    {t('transactionID')}:{" "}
                  </Form.Label>
                  <Form.Label>{transactionId}</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Label className="bold p-2">
                    {t('transactionReference')}:{" "}
                  </Form.Label>
                  <Form.Label>{referenceCode}</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Label className="bold p-2">{t('totalValue')}: </Form.Label>
                  <Form.Label>{TX_VALUE}</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Label className="bold p-2">{t('currency')}: </Form.Label>
                  <Form.Label>{currency}</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  {context.state?.isLoged ? (
                    <Form.Label className="bold p-2">
                      {t('pendingTransactionMessage')}
                      <a href="/panel/mydate">{t('here')}</a>
                    </Form.Label>
                  ) : (
                    <Form.Label className="bold p-2">
                     {t('pendingTransactionMessageNotLogin')}
                      <a href="/login">{t('here')}</a>
                    </Form.Label>
                  )}
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Button onClick={handleStatus}> {t('checkStatus')}</Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <a href="/">{t('backToHome')}</a>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form.Label className="bold">
                 {t('signatureValidationFailed')}
                </Form.Label>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
}

export default Responsepage;
