import { Calendar, DateSelectArg, EventClickArg } from "@fullcalendar/core";
import esLocale from "@fullcalendar/core/locales/es";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { IMessage, Message } from "../../../../../components/message";
import { useCalendarEvents } from "../../../../../services/calendar";
import { usePanelPage } from "../../../panelPage";
import { useAppointment } from "../panelAppointment";
import InfoEventCalendar from "./infoEventCalendar";
import { ContainerCalendar } from "./style";
import Holidays from "date-holidays";
import useFetchUnworkingDays from "../../../../share/hooks/useExcludeDays";

export default function MyCalendar(): JSX.Element {
  const {
    date,
    setDate,
    patientDocument,
    appointmentType,
    refresh,
    setRefresh,
  } = useAppointment();

  const hd = new Holidays('CO');
  const { specialistSelected } = usePanelPage();
  
  const [selectMonth, setSelectMonth] = useState(dayjs().month() + 1); // Current month
  const [selectYear, setSelectYear] = useState(dayjs().year()); // Current year
const propsUnworkingDays = {
  specialistId: Number(specialistSelected),
  selectMonth: String(selectMonth),
  selectYear: String(selectYear)
}
  const { data, excludedDays } = useFetchUnworkingDays(propsUnworkingDays);
  const [dateUnavialible, setDateUnavialible] = useState<number[] | null>(null);
  const [viewDate, setViewDate] = useState<dayjs.Dayjs | null>(date);

  const { eventsSource } = useCalendarEvents({
    specialistId: specialistSelected,
    date,
    viewDate,
    patientDocument,
    appointmentType,
    refresh,
  });

  useEffect(() => {
    if (data) {
      setDateUnavialible(data);
    }
  }, [data]);

  const [fullCalendarCreated, setFullCalendarCreated] = useState<boolean>(false);
  const [fullCalendar, setFullCalendar] = useState<Calendar | null>(null);
  const [eventInfo, setEventInfo] = useState<EventClickArg>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  useEffect(() => {
    const element = document.getElementById("full-calendar");
    if (element && dateUnavialible !== null) {
      const calendar = new Calendar(element, {
        nowIndicator: true,
        plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "timeGridDay,timeGridWeek,dayGridMonth",
        },
        locale: esLocale,
        longPressDelay: 1000,
        eventLongPressDelay: 1000,
        selectLongPressDelay: 1000,
        weekends: true,
        selectable: true,
        dayMaxEvents: true,
        allDaySlot: false,
        editable: false,
        rerenderDelay: 100,
        timeZone: "GMT-0",
        eventClassNames: "clickable-event",
        dayCellClassNames: (date) => {
          console.log(excludedDays)
          const adjustDay = new Date(date.date);
          adjustDay.setDate(adjustDay.getDate() + 1);
          const isHoliday = hd.isHoliday(adjustDay);
          const isUnavailable = excludedDays?.includes(date.date.toISOString().split('T')[0]);
          return isHoliday  ? "fc-holiday" : (isUnavailable ? "fc-weekend" : "fc-weekday");
        },
      });
      setFullCalendar(calendar);
      setFullCalendarCreated(true);
    }
  }, [dateUnavialible, excludedDays]);

  useEffect(() => {
    fullCalendar?.render();
    fullCalendar?.on("select", handleAddEventSelectAndOpenModal);
    fullCalendar?.on("eventClick", handleEditEventSelectAndOpenModal);
    fullCalendar?.on("datesSet", (dateSet) => {
      const { view } = dateSet;
      const { currentStart, currentEnd } = view;
      setSelectMonth(dayjs(currentStart).month() + 1);
      setSelectYear(dayjs(currentStart).year());
      setViewDate(dayjs(currentEnd));
    });
  }, [fullCalendarCreated]);

  useEffect(() => {
    fullCalendar?.gotoDate(date?.format("YYYY-MM-DD") ?? new Date());
  }, [date]);

  useEffect(() => {
    fullCalendar?.batchRendering(() => {
      fullCalendar?.removeAllEventSources();
      fullCalendar?.addEventSource(eventsSource);
    });
  }, [fullCalendar, eventsSource]);

  function handleAddEventSelectAndOpenModal(selectInfo: DateSelectArg) {
    fullCalendar?.changeView(
      "timeGridDay",
      dayjs(selectInfo?.startStr).format("YYYY-MM-DD")
    );
    setDate(dayjs(selectInfo?.startStr));
  }

  function handleEditEventSelectAndOpenModal(clickInfo: EventClickArg) {
    setIsOpenModal(true);
    setEventInfo(clickInfo);
  }

  return (
    <>
      <ContainerCalendar>
        <InfoEventCalendar
          eventsSource={eventsSource}
          eventInfo={eventInfo}
          open={isOpenModal}
          handleClose={(action: any, message: any) => {
            setIsOpenModal(false);
            setEventInfo(undefined);
            setRefresh(!refresh);
            if (message !== "" && message !== undefined)
              setMessage({
                show: true,
                message: message,
              });
          }}
        />
        <div id="full-calendar" />
      </ContainerCalendar>
      {message?.show ? (
        <Message
          show={message?.show}
          message={message?.message}
          hide={() => setMessage((prev: any) => ({ ...prev, show: false }))}
        />
      ) : null}
    </>
  );
}
