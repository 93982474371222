import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Fragment, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import Placeholder from "../../components/placeholder/placeholder";
import { useDebounce } from "../../hooks/useDebounce";
import { useFetchCities } from "./hooks/useFetchCities";
import { useFetchProcedures } from "./hooks/useFetchProcedures";
import { useFetchSpecialists } from "./hooks/useFetchSpecialists";
import useFetchSpecialistsData from "./hooks/useFetchSpecialistsData";

import MapGoogle from "./components/MapGoogle";
import CalendarSectorized from "./components/calendarSectorized";
import styles from "./specialists.module.css";
import { useTranslation } from "react-i18next";

function Specialistspage(): JSX.Element {
  const [searchParams] = useSearchParams();
  const { i18n, t } = useTranslation()
  const [specialist, setSpecialist] = useState<string>(
    searchParams.get("specialist") || ""
  );
  const debouncedSpecialist = useDebounce(specialist);

  const { specialists, loading: loadingSpecialists } =
    useFetchSpecialists(debouncedSpecialist);

  const [from, setFrom] = useState<string>("");
  const [until, setUntil] = useState<string>("");

  const { procedures, loading: loadingProcedures } = useFetchProcedures();
  const [procedure, setProcedure] = useState<string[]>(
    searchParams.get("procedure")?.trim() !== ""
      ? [searchParams.get("procedure") || ""]
      : []
  );

  const { cities, loading: loadingCities } = useFetchCities();
  const [city, setCity] = useState<string[]>(
    searchParams.get("city")?.trim() !== ""
      ? [searchParams.get("city") || ""]
      : []
  );

  const [showMap, setShowMap] = useState<boolean>(false);

  const [search, setSearch] = useState<boolean>(false);

  function handleSearch() {
    setSearch((prev) => !prev);
  }

  const { specialist: data } = useFetchSpecialistsData(
    search,
    specialist,
    procedure.join(","),
    city.join(","),
    from,
    until
  );

  return (
    <>
      <Container fluid className="specilist-search">
        <Row className="m-3">
          <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
            <Row>
              <Col>
                <Form.Text muted>{t('specialist')}</Form.Text>
                <Autocomplete
                  options={specialists.map(
                    ({ name, surname }) => `${name} ${surname}`
                  )}
                  inputValue={specialist}
                  onInputChange={(event, value) => setSpecialist(value)}
                  loading={loadingSpecialists}
                  freeSolo
                  className="input-autocomplete"
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <Fragment>
                            {loadingSpecialists ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Form.Text muted>{t('availableSchedule')}</Form.Text>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <Form.Label>{t('from')}</Form.Label>
                <Form.Control
                  type="date"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                />
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <Form.Label>{t('to')}</Form.Label>
                <Form.Control
                  type="date"
                  value={until}
                  onChange={(e) => setUntil(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Form.Text muted>{t('procedure')}</Form.Text>
                <div
                  style={{
                    height: "auto",
                    maxHeight: "250px",
                    overflowY: "auto",
                  }}
                >
                  {loadingProcedures ? (
                    <Placeholder />
                  ) : (
                    <>
                      {procedures?.map(({ id, name }) => (
                        <div key={id} style={{ display: "flex" }}>
                          <Form.Check
                            type="checkbox"
                            checked={procedure.some(
                              (value) => value === String(id)
                            )}
                            onChange={(event) => {
                              if (event.target.checked)
                                setProcedure((prev) => [...prev, String(id)]);
                              else
                                setProcedure((prev) =>
                                  prev?.filter((value) => value !== String(id))
                                );
                            }}
                          />
                          <Form.Text style={{ marginLeft: ".5rem" }}>
                            {name}
                          </Form.Text>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Form.Text muted>{t('city')}</Form.Text>
                <div
                  style={{
                    height: "auto",
                    maxHeight: "250px",
                    overflowY: "auto",
                  }}
                >
                  {loadingCities ? (
                    <Placeholder />
                  ) : (
                    <>
                      {cities?.map(({ id, name }) => (
                        <div key={id} style={{ display: "flex" }}>
                          <Form.Check
                            type="checkbox"
                            checked={city.some((value) => value === String(id))}
                            onChange={(event) => {
                              if (event.target.checked)
                                setCity((prev) => [...prev, String(id)]);
                              else
                                setCity((prev) =>
                                  prev?.filter((value) => value !== String(id))
                                );
                            }}
                          />
                          <Form.Text style={{ marginLeft: ".5rem" }}>
                            {name}
                          </Form.Text>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={handleSearch}>{t('search')}</Button>
              </Col>
            </Row>
          </Col>
          <Col
            xs={showMap ? 6 : 9}
            sm={showMap ? 6 : 9}
            md={showMap ? 6 : 9}
            lg={showMap ? 6 : 9}
            xl={showMap ? 6 : 9}
            xxl={showMap ? 6 : 9}
            className={styles.containerData}
            
          >
            <Row                       style={{ cursor: "pointer",display:"none" }}
>
              <Col className="d-flex justify-content-end">
                <Form.Check
                  name="typeVisualitation"
                  type="checkbox"
                  
                  checked={showMap}
                  onChange={(event) => setShowMap(event.target.checked)}
                />
                <Form.Label>{t('Map')}</Form.Label>
              </Col>
            </Row>
            {data?.map(({ id, name, surname, photo, short_description }) => (
              <Row key={id} className={styles.rowSpecialist}>
                <Col
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  xxl={3}
                  className="d-flex flex-column justify-content-center align-items-center text-center"
                >
                  <img src={photo} className={styles.photo} loading="lazy" />
                  <Form.Label
                    className={styles.name}
                  >{`${name} ${surname}`}</Form.Label>
                  <Form.Label className={styles.shortDescription}>
                    {short_description}
                  </Form.Label>
                </Col>
                <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
                  <CalendarSectorized specialist_id={id} />
                </Col>
              </Row>
            ))}
          </Col>
          {showMap && (
            <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} className="pt-5">
              <MapGoogle></MapGoogle>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}

export default Specialistspage;
