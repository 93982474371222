import { createContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { IMessage, Message } from "../../../../../../components/message";
import { useAuth } from "../../../../../../middlewares/auth";

import HttpService from "../../../../../../services/http";
import { usePanelPage } from "../../../../panelPage";
import useFetchTypePay from "./hooks/useFetchTypePay";
import { debug } from "console";
import { Form } from "react-router-dom";

export default function Typepay({ hidden }: any): JSX.Element {
  const { state } = useAuth();
  const [loading, setLoading] = useState(false);
  const [payPayu, setPayPayu] = useState(false);
  const [paySite, setPaySite] = useState(false);


  const { specialistSelected } = usePanelPage();

  const [idSpecialist, setIdSpecialist] = useState<number | null | undefined>(
    state?.specialist ? state?.id : state?.secretary ? specialistSelected : null
  );

  const [specialistData, setSpecialistData] = useState<{
    specialist_id?: number | null | undefined;
    typePay?: number[] | undefined;
  }>({
    specialist_id: idSpecialist,
    typePay: [],
  });

  const { typePay } = useFetchTypePay(idSpecialist);

  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });
  useEffect(() => {
    setIdSpecialist(
      state?.specialist
        ? state?.id
        : state?.secretary
        ? specialistSelected
        : null
    );
  }, [state?.specialist, state?.secretary, specialistSelected]);



  useEffect(() => {
    setSpecialistData({ ...specialistData, specialist_id: idSpecialist })
  }, [idSpecialist])
  
  useEffect(() => {
    if (typePay) {
      const selectedTypes = typePay.filter(
        (type) => type.specialist_id === specialistData.specialist_id
      )

      const typeIds = selectedTypes.map((type) => type.pay_id)

      setSpecialistData({ ...specialistData, typePay: typeIds })

      if (typeIds.includes(1)) {
        setPaySite(true)
      }

      if (typeIds.includes(2)) {
        setPayPayu(true)
      }
    }
  }, [typePay])
  
  function handleSave() {
    if (specialistData.typePay?.length===0) {
      setMessage({ show: true, message: "Debe seleccionar al menos un método de pago" });
      return;
    }
    setLoading(true);
    HttpService.post("specialists/updateSpecialistTypePays",specialistData)
      .then((result) => {
        setLoading(false);
        const { status, message } = result;
        if (status) {
          setMessage({ show: true, message: "Información actualizada"})
        } else
          setMessage({
            show: true,
            message,
          });
      })
      .catch(() => {
        setLoading(false);
        setMessage({
          show: true,
          message: "No se pudo actualizar la información de pagos",
        });
      });
  }
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    const value = parseInt(id.split('-')[1]);

    if (checked) {
      setSpecialistData({
        ...specialistData,
        typePay: [...specialistData.typePay || [], value],
      });
    } else {
      setSpecialistData({
        ...specialistData,
        typePay: specialistData.typePay?.filter((val) => val !== value),
      });
    }
  }
  return (
    <>
      <Container hidden={hidden}>
        <Row>
          <Col
            xs={{ offset: 9, span: 3 }}
            sm={{ offset: 9, span: 3 }}
            md={{ offset: 9, span: 3 }}
            lg={{ offset: 9, span: 3 }}
            xl={{ offset: 9, span: 3 }}
            xxl={{ offset: 9, span: 3 }}
            className="text-end"
          >
            <Button onClick={handleSave} className={"m-2"} disabled={loading}>
              <i className="bi bi-floppy"></i> Actualizar
            </Button>
          </Col>
        </Row>
      <h6>Para que podamos procesar el pago de las citas de sus pacientes de manera eficiente, seleccione minímo un método de pago.</h6>
        <Row className="mt-2">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="specialist-1"
                checked={specialistData.typePay?.includes(1)}
                onChange={handleCheckboxChange}
                />
              <label className="form-check-label" htmlFor="specialist-1">
                Pago en sitio
              </label>
            </div>
          </Col>
          {/**<Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="specialist-2"
                checked={specialistData.typePay?.includes(2)}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" htmlFor="specialist-2">
                Pago Payu
              </label>
            </div>
          </Col>**/ }
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="specialist-3"
                checked={specialistData.typePay?.includes(3)}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" htmlFor="specialist-3">
                Wompi
              </label>
            </div>
          </Col>
        </Row>
      </Container>
      {message?.show ? (
        <Message
          show={message?.show}
          message={message?.message}
          hide={() => setMessage((prev) => ({ ...prev, show: false }))}
        />
      ) : null}
    </>
  );
}
