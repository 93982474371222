export const styleselectreact = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "30px",
      fontSize: "12px",
      height: "25px",
      color: "#212529",
      backgroundColor: "#fff",
      borderColor: state.isFocused ? "#7045af" : "#ced4da",
      outline: 0,
      boxShadow: state.isFocused ? "0 0 0 0.1rem #7045af5e" : "none",
      "&:hover": {
        borderColor: "trasparent",
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: "12px", // Tamaño de letra de la lista desplegable
      backgroundColor: state.isSelected
        ? "#7a45ad" // Color de fondo cuando está seleccionado
        : state.isFocused
        ? "#b8b8b868" // Color de fondo en hover
        : "white", // Color de fondo predeterminado
      color: state.isSelected
        ? "white" // Color del texto cuando está seleccionado
        : state.isFocused
        ? "#000000" // Color del texto en hover
        : "black", // Color del texto predeterminado
      cursor: "pointer", // Cambiar el cursor al pasar sobre las opciones
    }),
  };

