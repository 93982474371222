import { useEffect, useState } from "react";
import { IProcedure } from "../../../../procedure/interface";
import HttpService from "../../../../../services/http";

export default function useFetchProcedure(
  id: string | undefined,
  editMode: boolean,
  refreshProcedure: boolean
) {
  const [procedure, setProcedure] = useState<IProcedure>({});
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id || editMode) {
      console.log("fetch procedure");
      setError(false);
      HttpService.post("procedures/getProcedure", { id })
        .then((result) => {
          const data = result?.data as IProcedure;
          setProcedure(data);
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    } else setProcedure({ id });
  }, [id, editMode, refreshProcedure]);

  return { procedure, setProcedure, loading, setLoading, error };
}
