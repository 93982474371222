import { useEffect, useState } from "react";
import { ICity } from "../../../interfaces/specialist/specialist.interface";
import HttpService from "../../../services/http";

export function useFetchCities() {
  const [cities, setCities] = useState<ICity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setCities([{ id: "1", name: "Medellín" }]);
    setLoading(false);
  }, []);

  return { cities, loading };
}
