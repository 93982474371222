import { useEffect, useState } from "react";
import HttpService from "../../../../../../../services/http";

export interface INetwork {
  id: number;
  name: string;
}

export default function useFetchSocialMedia() {
  const [networks, setNetworks] = useState<INetwork[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    HttpService.post("domains/getDomainForType", { id: 6 })
      .then((result) => {
        const data = result?.data as INetwork[];
        setNetworks(data);
      })
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  }, []);

  return { networks, loading };
}
