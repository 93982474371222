import { Badge } from "@mui/material";
import {
  DateCalendar,
  DayCalendarSkeleton,
  PickersDay,
  PickersDayProps,
  esES,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/es";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IWarning, Message } from "../../../../../components/message";
import Placeholder from "../../../../../components/placeholder/placeholder";
import { ISpecialist } from "../../../../../interfaces/specialist/specialist.interface";
import { useAuth } from "../../../../../middlewares/auth";
import HttpService from "../../../../../services/http";
import useFetchCalendar from "../../../../specialist/hooks/useFetchcalendar";
import "./calendar.css";

dayjs.locale("es");

interface CalendarProps {
  id?: string;
  specialist?: ISpecialist;
  setSelect: any;
  handleReserve: any;
  reschedule: any;
}

interface HoursAvailableProps {
  loading: boolean;
  hours: string[] | null;
  selectedHour: string | null;
  id?: string;
  setSelectedHour: React.Dispatch<React.SetStateAction<string | null>>; // Añade setSelectedHour con su tipo correspondiente
}
interface AppointmentType {
  appointment_type_id: number;
  appointments_type_id: { id: number; name: string };
  currency_type: number;
  currencys_type: { id: number; name: string };
  duration: number;
  id: number;
  price: string;
  principal_appointment: number;
  specialist_id: number;
  specialists_id: {
    id: number;
    name: string;
    surname: string;
    card_number: number;
  };
}

interface Appointment {
  created_at: Date;
  end_datetime: Date;
  id: number;
  patient_id: number;
  specialist_appointment_type_id: number;
  specialist_id: number;
  start_datetime: Date;
  updated_at: Date;
}

function HoursAvailable({
  loading,
  hours,
  selectedHour,
  id,
  setSelectedHour,
}: HoursAvailableProps) {
  // const [selectedItem, setSelectedItem] = useState<string>("");
  const convertirRangoHoraAMPM = (rangoHora24: string) => {
    // Dividir el rango de horas en dos horas individuales
    const [horaInicio, horaFin] = rangoHora24.split("-");

    // Parsear las horas con Day.js
    const horaInicioParseada = dayjs(horaInicio, "HH:mm");
    const horaFinParseada = dayjs(horaFin, "HH:mm");

    // Formatear las horas en formato de 12 horas con 'am/pm'
    const horaInicio12 = horaInicioParseada.format("hh:mm A");
    const horaFin12 = horaFinParseada.format("hh:mm A");

    // Retornar el rango de horas en formato de 12 horas
    return `${horaInicio12} - ${horaFin12}`;
  };
  return (
    <Row>
      {loading && <Placeholder />}
      {hours?.map((item, index) => {
        const handleClick = () => {
          setSelectedHour(item);
          // if (selectedHour === item) {
          //   setSelectedItem(""); // Deselecciona el elemento si ya está seleccionado
          // } else {
          //   setSelectedItem(item); // Selecciona el elemento
          // }
        };

        const isSelected = selectedHour === item;
        const spanClassName = isSelected ? "seleted" : "";

        return (
          <Col
            className="item_hours"
            key={index}
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
          >
            <span className={spanClassName} onClick={handleClick}>
              {convertirRangoHoraAMPM(item)}
            </span>
          </Col>
        );
      })}
    </Row>
  );
}

function Calendar({
  id,
  specialist,
  setSelect,
  handleReserve,
  reschedule,
}: CalendarProps): JSX.Element {
  const [daysBlocked, setDaysBlocked] = useState<string[]>([]);
  const [selectedHour, setSelectedHour] = useState<string | null>(null);
  const [appointmentType, setAppointmentType] = useState<AppointmentType[]>([]);

  const {
    excludedDays,
    excludedDaysOfWeek,
    selectedDate,
    setSelectedDate,
    setSelectMonth,
    today,
    availableSlot,
    loading,
    error,
    setSelectYear,
  } = useFetchCalendar({ id, withFirstDateAvailable: false });

  const [warning, setWarning] = useState<IWarning>({
    show: false,
    message: "",
  });
  function shouldDisableDate(valueDate: Dayjs) {
    return (
      excludedDaysOfWeek.includes(valueDate?.day()) ||
      excludedDays.includes(valueDate.format("YYYY-MM-DD"))
    );
  }

  async function getAppointmentType() {
    await HttpService.post("specialists/getTypeAppointment", {
      specialist_id: id,
    })
      .then((result: any) => {
        const { status } = result;
        if (status) {
          setAppointmentType(result?.data);
        }
      })
      .catch((e: any) => {
        setWarning({
          show: true,
          message: e.message,
        });
      });
  }

  useEffect(() => {
    setSelectedHour(null);
    setSelect({ date: null, start_datetime: null, end_datetime: null });
  }, [selectedDate]);

  useEffect(() => {
    if (selectedHour && selectedDate) {
      const hourStart = selectedHour?.split("-")[0].split(":")[0];
      const minutesStart = selectedHour?.split("-")[0].split(":")[1];
      const hourEnd = selectedHour?.split("-")[1].split(":")[0];
      const minutesEnd = selectedHour?.split("-")[1].split(":")[1];
      const data = {
        date: dayjs(selectedDate),
        start_datetime: dayjs(selectedDate || new Date())
          .hour(Number(hourStart))
          .minute(Number(minutesStart))
          .second(0),

        end_datetime: dayjs(selectedDate || new Date())
          .hour(Number(hourEnd))
          .minute(Number(minutesEnd))
          .second(0),
      };
      setSelect(data);
    }
  }, [selectedHour, selectedDate]);

  useEffect(() => {
    getAppointmentType();
  }, []);

  const handleMonthChange = (date: Dayjs) => {
    setSelectYear(date.year());
    setSelectMonth(date.month() + 1);
  };

  function serverDay(
    props: PickersDayProps<Dayjs> & { daysBlocked?: string[] }
  ) {
    const { daysBlocked, day, outsideCurrentMonth, ...other } = props;
    const today = dayjs();
    let monthSearch = 0;
    let yearSearch = 0;
    if (daysBlocked && daysBlocked?.length > 0) {
      monthSearch = Number(daysBlocked[0].split("-")[1]);
      yearSearch = Number(daysBlocked[0].split("-")[0]);
    }
    const arrayDates = daysBlocked?.map((date) => Number(date.split("-")[2]));
    if (
      new Date().getMonth() + 1 == Number(monthSearch) &&
      new Date().getFullYear() == yearSearch
    ) {
      for (let i = 1; i <= today.date(); i++) {
        arrayDates?.push(i);
      }
    }
    const isDisabled =
      !props.outsideCurrentMonth &&
      arrayDates &&
      arrayDates.indexOf(props.day.date()) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={
          isDisabled ? (
            <span
              style={{
                marginRight: "2.4em",
                marginTop: "2em",
                color: "darkgray",
                fontSize: "1em",
              }}
            >
              —
            </span>
          ) : undefined
        }
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }
  if (error) return <h2>No pudimos cargar el calendario...</h2>;

  return (
    <>
      <Row>
        <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={esES}
            localeText={{
              previousMonth: "Anterior mes",
              nextMonth: "Próximo mes",
            }}
          >
            <DateCalendar
              value={selectedDate}
              onChange={(value) => setSelectedDate(value)}
              onMonthChange={handleMonthChange}
              onYearChange={(value) => setSelectYear(value.year())}
              minDate={today}
              shouldDisableDate={shouldDisableDate}
              renderLoading={() => <DayCalendarSkeleton />}
              loading={loading}
              slots={{ day: serverDay }}
              slotProps={{
                day: {
                  daysBlocked,
                } as any,
              }}
            />
          </LocalizationProvider>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
          {availableSlot && (
            <HoursAvailable
              loading={loading}
              hours={availableSlot}
              id={id}
              selectedHour={selectedHour}
              setSelectedHour={setSelectedHour}
            />
          )}

          {selectedHour && (
            <Button className="btn-reserva" onClick={() => handleReserve()}>
              {reschedule ? " Reagendar cita" : "Reservar cita"}
            </Button>
          )}
        </Col>
      </Row>

      {warning?.show ? (
        <Message
          show={warning?.show}
          message={warning?.message}
          hide={() => {
            setWarning({ show: false });
          }}
        />
      ) : null}
    </>
  );
}

export default Calendar;
