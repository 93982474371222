import { useEffect, useState } from "react";
import {
  AppointmentPendingConfirmation,
  AppointmentUpcoming,
  GroupedAppointments,
} from "../types/appointment";
import HttpService from "./http";

function useAppointmentsPendingConfirmation({
  specialistSelected,
  refetch,
}: {
  specialistSelected?: number | null;
  refetch?: number;
}) {
  const [appoinments, setAppoinments] = useState<
    AppointmentPendingConfirmation[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getAppointmentsPendingConfirmation(specialistSelected);
    const newIntervalId = setInterval(() => {
      getAppointmentsPendingConfirmation(specialistSelected);
    }, 60000);
    return () => clearInterval(newIntervalId);
  }, [specialistSelected, refetch]);

  function getAppointmentsPendingConfirmation(
    specialistSelected: number | null | undefined
  ) {
    if (!specialistSelected) return;
    setLoading(true);
    setAppoinments([]);
    setError(false);
    HttpService.get(
      `specialist/getAppointmentsPendingConfirmation/${specialistSelected}`
    )
      .then((result) => {
        const data = result?.data as AppointmentPendingConfirmation[];
        setAppoinments(data);
        setLoading(false);
      })
      .catch(() => setError(true));
  }

  return { appoinments, loading, error };
}

function useAppointmentsPendingConfirmationId(appointmentId: number | null) {
  const [appoinment, setAppoinment] =
    useState<AppointmentPendingConfirmation | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (appointmentId) {
      setAppoinment(null);
      setLoading(true);
      HttpService.get(
        `specialist/getAppointmentsPendingConfirmationId/${appointmentId}`
      )
        .then((result) => {
          const data = result?.data as AppointmentPendingConfirmation[];
          setAppoinment(data[0]);
          setLoading(false);
        })
        .catch(() => setError(true));
    }
  }, [appointmentId]);

  return { appoinment, loading, error };
}

function useAppointment(appointmentId: number | null) {
  const [appointment, setAppointment] = useState<any>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  useEffect(() => {
    setAppointment(null);
    setIsOpenModal(false);
    if (appointmentId)
      HttpService.get(`appointments/getAppointment/${appointmentId}`).then(
        (result) => {
          if (result?.status) {
            setAppointment(result?.data ?? null);
            setIsOpenModal(true);
          }
        }
      );
  }, [appointmentId]);
  return { appointment, isOpenModal, setIsOpenModal };
}

function updateAppointmentsPendingConfirmation({
  appointment_id = null,
  appointments_status,
  comment = "",
  electronic_payment = false,
  payment_on_site = false,
}: {
  appointment_id: number | null | undefined;
  appointments_status: number;
  comment: string;
  electronic_payment: boolean | null;
  payment_on_site: boolean | null;
}) {
  return HttpService.post("specialist/updateAppointmentsPendingConfirmation", {
    appointment_id,
    appointments_status,
    comment,
    electronic_payment,
    payment_on_site,
  });
}

function useAppointmentsUpcoming({
  specialistSelected,
  refetch,
}: {
  specialistSelected?: number | null;
  refetch?: number;
}) {
  const [appointments, setAppointments] = useState<AppointmentUpcoming[]>([]);
  const [groupedAppointments, setGroupedAppointments] =
    useState<GroupedAppointments>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getAppointmentsUpcoming(specialistSelected);
    const newIntervalId = setInterval(() => {
      getAppointmentsUpcoming(specialistSelected);
    }, 60000);
    return () => clearInterval(newIntervalId);
  }, [specialistSelected, refetch]);

  useEffect(() => {
    setGroupedAppointments({});
    setLoading(true);
    const groupedAppointments_: GroupedAppointments = {};
    appointments.forEach((appointment) => {
      const dateKey = formatDate(appointment.start_datetime);
      if (!groupedAppointments_[dateKey]) groupedAppointments_[dateKey] = [];

      groupedAppointments_[dateKey].push({
        ...appointment,
      });
    });
    setGroupedAppointments(groupedAppointments_);
    setLoading(false);
  }, [appointments]);

  function getAppointmentsUpcoming(
    specialistSelected: number | null | undefined
  ) {
    if (!specialistSelected) return;
    setAppointments([]);
    setError(false);
    HttpService.get(`specialist/getAppointmentsUpcoming/${specialistSelected}`)
      .then((result) => {
        const data = result?.data as AppointmentUpcoming[];
        setAppointments(data);
      })
      .catch(() => setError(true));
  }

  return { groupedAppointments, loading, error };
}

function updateAppointmentsCancel({
  appointment_id,
  appointments_status,
}: {
  appointment_id: number;
  appointments_status: number;
}) {
  return HttpService.post("specialist/updateAppointmentsCancel", {
    appointment_id,
    appointments_status,
  });
}

function updateAppointmentsStatus({
  appointment_id,
  appointments_status,
}: {
  appointment_id: number;
  appointments_status: number;
}) {
  return HttpService.post("appointments/updateAppointmentsStatus", {
    appointment_id,
    appointments_status,
  });
}

function formatDate(date: string) {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
}

export {
  updateAppointmentsCancel,
  updateAppointmentsPendingConfirmation,
  updateAppointmentsStatus,
  useAppointment,
  useAppointmentsPendingConfirmation,
  useAppointmentsPendingConfirmationId,
  useAppointmentsUpcoming,
};
