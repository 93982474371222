import { Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import SimpleMap from "../../components/map/Map";
import Calendar from "./components/calendar";
import useFetchSpecialist from "./hooks/useFetchSpecialist";

import { Rating, Zoom } from "@mui/material";
import { useEffect, useState } from "react";
import { IMessage, Message } from "../../components/message";
import {
  ILanguage,
  INetwork,
} from "../../interfaces/specialist/specialist.interface";
import css from "./specialist.module.css";
import { useTranslation } from "react-i18next";

interface LanguageListProps {
  languages?: ILanguage[] | null | undefined;
}

interface SocialNetworkProps {
  networks?: INetwork[] | null | undefined;
}

interface ProcedimientoTipo {
  name: string;
  data: string[];
}

export default function Specialistpage(): JSX.Element {
  const { id } = useParams();
  const otherCirugie = "Otras cirugias";  
  const { i18n, t } = useTranslation()
  const { specialist, loading, error } = useFetchSpecialist(id);
  const [valor, setValor] = useState("0");
  const [idiomas, setidiomas] = useState("");
  const [procedimientos, setProcedimientos] = useState<ProcedimientoTipo[]>([]);

  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });
  useEffect(() => {
    if (specialist.appointmentTypePrimary?.length) {
      let tempvalor = specialist.appointmentTypePrimary[0];
      const numeroFormateado = new Intl.NumberFormat("es-ES", {
        minimumFractionDigits: 0,
      }).format(parseFloat(tempvalor.price));

      setValor(numeroFormateado ?? "0");
    }
    if (specialist.procedure?.length) {
      const groupedProcedimientos = specialist.procedure.reduce(
        (acc: { [key: string]: string[] }, procedimiento) => {
          const tipoProcedimiento = procedimiento.domainame;
          if (!acc[tipoProcedimiento]) {
            acc[tipoProcedimiento] = [];
          }
          let nameorigin = procedimiento?.typeid
            ? procedimiento?.name || ""
            : procedimiento?.nametemp;
          acc[tipoProcedimiento].push(nameorigin ?? "");
          return acc;
        },
        {}
      );

      const procedimientosArray: ProcedimientoTipo[] = Object.entries(
        groupedProcedimientos
      ).map(([name, data]) => ({
        name,
        data,
      }));
      console.log(procedimientosArray);
      setProcedimientos(procedimientosArray);
    }
  }, [specialist]);

  function generarEnlace() {
    // Codificar el ID en base64
    const base64Id = btoa(`0000${specialist.id?.toString() || ""}`);
    // Concatenar el dominio con la ruta /cp/ y el ID codificado
    const enlace = `${process.env.REACT_APP_API}/cp/${base64Id}`;
    return enlace;
  }

  const copiarAlPortapapeles = () => {
    const enlace = generarEnlace();
    navigator.clipboard
      .writeText(enlace)
      .then(() => {
        setMessage({
          show: true,
          message: "¡Enlace copiado al portapapeles!",
        });
      })
      .catch((err) => {
        setMessage({
          show: true,
          message: "Error al copiar el enlace: ",
        });
      });
  };
  function LanguageList({ languages }: LanguageListProps) {
    if (!languages || languages.length === 0) {
      return <></>;
    }

    let languageString = "";
    for (let i = 0; i < languages.length; i++) {
      const languageName = languages[i]?.domain?.name;
      if (languageName) {
        languageString += languageName;
        if (i < languages.length - 2) {
          languageString += ", ";
        }
        if (i === languages.length - 2) {
          languageString += " y ";
        }
      }
    }

    return (
      <Col className={css.Col}>
        <Form.Label>
          <i className="bi bi-translate profileIcon"></i>
          {`     Habla ${languageString} `}
        </Form.Label>
      </Col>
    );
  }
  function SocialNetworks({ networks }: SocialNetworkProps) {
    const openNetwork = (url: any) => {
      window.open(url, "_blank");
    };

    return (
      <div>
        {networks?.map((network) => (
          <>
            {network?.domain?.name !== "Pagina web" ? (
              <div
                key={network.id}
                onClick={() => openNetwork(network.url)}
                style={{ cursor: "pointer" }}
                title={network?.domain?.name ?? ""}
                className={css.network}
              >
                <i className={`bi ${network?.domain?.icon}   profileIcon`}></i>
              </div>
            ) : (
              ""
            )}
          </>
        ))}
      </div>
    );
  }

  function PageWeb({ networks }: SocialNetworkProps) {
    const openNetwork = (url: any) => {
      window.open(url, "_blank");
    };

    return (
      <div>
        {networks?.map((network) => (
          <>
            {network?.domain?.name === "Pagina web" ? (
              <div
                key={network.id}
                onClick={() => openNetwork(network.url)}
                style={{ cursor: "pointer" }}
                title={network?.domain?.name ?? ""}
                className={css.networkWeb}
              >
                <i className={`bi bi-globe2 profileIcon m-2`}></i>
                {network?.url ?? ""}
              </div>
            ) : (
              ""
            )}
          </>
        ))}
      </div>
    );
  }
  if (error)
    return (
      <Container>
        <h2>
          {t('specialistInfoError')}
        </h2>
      </Container>
    );

  if (loading)
    return (
      <Container>
        <h2>{t('consultingFavoriteSpecialist')}</h2>
      </Container>
    );

  return (
    <>
      <Container className={css.container} fluid>
        <Row>
          <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9}>
            <Row className="p-2">
              <Col xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                {" "}
                <Row className=" justify-content-end">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{ textAlign: "left" }}
                  >
                    <div
                      onClick={() => window.history.back()}
                      style={{ cursor: "pointer" }}
                      title="Atras"
                      className={css.share}
                    >
                      <i
                        className={`bi bi-arrow-left-circle profileIcon m-2`}
                      ></i>
                      <Form.Label className={css.labelDesc}>{t('back')}</Form.Label>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={6} sm={6} md={8} lg={8} xl={8} xxl={8}>
                <Row className=" justify-content-end">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{ textAlign: "right" }}
                  >
                    <div
                      onClick={() => copiarAlPortapapeles()}
                      style={{ cursor: "pointer" }}
                      title={t('shareProfile')}
                      className={css.share}
                    >
                      <i className={`bi bi-share profileIcon m-2`}></i>
                      <Form.Label className={css.labelDesc}>
                        {t('share')}
                      </Form.Label>
                    </div>
                    <div
                      onClick={() => console.log(t('addFavorites'))}
                      style={{ cursor: "pointer",display:"none" }}
                      title={t('addFavorites')}
                      className={css.fav}
                    >
                      <i className={`bi bi-suit-heart profileIcon m-2`}></i>
                      <Form.Label className={css.labelDesc}>
                        {t('favorites')}
                      </Form.Label>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <div className={css.profile}>
                  <Col>
                    <img className={css.photo} src={specialist?.photo} />
                  </Col>

                  <Col className={css.Col}>
                    <Form.Label className={css.titleprimary}>
                      {`${specialist?.gender == 7 ? "Dra. " : "Dr. "} ${
                        specialist?.name
                      } ${specialist?.surname}`}
                    </Form.Label>
                  </Col>

                  <Col className={css.Col}>
                    <Form.Label>{`${specialist?.short_description}`}</Form.Label>
                  </Col>
                  <Col className={css.Col}>
                    <Form.Label className={css.title}>
                      {" "}
                      Tarjeta Profesional
                    </Form.Label>
                    <Form.Label>{`${specialist?.card_number}`}</Form.Label>
                  </Col>
                  <Col className={css.Col}>
                    <Form.Label className={css.title}>Consulta</Form.Label>
                    <Form.Label>{`$${valor} COP`}</Form.Label>
                  </Col>

                  <LanguageList languages={specialist?.language} />
                  <Col className={css.Col}>
                    <SocialNetworks
                      networks={specialist?.network}
                    ></SocialNetworks>
                  </Col>
                  <Col className={css.Col}>
                    <PageWeb networks={specialist?.network}></PageWeb>
                  </Col>
                  {specialist.qualification !== 0 && (
                    <Col className={css.Col}>
                      <Form.Label className={css.title}>
                        {t('record')}{" "}
                        <span className={css.qualification}>
                          {specialist.qualification}
                        </span>
                      </Form.Label>
                      <Rating
                        name="read-only"
                        value={specialist.qualification}
                        readOnly
                      />
                    </Col>
                  )}
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
                className={`scroll-container ${css.containerInfo}`}
              >
                <Row className="p-3">
                  <fieldset className={css.fieldsetCustom}>
                    <legend className={css.legendCustom}>{t('aboutMe')}</legend>
                    <Form.Label
                      className={css.labelDesc}
                      dangerouslySetInnerHTML={{
                        __html: `${specialist?.about}`,
                      }}
                    ></Form.Label>
                  </fieldset>
                </Row>
                <Row className="p-3">
                  <fieldset className={css.fieldsetCustom}>
                    <legend className={css.legendCustom}>
                      {t('patientReviews')}
                    </legend>
                    <Form.Label
                      className={css.labelDesc}
                    >{`${t('reviewaEmpty')}`}</Form.Label>
                  </fieldset>
                </Row>
                <Row className="p-3">
                  <fieldset className={css.fieldsetCustom}>
                    <legend className={css.legendCustom}>{t('procedures')}</legend>
                    <Row>
                      {procedimientos.map((tipoProcedimiento, index) => (
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                          <Form.Label className={css.titleprocedure}>
                            {tipoProcedimiento.name}
                          </Form.Label>
                          <ul>
                            {tipoProcedimiento.data.map(
                              (procedimiento, index) => (
                                <li key={index}>
                                  <Form.Label className={css.labelDesc}>
                                    {procedimiento}
                                  </Form.Label>
                                </li>
                              )
                            )}
                          </ul>
                        </Col>
                      ))}
                    </Row>
                  </fieldset>
                </Row>
                <Row className="p-3">
                  <fieldset className={css.fieldsetCustom}>
                    <legend className={css.legendCustom}>{t('studies')}</legend>
                    <ul>
                      {specialist?.study?.map((study, index) => (
                        <li key={index} className={css.liresponsive}>
                          {" "}
                          <Form.Label className={css.labelDesc}>
                            {`${study?.name}`}
                          </Form.Label>
                        </li>
                      ))}
                    </ul>
                  </fieldset>
                </Row>

                <Row className="p-3">
                  <fieldset className={css.fieldsetCustom}>
                    <legend className={css.legendCustom}>
                      {t('awardsRecognitions')}
                    </legend>
                    <ul>
                      {specialist?.reward?.map((reward, index) => (
                        <li key={index} className={css.liresponsive}>
                          <Form.Label className={css.labelDesc}>
                            {`${reward?.name}`}
                          </Form.Label>
                        </li>
                      ))}
                    </ul>
                  </fieldset>
                </Row>
                <Row className="p-3">
                  <fieldset className={css.fieldsetCustom}>
                    <legend className={css.legendCustom}>{t('contact')}</legend>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Label className={css.titleprocedure}>
                          {t('address')}:{" "}
                        </Form.Label>
                        <Form.Label>{specialist?.office_address}</Form.Label>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Label className={css.titleprocedure}>
                          {t('phone')}:{" "}
                        </Form.Label>
                        <Form.Label>{specialist?.phones}</Form.Label>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Label className={css.titleprocedure}>
                          {t('Map')}:{" "}
                        </Form.Label>
                        {specialist?.latitude && specialist?.longitude && (
                          <SimpleMap
                            center={{
                              lat: specialist?.latitude,
                              lng: specialist?.longitude,
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </fieldset>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            style={{ textAlign: "center" }}
            className={css.containerCalendar}
          >
            <i
              className="bi bi-calendar-check"
              style={{ fontSize: "3rem" }}
            ></i>
            <br></br>
            <Form.Label className={css.titleprimary}>
              {`${t('bookAnAppointment')}`}
            </Form.Label>
            <Calendar id={id} specialist={specialist} />
          </Col>
        </Row>
      </Container>
      {message?.show ? (
        <Message
          show={message?.show}
          message={message?.message}
          title="Compartir"
          hide={() => setMessage((prev) => ({ ...prev, show: false }))}
        />
      ) : null}
    </>
  );
}
