import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Button, Container, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CarouselTop from "../../components/carousel/carouselTop";
import { useDebounce } from "../../hooks/useDebounce";
import css from "./home.module.css";
import { useFetchCity } from "./hooks/useFetchCity";
import { useFetchProcedure } from "./hooks/useFetchProcedure";
import { useFetchSpecialist } from "./hooks/useFetchSpecialist";
import { useTranslation } from "react-i18next";

function Homepage(): JSX.Element {
  const [specialist, setSpecialist] = useState<string>("");
  const debouncedSpecialist = useDebounce(specialist);
  const { specialists, loading: loadingSpecialists } = useFetchSpecialist(debouncedSpecialist);
  const { i18n, t } = useTranslation();
  const [procedure, setProcedure] = useState<string>("");
  const { procedures, loading: loadingProcedures } = useFetchProcedure();
  const [city, setCity] = useState<string>("");
  const { cities, loading: loadingCities } = useFetchCity();
  const [search, setSearch] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const matchedSpecialist = specialists.find(({ name, surname }) => `${name} ${surname}` === specialist);
    if (search ) {
      if(matchedSpecialist){

        navigate(`./specialist/${matchedSpecialist.id}`);
      }else{
        navigate(
          `./specialists?&specialist=${specialist?.trim()}&procedure=${procedure}&city=${city}`
        );
      }
    }
  }, [search]);

  return (
    <>
      <div className="imgHomeHeader" />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          position: "relative",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/*
        <InputGroup
          className={"inputSearch"}
          style={{ width: "50%", background: "#fff" ,display:"none"}}
        >
          <Autocomplete
            options={specialists.map(
              ({ name, surname }) => `${name} ${surname}`
            )}
            inputValue={specialist}
            onInputChange={(event, value) => setSpecialist(value)}
            loading={loadingSpecialists}
            freeSolo
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('specialist')}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  className: css["input-search"],
                  endAdornment: (
                    <Fragment>
                      {loadingSpecialists ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
            style={{ flex: "1 1 auto", width: "1%", minWidth: "0" }}
          />
          <Autocomplete
            options={procedures}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(event, value) => setProcedure(value?.id || "")}
            loading={loadingProcedures}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('procedure')}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  className: css["input-search"],
                  endAdornment: (
                    <Fragment>
                      {loadingSpecialists ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
            style={{ flex: "1 1 auto", width: "1%", minWidth: "0" }}
          />
          <Autocomplete
            options={cities}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(event, value) => setCity(value?.id || "")}
            loading={loadingCities}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('city')}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  className: css["input-search-r"],
                  endAdornment: (
                    <Fragment>
                      {loadingSpecialists ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
            style={{ flex: "1 1 auto", width: "1%", minWidth: "0" }}
          />
          <Button className={css.search} onClick={() => setSearch(true)} />
        </InputGroup>
*/ }
        <h4
          style={{
            color: "#fff",
            marginTop: "20px",
            width: "40%",
            textAlign: "center",
          }}
        >
          {t('bestSpecialists')}
        </h4>
      </div>
      <Container fluid={true} className="carouselTop">
        <CarouselTop />
      </Container>
      <div
        className="home-content"
        style={{
          display: "flex",
          flexWrap: "wrap",
          position: "relative",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <a href="/specialists?&specialist=&procedure=&city=">
          {t('allSpecialists')}<b>{`   >`}</b>
        </a>
        <div className="mt-5 mb-5 home-info">
          <h4>{t('whatProcedures')}</h4>
          <label style={{ width: "90%" }}>
            {t('plasticSurgeryInfo')}
          </label>
          <a href="/procedures">
            <button className="btn btn-primary btn-procedures">
              {t('moreProcedures')}
            </button>
          </a>
        </div>
      </div>
    </>
  );
}

export default Homepage;
