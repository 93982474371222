import dayjs from "dayjs";
import React from "react";
import { Card } from "react-bootstrap";

interface PatientAppointmentProps {
  data: any;
  handleAction: any | null;
}
const PatientAppointment = ({
  data,
  handleAction,
}: PatientAppointmentProps) => {
  console.log(data);
  return (
    <div>
      {data && data?.appointments?.length > 0 ? (
        data.appointments.map((item: any) => {
          // Elimina las llaves aquí
          return (
            <Card key={item.id} className="mx-5 my-3 p-2">
              <div className="d-flex align-items-center justify-content-end">
                <span
                  style={{
                    display: "block",
                    height: "12px",
                    width: "12px",
                    marginRight: "2px",
                    background: `${
                      item?.appointments_status == 27
                        ? "#00bb2d"
                        : item?.appointments_status == 30
                        ? "#fe0000"
                        : "#f7ff00"
                    }`,
                    borderRadius: "50px",
                  }}
                />
                <span
                  style={{
                    marginRight: "1em",
                    fontSize: "10px",
                  }}
                  className="text-secondary"
                >
                  {item?.appointment_status.description}
                </span>
              </div>
              <div style={{
                    marginLeft: "1em",
                    marginBottom:"0.5em"
                  }}>
                <span className="h6">
                  <strong>
                    {
                      item?.specialistAppointmentType?.appointments_type_id
                        ?.name
                    }
                  </strong>
                </span>
              </div>
              <div style={{
                    marginLeft: "1em",
                    marginBottom:"0.5em"
                  }}>
                <span className="text-secondary">{`${new Date(item?.start_datetime).getDate()}-
              ${new Date(item?.start_datetime).toLocaleString("default", {
                month: "short",
              })}-
              ${new Date(item?.start_datetime).getFullYear()} 
              ${dayjs(item?.start_datetime).format("LT")}-
              ${dayjs(item?.end_datetime).format("LT")}`}</span>
              </div>
              <div style={{
                    marginLeft: "1em",
                  }}>
                <span>
                  <strong style={{fontWeight:"bold"}}>Tipo de pago</strong>: {item?.payment_on_site ? "En sitio" : "En linea"}
                </span>
                <span style={{
                    marginLeft: "1em",

                  }}>
                  <strong style={{fontWeight:"bold"}}>Valor </strong>: {new Intl.NumberFormat("es-ES", {
                            minimumFractionDigits: 0,
                          }).format(parseFloat(item?.specialistAppointmentType?.price))}{" "}
                  {item?.specialistAppointmentType?.currencys_type?.name}
                </span>
              </div>
            </Card>
          );
        })
      ) : (
        <span>sin resultados</span>
      )}
    </div>
  );
};

export default PatientAppointment;
