import { useEffect, useState } from "react";
import HttpService from "../../../../../../../services/http";
import {
  IExcludedDays,
  IExcludedHours,
  IWorkingDays,
} from "../../../../../../../interfaces/schedule/schedule.interface";

export default function useFetchSchedule(
  id: number | null | undefined,
  refreshSchedule: boolean
) {
  const [workingDays, setWorkingDays] = useState<IWorkingDays[]>([]);
  const [excludedDays, setExcludedDays] = useState<IExcludedDays[]>([]);
  const [excludedHours, setExcludedHours] = useState<IExcludedHours[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      setWorkingDays([]);
      setExcludedDays([]);
      setExcludedHours([]);
      HttpService.get(`calendars/${id}`).then((result) => {
        const { status, data } = result;
        if (status) {
          setWorkingDays((data?.workingDays as IWorkingDays[]) || []);
          setExcludedDays((data?.excludedDays as IExcludedDays[]) || []);
          setExcludedHours((data?.excludedHours as IExcludedHours[]) || []);
        }
        setLoading(false);
      });
    }
  }, [id, refreshSchedule]);

  return {
    workingDays,
    setWorkingDays,
    excludedDays,
    setExcludedDays,
    excludedHours,
    setExcludedHours,
    loading,
    setLoading,
  };
}
