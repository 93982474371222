import React, { useEffect, useState } from "react";
import { ListGroup, Form, Button, Row, Col, Container } from "react-bootstrap";
import { specialistprofile } from "../../../../../../types/specialist";
import { usePanelPage } from "../../../../panelPage";
import { useAuth } from "../../../../../../middlewares/auth";
import { IUserLoged } from "../../../../../../interfaces/user/user.interface";
import { IMessage, Message } from "../../../../../../components/message";
import {
  ISpecialist,
  ILanguage,
} from "../../../../../../interfaces/specialist/specialist.interface";
import ReactSelect from "react-select";
import { styleselectreact } from "../../../../../../styles";

const LanguageSection = ({
  Language,
  setLanguages,
  idSpecialist,
  list
}: {
  Language: ILanguage[];
  setLanguages: any;
  idSpecialist: any;
  list:any;
}) => {
  const [newLanguage, setNewLanguage] = useState<any>(null);

  const [options, setoptions] = useState<any[]>([]);
  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  const handleAddLanguage = () => {
    if (newLanguage) {
      // Verificar si el idioma ya existe en el array Language
      const languageExists = Language?.some(
        (language) => language?.domain?.id === newLanguage.value
      );

      if (!languageExists) {
        // Obtener el ID del especialista
        const specialistId: number | undefined = idSpecialist ?? undefined;

        // Crear el nuevo idioma con un ID temporal (-1)
        const newLanguageObject: ILanguage = {
          id: -1,
          language_id: newLanguage.value,
          specialist_id: specialistId,
          domain: {
            id: newLanguage.value,
            name: newLanguage.label,
            icon: null
          }
        };

        // Actualizar el array de idiomas
        const updatedLanguages: ILanguage[] = [...Language, newLanguageObject];
        setLanguages(updatedLanguages);
        setNewLanguage(""); // Limpiar el valor del nuevo idioma
      } else {
        setMessage({ show: true, message: "¡El idioma ya existe!" });
      }
    } else {
      setMessage({
        show: true,
        message: "Por favor, seleccione un idioma válido",
      });
    }
  };

  const handleRemoveLanguage = (id: number) => {
    const updatedLanguages = Language?.filter(
      (data) => data.language_id !== id
    );
    setLanguages(updatedLanguages);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewLanguage(e.target.value);
  };

  useEffect(() => {
    debugger
    const selectOptions: any[] = list.map((option: any) => ({
      value: option.id,
      label: option.name,
    }));
    setoptions(selectOptions);
  }, [list]);

  return (
    <>
      <fieldset className="border p-2 mt-3 mb-3">
        <legend className="w-auto">Idiomas</legend>
        <Row>
          <Col xs={10} sm={10} className="pt-2">
            <ReactSelect
              value={newLanguage}
              onChange={setNewLanguage}
              options={options}
              placeholder="Seleccionar"
              noOptionsMessage={({ inputValue }) => {
                return inputValue
                  ? "No se encontraron opciones"
                  : "Ingrese un valor para comenzar la búsqueda";
              }}
              styles={styleselectreact}
              isSearchable
              menuPortalTarget={document.body}
            />
          </Col>
          <Col xs={2} sm={2}>
            <button
              title="Agregar"
              className="btnadd"
              onClick={handleAddLanguage}
            >
              <i className="bi bi-node-plus-fill"></i>
            </button>
          </Col>
        </Row>

        <ListGroup className="profilecontainer">
          {Language.map((dato) => (
            <ListGroup.Item key={dato.language_id} className="pt-1">
              <Row>
                <Col xs={10} sm={10}>
                  {dato.domain?.name}
                </Col>
                <Col xs={2} sm={2}>
                  <button
                    title="Eliminar"
                    className="btndelete"
                    onClick={() => handleRemoveLanguage(dato.language_id ?? 0)}
                  >
                    <i className="bi bi-trash3"></i>
                  </button>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </fieldset>
      {message?.show ? (
        <Message
          show={message?.show}
          message={message?.message}
          hide={() => setMessage((prev) => ({ ...prev, show: false }))}
        />
      ) : null}
    </>
  );
};
export default LanguageSection;
