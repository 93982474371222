import { PropsWithChildren } from "react";
import Footer from "../layouts/footer/footer";
import Header from "../layouts/header/header";
import { AuthMiddleware } from "../middlewares/auth";

export default function Rootpage({ children }: PropsWithChildren): JSX.Element {
  return (
    <AuthMiddleware>
      <>
        <Header />
        {children}
        <Footer />
      </>
    </AuthMiddleware>
  );
}
