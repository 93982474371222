import { useCallback } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "300px",
};

export default function SimpleMap({ center }: { center: any }): JSX.Element {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyATjozl7y1EIh2QeFOIsBWe2W36ogtgtDk",
  });

  const onLoad = useCallback(
    function callback(map: google.maps.Map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      map.setZoom(20)
    },
    [center]
  );

  const onUnmount = useCallback(function callback(map: google.maps.Map) {}, []);
const option ={
  minZoom: 4,
  maxZoom: 18,
}
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={option}
    >
      {<Marker position={center}  />}
    </GoogleMap>
  ) : (
    <></>
  );
}
