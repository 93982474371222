import { useState, useEffect } from 'react';
import HttpService from '../../../services/http';
export interface IcalendarProps {
  specialistId: number,
  callback?: any,
  selectMonth?: string,
  selectYear?: string
}

const useFetchUnworkingDays = (props: IcalendarProps) => {
  const [data, setData] = useState<number[] | null>(null);
  const [excludedDays, setExcludedDays] = useState<any>(null);
  useEffect(() => {
    if (props.specialistId) {

      HttpService.get(`unworkingDays/${props.specialistId}`)
        .then((result) => {
          const { status, data } = result;
          if (status) {
            setData(data);
            if (props.callback) {
              props.callback(data);
            }
          }

        })
        .catch((e) => {
          console.log(e)
        });
    }
  }, [props.specialistId]);
  useEffect(() => {
    if (props.specialistId && props.selectMonth) {

      HttpService.get(`specialists/${props.specialistId}/unavailable-dates/${props.selectMonth}/${props.selectYear}/`)
        .then((result) => {
          const { status } = result;
          if (status) {
            const data = result?.data as string[];
            setExcludedDays(data);
          }

        })
        .catch((e) => {
          console.log(e)
        });
    }
  }, [props.specialistId, props.selectMonth, props.selectYear]);

  return { data, excludedDays };
};

export default useFetchUnworkingDays;
