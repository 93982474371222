import React, { useState } from "react";
import { Col, Form, ListGroup, Row } from "react-bootstrap";
import { IMessage, Message } from "../../../../../../components/message";
import { IReward } from "../../../../../../interfaces/specialist/specialist.interface";

const RewardSection = ({
  rewards,
  setRewards,
  idSpecialist,
}: {
  rewards: IReward[];
  setRewards: any;
  idSpecialist: any;
}) => {
  const [newReward, setNewReward] = useState("");

  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  const handleAddReward = () => {
    if (newReward.trim() !== "") {
      // Comprueba si la recompensa ya existe
      const rewardExists = rewards?.some(
        (reward) => reward.name === newReward.trim()
      );

      if (!rewardExists) {
        // Asignar un valor predeterminado a specialist_id si es nulo o indefinido
        const specialistId = idSpecialist ?? null;

        const updatedRewards = [
          ...rewards,
          {
            id: -1,
            name: newReward.trim(),
            specialist_id: specialistId,
          },
        ];
        setRewards(updatedRewards);
        setNewReward("");
      } else {
        setMessage({ show: true, message: "¡La recompensa ya existe!" });
      }
    } else {
      setMessage({
        show: true,
        message: "Por favor, ingrese una recompensa válida",
      });
    }
  };

  const handleRemoveReward = (id: number) => {
    const updatedRewards = rewards?.filter((reward) => reward.id !== id);
    setRewards(updatedRewards);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewReward(e.target.value);
  };

  return (
    <>
      <fieldset className="border p-2 mt-3 mb-3">
        <legend className="w-auto">Agremiaciones</legend>
        <Row>
          <Col xs={10} sm={10} className="pt-2">
            <Form.Control
              type="text"
              placeholder="Nueva recompensa"
              value={newReward}
              onChange={handleChange}
            />
          </Col>
          <Col xs={2} sm={2}>
            <button
              title="Agregar"
              className="btnadd"
              onClick={handleAddReward}
            >
              <i className="bi bi-node-plus-fill" />
            </button>
          </Col>
        </Row>

        <ListGroup className="profilecontainer">
          {rewards.map((reward) => (
            <ListGroup.Item key={reward.id} className="pt-1">
              <Row>
                <Col xs={10} sm={10}>
                  {reward.name}
                </Col>
                <Col xs={2} sm={2}>
                  <button
                    title="Eliminar"
                    className="btndelete"
                    onClick={() => handleRemoveReward(reward.id ?? 0)}
                  >
                    <i className="bi bi-trash3" />
                  </button>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </fieldset>
      {message?.show ? (
        <Message
          show={message?.show}
          message={message?.message}
          hide={() => setMessage((prev) => ({ ...prev, show: false }))}
        />
      ) : null}
    </>
  );
};
export default RewardSection;
