import dayjs from "dayjs";
import "dayjs/locale/es";
import { createContext, useContext, useEffect, useState } from "react";
import { Button, Carousel, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Confirmation, IWarning, Message } from "../../../components/message";
import { useAuth } from "../../../middlewares/auth";
import HttpService from "../../../services/http";
dayjs.locale("es");

interface TimeSlots {
  date: string;
  times: string[];
}

interface Schedule {
  [key: string]: string[];
}

interface Appointment {
  created_at: Date;
  end_datetime: Date;
  id: number;
  patient_id: number;
  specialist_appointment_type_id: number;
  specialist_id: number;
  start_datetime: Date;
  updated_at: Date;
}

interface AppointmentType {
  appointment_type_id: number;
  appointments_type_id: { id: number; name: string };
  currency_type: number;
  currencys_type: { id: number; name: string };
  duration: number;
  id: number;
  price: string;
  principal_appointment: number;
  specialist_id: number;
  specialists_id: {
    id: number;
    name: string;
    surname: string;
    card_number: number;
  };
}

function useFetchTimeSlots(
  specialist_id?: string | number,
  date?: dayjs.Dayjs | null
) {
  const [daysByMonth, setDaysByMonth] = useState<TimeSlots[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!date) return;

    setLoading(true);
    HttpService.get(
      `available-slots-month/${specialist_id}/${date?.get("month")}/${date?.get(
        "year"
      )}`
    ).then((result) => {
      const dates = Object.keys(
        Object.fromEntries(
          Object.entries(result?.data as Schedule).filter(
            ([key, value]) => value?.length > 0
          )
        ) ?? {}
      );
      setDaysByMonth(
        dates.map((date) => {
          return {
            date,
            times: result?.data[date] || [],
          };
        })
      );
      setLoading(false);
    });
  }, [specialist_id, date]);

  return { daysByMonth, loadingDaysByMonth: loading };
}

function MyCarousel({ data }: { data: TimeSlots[] }): JSX.Element {
  const {
    selected,
    setSelected,
  }: {
    selected: PropsSelected | undefined;
    setSelected: React.Dispatch<
      React.SetStateAction<PropsSelected | undefined>
    >;
  } = useCalendar();
  const [chunks, setChunks] = useState<TimeSlots[][]>([]);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    const copy = [];
    const chunkSize = 4;
    for (let iterator = 0; iterator < data.length; iterator += chunkSize)
      copy.push(data.slice(iterator, iterator + chunkSize));
    setChunks(copy);
  }, [data]);

  function Item({ chunk }: { chunk: TimeSlots[] }): JSX.Element {
    return (
      <table className="table table-bordered table-search-calendar">
        <thead>
          <tr>
            {chunk?.map(({ date }) => (
              <th key={date}>{date}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {chunk?.map(({ date, times }) => (
              <td key={date}>
                {times?.map((time) => (
                  <div
                    key={`${date}_${time}`}
                    className={`time-date ${
                      selected?.date?.isSame(dayjs(date)) &&
                      selected?.hour === time &&
                      "selected"
                    }`}
                    onClick={() =>
                      setSelected({ date: dayjs(date), hour: time })
                    }
                  >
                    {time}
                  </div>
                ))}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  }

  return (
    <Carousel
      activeIndex={index}
      onSelect={(selectedIndex) => setIndex(selectedIndex)}
      interval={null}
    >
      {chunks?.map((chunk, index) => (
        <Carousel.Item key={index}>
          <Item chunk={chunk} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

interface Props {
  specialist_id?: string | number;
}

const CalendarContext = createContext<any>({});

function useCalendar() {
  const context = useContext(CalendarContext);
  if (!context) throw new Error("Cant get the context");
  return context;
}

interface PropsSelected {
  date: dayjs.Dayjs | null;
  hour: String | null;
}

export default function CalendarSectorized({
  specialist_id,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const { state } = useAuth();

  const [verifyLogin, setVerifyLogin] = useState<boolean>(false);

  const [initialDate] = useState<dayjs.Dayjs>(dayjs());
  const [date, setDate] = useState<dayjs.Dayjs | null>();
  const [dates, setDates] = useState<string[]>([]);

  const [sending, isSending] = useState<boolean>(false);

  const [appointment, setAppointment] = useState<Appointment>();
  const [appointmentType, setAppointmentType] = useState<AppointmentType[]>([]);
  const [appointmentTypeselect, setAppointmentTypeselect] =
    useState<AppointmentType | null>();

  const [warning, setWarning] = useState<IWarning>({
    show: false,
    message: "",
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      if (date != null) return;

      console.info("setTimeout");
      HttpService.get(`first-work-day/${specialist_id}`)
        .then((result) => {
          if (
            result?.status &&
            result?.data &&
            Object.keys(result?.data).length > 0
          ) {
            const firstDate =
              Object?.keys(result?.data)?.find(
                (date) => result?.data[date].length > 0
              ) ?? null;
            if (firstDate) setDate(dayjs(firstDate));
          }
        })
        .catch(() => setDate(dayjs()));

      HttpService.post("specialists/getTypeAppointment", {
        specialist_id,
      }).then((result) => {
        const { status } = result;
        if (status) setAppointmentType(result?.data);
      });
    }, 1500);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  useEffect(() => {
    if (!date) return;

    let i = 0;
    const copyDates = [];
    if (date.isAfter(initialDate))
      copyDates.push(date.clone().add(-1, "month").format("MMMM-YYYY"));
    while (i < 5) {
      copyDates.push(date.clone().add(i, "month").format("MMMM-YYYY"));
      i++;
    }
    setDates(copyDates);
  }, [date]);

  useEffect(() => {
    const selectedAppointmentTypes = appointmentType.filter(
      (type) => type.principal_appointment === 1
    );
    setAppointmentTypeselect(
      selectedAppointmentTypes.length > 0 ? selectedAppointmentTypes[0] : null
    );
  }, [appointmentType]);

  const { daysByMonth, loadingDaysByMonth } = useFetchTimeSlots(
    specialist_id,
    date
  );

  const [selected, setSelected] = useState<PropsSelected>();
  const { t } = useTranslation();

  useEffect(() => setSelected(undefined), [date]);

  const createTemporalAppointment = () => {
    const hourStart = selected?.hour?.split("-")[0].split(":")[0];
    const minutesStart = selected?.hour?.split("-")[0].split(":")[1];
    const hourEnd = selected?.hour?.split("-")[1].split(":")[0];
    const minutesEnd = selected?.hour?.split("-")[1].split(":")[1];
    const data = {
      patient_id: null,
      specialist_id: `${specialist_id}`,
      specialist_appointment_type_id: `${appointmentTypeselect?.appointment_type_id}`,
      start_datetime: dayjs(selected?.date || new Date())
        .hour(Number(hourStart))
        .minute(Number(minutesStart))
        .second(0)
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: dayjs(selected?.date || new Date())
        .hour(Number(hourEnd))
        .minute(Number(minutesEnd))
        .second(0)
        .format("YYYY-MM-DD HH:mm:ss"),
    };
    return HttpService.post("appointments/appointments", data);
  };

  const validateLogin = () => {
    isSending(true);
    createTemporalAppointment()
      .then(async (result) => {
        const { status, data } = result;
        if (!status) {
          isSending(false);
          setWarning({
            show: true,
            message: data,
          });
          return;
        }
        const newAppointment = result?.data as Appointment;
        setAppointment(newAppointment);

        const resultSpecialist = await HttpService.post(
          "specialists/getProfileId",
          { id: specialist_id }
        );

        if (appointmentType && status && resultSpecialist?.status) {
          if (state?.isLoged) {
            navigate(`/purchase?id=${specialist_id}&hour=${selected?.hour}`, {
              preventScrollReset: true,
              state: {
                specialist: resultSpecialist?.data ?? {},
                requireLogin: false,
                price: appointmentTypeselect?.price,
                currency: appointmentTypeselect?.currencys_type.name,
                appointment: newAppointment,
                selectAppointment: {
                  selectedDate: selected?.date,
                  selectedHour: selected?.hour,
                },
              },
              replace: false,
            });
          } else setVerifyLogin(true);
        }
      })
      .catch((e) => {
        setWarning({
          show: true,
          message: e.message,
        });
      });
  };

  return (
    <CalendarContext.Provider value={{ selected, setSelected }}>
      <>
        <Row className="calendar-search">
          <Col className="d-flex flex-column justify-content-center align-items-center text-center">
            <Form.Select
              className="form-control select-calendar"
              disabled={loadingDaysByMonth}
              value={date?.format("MMMM-YYYY")}
              onChange={(event) => {
                const { value } = event.target;
                setDate(dayjs(value, "MMMM-YYYY"));
              }}
            >
              {dates.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        {loadingDaysByMonth ? (
          <div
            className="d-flex justify-content-center align-items-center loading"
            style={{ height: "150px" }}
          />
        ) : (
          <MyCarousel data={daysByMonth} />
        )}

        {selected && (
          <Row>
            <Col className="col d-flex justify-content-end align-items-center">
              <Form.Label className="mb-0">{`${selected?.date?.format(
                "DD/MM/YYYY"
              )} ${selected?.hour}`}</Form.Label>
              <Button
                variant="primary"
                disabled={sending}
                onClick={validateLogin}
              >
                {t("bookAppointment")}
              </Button>
            </Col>
          </Row>
        )}
      </>

      {verifyLogin && (
        <Confirmation
          hide={() => {
            isSending(false);
            setVerifyLogin(false);
          }}
          show={verifyLogin}
          message={t("loginToContinuePayment")}
          btn={{
            accept: {
              text: `${t("login")}`,
              event: async () => {
                const resultSpecialist = await HttpService.post(
                  "specialists/getProfileId",
                  { id: specialist_id }
                );
                navigate("/login", {
                  preventScrollReset: true,
                  state: {
                    redirect: {
                      url: `/purchase?id=${specialist_id}&hour=${selected?.hour}`,
                      props: {
                        specialist: resultSpecialist?.data ?? {},
                        selectAppointment: {
                          selectedDate: selected?.date,
                          selectedHour: selected?.hour,
                        },
                        price: appointmentTypeselect?.price,
                        currency: appointmentTypeselect?.currencys_type.name,
                        appointment,
                        preventScrollReset: true,
                        replace: false,
                      },
                    },
                  },
                  replace: false,
                });
              },
            },
            cancel: {
              text: `${t("continueWithoutSession")}`,
              event: async () => {
                const resultSpecialist = await HttpService.post(
                  "specialists/getProfileId",
                  { id: specialist_id }
                );
                navigate(
                  `/purchase?id=${specialist_id}&hour=${selected?.hour}`,
                  {
                    preventScrollReset: true,
                    state: {
                      specialist: resultSpecialist?.data ?? {},
                      selectAppointment: {
                        selectedDate: selected?.date,
                        selectedHour: selected?.hour,
                      },
                      price: appointmentTypeselect?.price,
                      currency: appointmentTypeselect?.currencys_type.name,
                      appointment,
                      requireLogin: false,
                    },
                    replace: false,
                  }
                );
              },
            },
          }}
        />
      )}
      {warning?.show ? (
        <Message
          show={warning?.show}
          message={warning?.message}
          hide={() => setWarning({ show: false })}
        />
      ) : null}
    </CalendarContext.Provider>
  );
}
