import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import HttpService from "../../../../../services/http";

interface SearchPatientsProps {
  idSpecialist: number | null | undefined;
  setFilteredData: React.Dispatch<React.SetStateAction<never[]>>;
}
const SearchPatient: React.FC<SearchPatientsProps> = ({
  idSpecialist,
  setFilteredData,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleSearch = async () => {
    const info = {
      specialistId: idSpecialist,
      filter: selectedValue,
    };
    try {
      const response = await HttpService.post(
        "specialists/getPatientsAppointment",
        info
      );
      const { data } = response;
      setFilteredData(data);
    } catch (error) {
      console.error("Error fetching payment data:", error);
    }
  };

  return (
    <>
    <h2 style={{color:"#0b4e8a"}}><i className="bi bi-people"/> Pacientes</h2>
    <Form>
      <Row>
        <Col>
          <Form.Group controlId="selectField">
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Documento/Nombres y Apellidos"
                aria-label="Documento/Nombres y Apellidos"
                aria-describedby="filtro"
                onChange={(e) => setSelectedValue(e.target.value)}
              />
              <InputGroup.Text
                id="filtro"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                data-bs-title="Dar click para buscar"
                style={{
                  background: "transparent",
                  borderBottomRightRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
              >
                <button
                  type="button"
                  onClick={handleSearch}
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title="Dar click para buscar"
                  className="button-search"
                >
                  <i className="bi bi-search" />
                </button>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col></Col>
      </Row>
    </Form>
    </>
  );
};

export default SearchPatient;
