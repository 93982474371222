import AppRoutes from "./routes";
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // Importa el idioma español para Colombia
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // Importa el plugin de zona horaria

dayjs.extend(utc);
dayjs.extend(timezone);

// Configura la zona horaria predeterminada para Colombia
dayjs.tz.setDefault('UTC'); 

// Establece el idioma en español para Colombia
dayjs.locale('es');


function App(): JSX.Element {
  return <AppRoutes />;
}

export default App;
