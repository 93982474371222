import { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import {
  IExcludedDays,
  IExcludedHours,
} from "../../../../../../../../interfaces/schedule/schedule.interface";
import { ScheduleContext, checkTimeInRange } from "../../schedule";
import { IMessage, Message } from "../../../../../../../../components/message";

import { DateCalendar, LocalizationProvider, esES } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import "dayjs/locale/es";
dayjs.extend(isBetween);

dayjs.locale("es");

export default function ExcludedHours(): JSX.Element {
  const {
    excludedHours,
    setExcludedHours,
    excludedDays,
  }: {
    excludedHours: IExcludedHours[];
    setExcludedHours: React.Dispatch<React.SetStateAction<IExcludedHours[]>>;
    excludedDays: IExcludedDays[];
  } = useContext(ScheduleContext);

  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  const [disabledDates, setDisabledDates] = useState<string[]>([]);

  const [add, setAdd] = useState<boolean>(false);

  const [allDays, setAllDays] = useState<boolean>(false);
  const [date, setDate] = useState<dayjs.Dayjs | null>(null);
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");

  useEffect(() => {
    updateDisabledDates();
    setAdd(false);
  }, [excludedDays]);

  function updateDisabledDates() {
    const newDisabledDates: string[] = [];
    excludedDays.forEach((excludedDay) => {
      const { startDay, endDay } = excludedDay;
      const startDate = dayjs(startDay);
      const endDate = endDay ? dayjs(endDay) : startDate;
      let currentDate = startDate;
      while (
        currentDate.isBefore(endDate) ||
        currentDate.isSame(endDate, "day")
      ) {
        newDisabledDates.push(currentDate.format("YYYY-MM-DD"));
        currentDate = currentDate.add(1, "day");
      }
    });
    setDisabledDates(newDisabledDates);
  }

  useEffect(() => {
    setAllDays(false);
    setDate(null);
  }, [add]);

  useEffect(() => {
    setDate(null);
  }, [allDays]);

  useEffect(() => {
    if (!allDays) {
      setStartTime("");
      setEndTime("");
    }
  }, [allDays, date]);

  const shouldDisableDate = (day: dayjs.Dayjs) => {
    const today = dayjs();
    const valueDate = dayjs(day);

    // Verifica si la fecha es anterior al día actual o si está incluida en las fechas deshabilitadas
    return (
      valueDate.isBefore(today, "day") ||
      disabledDates.includes(valueDate.format("YYYY-MM-DD"))
    );
  };
  function handleAdd() {
    const start = dayjs(startTime, "HH:mm");
    const end = dayjs(endTime, "HH:mm");

    if (!start.isBefore(end)) {
      setMessage({
        show: true,
        message: "La hora inicial debe ser menor a la hora final.",
      });
      return;
    }

    const copyExcludedHours: IExcludedHours[] = JSON.parse(
      JSON.stringify(excludedHours)
    );
    if (
      !allDays &&
      copyExcludedHours
        .filter(
          (excludedHour) =>
            excludedHour?.date === date?.format("YYYY-MM-DD") || ""
        )
        .some(
          (excludedHour) =>
            checkTimeInRange(excludedHour?.startTime, startTime, endTime) ||
            checkTimeInRange(excludedHour?.endTime, startTime, endTime)
        )
    ) {
      setMessage({
        show: true,
        message: `El rango de hora desde ${startTime} hasta ${endTime} ya se encuentra configurado.`,
      });
      return;
    }
    copyExcludedHours.push({
      date: date?.format("YYYY-MM-DD") || "",
      allDays,
      startTime,
      endTime,
    });
    setExcludedHours(copyExcludedHours);
    setAdd(false);
  }

  function handleChangeStartTime(valueTime: string) {
    const time = dayjs(valueTime, "HH:mm");
    const startTimeHours = time.get("hour")?.toString()?.padStart(2, "0");
    const startTimeMinutes = time.get("minute")?.toString()?.padStart(2, "0");
    setStartTime(`${startTimeHours}:${startTimeMinutes}:00`);
  }

  function handleChangeEndTime(valueTime: string) {
    const time = dayjs(valueTime, "HH:mm");
    const endTimeHours = time.get("hour")?.toString()?.padStart(2, "0");
    const endTimeMinutes = time.get("minute")?.toString()?.padStart(2, "0");
    setEndTime(`${endTimeHours}:${endTimeMinutes}:00`);
  }

  function handleDelete(indexDate: number) {
    const copyExcludedHours: IExcludedHours[] = JSON.parse(
      JSON.stringify(excludedHours)
    );
    setExcludedHours(
      copyExcludedHours?.filter(({}, index) => index !== indexDate)
    );
  }
  const handleClose = () => setAdd(false);

  return (
    <>
      <Container>
        <div className="contain_add">
          <h6>Excluir horas</h6>
          {!add ? (
            <button
              title="Agregar configuración"
              className="btnadd"
              onClick={() => setAdd(true)}
            >
              <i className="bi bi-node-plus-fill"></i>
            </button>
          ) : null}
        </div>
        <Modal show={add} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="h6">Horas a excluir</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup>
              <Form.Check
                type="checkbox"
                checked={allDays}
                onChange={(event) => setAllDays(event.target.checked)}
              />
              <Form.Label>Aplicar rango de horas todos los días</Form.Label>
            </InputGroup>
            {!allDays ? (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={esES}
                localeText={{
                  previousMonth: "Anterior mes",
                  nextMonth: "Próximo mes",
                }}
              >
                <label>Seleccionar el día</label>
                <DateCalendar
                  value={date}
                  onChange={(value) => setDate(value)}
                  maxDate={dayjs().add(12, "month")}
                  shouldDisableDate={shouldDisableDate}
                />
              </LocalizationProvider>
            ) : null}
            {(date && !allDays) || allDays ? (
              <Row>
                <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Label>Hora de inicio</Form.Label>
                  <div className="input-wrapper">
                    <Form.Control
                      type="time"
                      value={startTime}
                      onChange={(event) =>
                        handleChangeStartTime(event.target.value)
                      }
                    />
                    <button
                      title="Limpiar hora"
                      className="btnclean"
                      onClick={() => setStartTime("")}
                    >
                      <i className="bi bi-x-circle"></i>
                    </button>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Label>Hora de fin</Form.Label>
                  <div className="input-wrapper">
                    <Form.Control
                      type="time"
                      value={endTime}
                      onChange={(event) =>
                        handleChangeEndTime(event.target.value)
                      }
                    />
                    <button
                      title="Limpiar hora"
                      className="btnclean"
                      onClick={() => setEndTime("")}
                    >
                      <i className="bi bi-x-circle"></i>
                    </button>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleAdd}>Confirmar</Button>
          </Modal.Footer>
        </Modal>

        <Table responsive bordered className="tableschedule">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Desde</th>
              <th>Hasta</th>
              <th>Todos los días</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {excludedHours?.map(
              ({ date, startTime, endTime, allDays }, indexDate) => (
                <tr key={indexDate}>
                  <td>{date}</td>
                  <td>{startTime}</td>
                  <td>{endTime}</td>
                  <td>{allDays ? "Si" : "No"}</td>
                  <td>
                    <button
                      className="btndelete"
                      title="Eliminar configuración"
                      disabled={add}
                      onClick={() => handleDelete(indexDate)}
                    >
                      <i className="bi bi-trash3"></i>
                    </button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </Container>

      {message?.show ? (
        <Message
          show={message?.show}
          message={message?.message}
          hide={() => setMessage((prev) => ({ ...prev, show: false }))}
        />
      ) : null}
    </>
  );
}
