import { useState } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import { IMessage, Message } from "../../components/message";
import HttpService from "../../services/http";
import { useTranslation } from "react-i18next";

export default function ChangePasswordPage(): JSX.Element {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [verifyNewPassword, setVerifyNewPassword] = useState<string>("");

  const [sending, setSending] = useState<boolean>(false);
  const [warning, setWarning] = useState<IMessage>({ show: false });
  const { i18n, t } = useTranslation()
  function send() {
    setSending(true);

    let message = "";

    if (currentPassword?.trim() === "")
      message = message.concat(`${t('currentPasswordRequired')}`);
    if (newPassword?.trim() === "")
      message = message.concat(`${t('currentPasswordRequired')}`);
    if (verifyNewPassword?.trim() === "")
      message = message.concat(
       `${t('confirmNewPasswordRequired')}`
      );
    if (newPassword !== verifyNewPassword)
      message = message.concat(`${t('newPasswordNotMatch')}`);

    if (message !== "") {
      setWarning({ show: true, message });
      setSending(false);
    } else {
      HttpService.post("user/changePassword", {
        oldPassword: currentPassword?.trim(),
        newPassword,
      })
        .then((result) => {
          const { status, message } = result;
          if (status)
            setWarning({
              show: true,
              message: "Se ha cambiado la contraseña.",
              hide: () => {
                window.location.href = "/";
              },
            });
          else setWarning({ show: true, message });
          setSending(false);
        })
        .catch(() => setSending(false));
    }
  }

  return (
    <Container>
      <h2>{t('changePassword')}</h2>
      <Form.Group>
        <Form.Label>{t('currentPassword')}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t('currentPassword')}
          disabled={sending}
          value={currentPassword}
          onChange={(event) => setCurrentPassword(event.target.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('newPassword')}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t('newPassword')}
          disabled={sending}
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('confirmNewPassword')}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t('confirmNewPassword')}
          disabled={sending}
          value={verifyNewPassword}
          onChange={(event) => setVerifyNewPassword(event.target.value)}
        />
      </Form.Group>
      <Col
        xs={{ offset: 5, span: 2 }}
        sm={{ offset: 5, span: 2 }}
        md={{ offset: 5, span: 2 }}
        lg={{ offset: 5, span: 2 }}
        xl={{ offset: 5, span: 2 }}
        xxl={{ offset: 5, span: 2 }}
      >
        <Button onClick={send} disabled={sending}>
          {t('changePassword')}
        </Button>
      </Col>

      {warning?.show ? (
        <Message
          show={warning?.show}
          message={warning?.message}
          hide={() => {
            if (warning?.hide) warning?.hide();
            else setWarning({ show: false });
          }}
        />
      ) : null}
    </Container>
  );
}
