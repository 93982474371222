import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { IWarning, Message } from "../../components/message";
import { IPatient } from "../../interfaces/patient/patient.interface";
import { IUser } from "../../interfaces/user/user.interface";
import HttpService from "../../services/http";
import Util from "../../utils/util";
import { useTranslation } from "react-i18next";

const Register = (): JSX.Element => {
  const [user, setUser] = useState<IUser>({ email: "", password: "" });
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const { state: stateLocation } = useLocation();
  const { i18n, t } = useTranslation()
  const navigate = useNavigate();
  const [patient, setPatient] = useState<IPatient>({
    id: -1,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender_id: null,
    document_type_id: null,
    document: "",
    user_id: null,
  });

  const [sending, setSending] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);

  const [warning, setWarning] = useState<IWarning>({
    show: false,
    message: "",
  });

  useEffect(() => {
    setPatient((prev) => ({ ...prev, email: user?.email }));
  }, [user?.email]);

  function createUser() {
    setSending(true);
    setRedirect(false);

    const isValidData = validateData();

    if (isValidData) {
      HttpService.post("patients/register", { user, patient })
        .then((result) => {
          const { status, message } = result;
          if (status) {
            setWarning({
              show: true,
              message:
                `${t('registrationSuccess')}`,
            });
            setSending(false);
            setRedirect(true);
          } else {
            setWarning({
              show: true,
              message,
            });
            setSending(false);
          }
        })
        .catch((e) => {
          setWarning({
            show: true,
            message: e.message,
          });
          setSending(false);
        });
    } else setSending(false);
  }

  const validateData = () => {
    const isValidEmail = Util.isValidEmail(user?.email);
    if (
      patient?.first_name?.trim() === "" ||
      patient?.last_name?.trim() === "" ||
      patient?.last_name?.trim() === ""
    ) {
      setWarning({
        show: true,
        message: `${t('requiredFields')}`,
      });
      return false;
    }

    if (!isValidEmail) {
      setWarning({
        show: true,
        message: `${t('invalidEmail')}`,
      });
      return false;
    }

    if (user?.password === "" || passwordConfirm === "") {
      setWarning({
        show: true,
        message: `${t('enterPassword')}`,
      });
      return false;
    }

    if (user?.password !== passwordConfirm) {
      setWarning({
        show: true,
        message: `${t('passwordNotMatch')}`,
      });
      return false;
    }

    return true;
  };

  return (
    <>
      <Container>
        <h4 className="d-flex justify-content-center mt-2 ">
          {t('patientRegistration')}
        </h4>
        <Col
          xs={{ offset: 1, span: 10 }}
          sm={{ offset: 1, span: 10 }}
          md={{ offset: 1, span: 10 }}
          lg={{ offset: 1, span: 10 }}
          xl={{ offset: 1, span: 10 }}
          xxl={{ offset: 1, span: 10 }}
        >
          <Form.Text muted className="registre-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-person-fill"
              viewBox="2 2 17 17"
            >
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
            </svg>
            {t('personalInfo')}
          </Form.Text>
          <Row className="d-flex">
            <Form.Group as={Col} md={6}>
              <Form.Label>{t('firstName')} *</Form.Label>
              <Form.Control
                type="text"
                value={patient?.first_name}
                onChange={(event) =>
                  setPatient((prev) => ({
                    ...prev,
                    first_name: event.target.value,
                  }))
                }
                disabled={sending}
              />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>{t('lastName')} *</Form.Label>
              <Form.Control
                type="text"
                value={patient?.last_name}
                onChange={(event) =>
                  setPatient((prev) => ({
                    ...prev,
                    last_name: event.target.value,
                  }))
                }
                disabled={sending}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>{t('documentType')} *</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={patient?.document_type_id || undefined}
                onChange={(event) =>
                  setPatient((prev) => ({
                    ...prev,
                    document_type_id: parseInt(event.target.value),
                  }))
                }
                disabled={sending}
              >
                <option>{t('select')}</option>
                <option value="17">C.C.</option>
                <option value="18">C.E.</option>
                <option value="19">R.C.</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>{t('identification')} *</Form.Label>
              <Form.Control
                type="text"
                value={patient?.document}
                onChange={(event) =>
                  setPatient((prev) => ({
                    ...prev,
                    document: event.target.value,
                  }))
                }
                disabled={sending}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>{t('gender')} *</Form.Label>
              <br />
              <Form.Check
                label={t('male')}
                name="gender"
                type="radio"
                checked={patient?.gender_id === 6}
                value={6}
                onChange={(event) =>
                  setPatient((prev) => ({
                    ...prev,
                    gender_id: parseInt(event.target.value),
                  }))
                }
                disabled={sending}
              />
              <Form.Check
                label={t('female')}
                name="gender"
                type="radio"
                checked={patient?.gender_id === 7}
                value={7}
                onChange={(event) =>
                  setPatient((prev) => ({
                    ...prev,
                    gender_id: parseInt(event.target.value),
                  }))
                }
                disabled={sending}
              />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>{t('phoneMobile')} *</Form.Label>
              <Form.Control
                type="tel"
                value={patient?.phone}
                onChange={(event) =>
                  setPatient((prev) => ({
                    ...prev,
                    phone: event.target.value,
                  }))
                }
                disabled={sending}
              />
            </Form.Group>
          </Row>

          <Form.Text muted className="registre-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-box-arrow-in-right"
              viewBox="2 2 17 17"
            >
              <path
                fill-rule="evenodd"
                d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
              />
              <path
                fill-rule="evenodd"
                d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
              />
            </svg>
            {t('loginInfo')}
          </Form.Text>
          <br />
          <Form.Group as={Col} md={6}>
            <Form.Label>{t('email')} *</Form.Label>
            <Form.Control
              type="email"
              value={user?.email}
              onChange={(event) =>
                setUser((prev) => ({ ...prev, email: event.target.value }))
              }
              disabled={sending}
            />
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>{t('password')} *</Form.Label>
            <Form.Control
              type="password"
              value={user?.password}
              onChange={(event) =>
                setUser((prev) => ({ ...prev, password: event.target.value }))
              }
              disabled={sending}
            />
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>{t('confirmPassword')} *</Form.Label>
            <Form.Control
              type="password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              disabled={sending}
            />
          </Form.Group>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className="mt-2 text-center"
        >
          <b>
            <Form.Label>
              Al hacer click en Registrarme, estarás aceptando nuestra{" "}
              <a
                href={process.env.REACT_APP_POLITICA_PRIVACIDAD}
                target="_black"
              >
                Política de Privacidad
              </a>{" "}
              y{" "}
              <a
                target="_black"
                href={process.env.REACT_APP_TERMINOS_Y_CONDICIONES}
              >
                Términos y Condiciones
              </a>
              .
            </Form.Label>
          </b>
        </Col>
        <Col
          xs={{ offset: 10, span: 2 }}
          sm={{ offset: 10, span: 2 }}
          md={{ offset: 10, span: 2 }}
          lg={{ offset: 10, span: 2 }}
          xl={{ offset: 10, span: 2 }}
          xxl={{ offset: 10, span: 2 }}
          className="my-2"
        >
          <Button
            className="d-flex justify-content-center align-items-center"
            disabled={sending}
            onClick={createUser}
          >
            <i className="bi bi-save2" />
            {t('register')}
          </Button>
        </Col>

        {warning?.show ? (
          <Message
            show={warning?.show}
            message={warning?.message}
            hide={() => {
              setWarning({ show: false });
              if (!redirect) return;
              if (stateLocation?.redirect)
                navigate("../login", stateLocation?.redirect?.props);
              else navigate("../login");
            }}
          />
        ) : null}
      </Container>
    </>
  );
};

export default Register;
