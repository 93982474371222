import React from 'react';

interface LoaderProps {
  status: Boolean;
}

function Loader({ status }: LoaderProps) {
  return (
    <>
     {status && (
        <div id="Loadcontenedor">
          <div className="cargando">
            <div className="rueda"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default Loader;
