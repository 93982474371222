import { useEffect, useState } from "react";
import { Button, Carousel, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IUserLoged } from "../../interfaces/user/user.interface";
import { useAuth } from "../../middlewares/auth";
import { ISpecialist } from "../../interfaces/specialist/specialist.interface";
import HttpService from "../../services/http";
import css from "./carousel-top.module.css";
import { useTranslation } from "react-i18next";

interface ItemProps {
  chunk: ISpecialist[];
}

function Item({ chunk }: ItemProps): JSX.Element {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  return (
    <>
      {chunk.map((specialist, index) => (
        <div
          className={css.card}
          key={index}
          onClick={() =>
            navigate(`/specialist/${specialist.id}`, {
              preventScrollReset: true,
            })
          }
        >
          <div className={css.image}>
            <img
              className="image"
              src={`${specialist.photo || ""}`}
              alt={`${specialist.name} ${specialist.surname}`}
            />
          </div>
          <div className={css.container}>
            <div
              className={css.title}
            >{`${specialist.name} ${specialist.surname}`}</div>
            <div>{`${specialist.procedure || ""}`}</div>
            <div>{`${specialist.short_description}`}</div>
            <div
              className={css.item_ubicacion}
            >{`${specialist.office_address}`}</div>
          </div>
          <div className={css.btn}>
            <Button
              className="button-secundary"
              onClick={() =>
                navigate(`/specialist/${specialist.id}`, {
                  preventScrollReset: true,
                })
              }
            >
              {t("asignamentAppointment")}
            </Button>
          </div>
        </div>
      ))}
    </>
  );
}

function CarouselTop(): JSX.Element {
  const { state } = useAuth();
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [topRating, setTopRating] = useState<ISpecialist[][]>([]);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    setLoading(true);
    HttpService.post("specialists/getTop")
      .then((result) => {
        const chunkSize = 5;
        const specialists = [];
        const data = result?.data || [];
        for (let iterator = 0; iterator < data.length; iterator += chunkSize)
          specialists.push(data.slice(iterator, iterator + chunkSize));
        setTopRating(specialists);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setTopRating([]);
      });
  }, [state?.isLoged]);

  if (loading)
    return (
      <>
        <Container>
          <h2>{t("Cargando top especialistas")}....</h2>
        </Container>
      </>
    );

  return (
    <div className={css.topCarousel}>
      <Carousel
        activeIndex={index}
        onSelect={(selectedIndex) => setIndex(selectedIndex)}
        className={css.content}
      >
        {topRating?.map((chunk, index) => (
          <Carousel.Item key={index}>
            <Item chunk={chunk} />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default CarouselTop;
