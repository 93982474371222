import { ReactElement, useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../../../middlewares/auth";
import logo from "../resources/img/logo.svg";
import logoIcon from "../../../assets/logoImed.webp";

import css from "./layou.module.css";

import "../../../styles/sidebar.css";
import { useTranslation } from "react-i18next";

export default function SideNav(): JSX.Element {
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const { i18n, t } = useTranslation()
  const { state } = useAuth();

  return (
    <Box>
      <div >
      <Sidebar collapsed={isCollapsed} className="inmed-sidebar">
        <Menu>
          {isCollapsed && (
            <div className={css.containerlogoicon}>
              <img
                src={logoIcon}
                alt="logo_inmed"
                className={css.logoIconSideNav}
              />
            </div>
          )}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={
              isCollapsed ? (
                <div>
                  <i className="bi bi-list" title="expandir menu" />
                </div>
              ) : undefined
            }
          >
            {!isCollapsed && (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <img
                    src={logo}
                    alt="logo_inmed"
                    className={css.logoSideNav}
                  />
                  <IconButton
                    className=""
                    onClick={() => setIsCollapsed(!isCollapsed)}
                  >
                    <i className="bi bi-list" title="Contraer menu" />
                  </IconButton>
                </Box>
              </>
            )}
          </MenuItem>

          <Box>
            <ul className="listMenu">
              {!state?.patient ? (
                <li>
                  <div>
                    <Link to="./home">
                      {" "}
                      <i className="bi bi-house " />{" "}
                      {!isCollapsed && <>{t('home')}</>}
                    </Link>
                  </div>
                </li>
              ) : (
                <li>
                  <div>
                    <Link to="../">
                      {" "}
                      <i className="bi bi-globe" />
                      {!isCollapsed && <>{t('home')}</>}
                    </Link>
                  </div>
                </li>
              )}
              <hr />

              {state?.isLoged && (state?.secretary || state?.specialist) && (
                <li>
                  <div>
                    <Link to="./appointment">
                      <i className="bi bi-calendar-week" />
                      {!isCollapsed && <>{t('agenda')}</>}
                    </Link>
                  </div>
                </li>
              )}
              {state?.isLoged && (state?.secretary || state?.specialist) ? (
                <li>
                  <div>
                    <Link to="./patients">  <i className="bi bi-people-fill" />
                      {!isCollapsed && <> {t('patients')}</>}</Link>


                  </div>
                </li>
              ) : null}
              {state?.isLoged && (state?.secretary || state?.specialist) ? (
                <>
                  <li>
                    <div>
                      <Link to="./historical" style={{ display: 'flex' }}>
                        {" "}
                        <img src={`/resources/images/expediente.png`}
                          alt={`historical icon`}
                          style={{
                            width: 18, height: 18, marginRight: "1em", marginLeft: "0.5em"
                          }} />
                        {!isCollapsed && <>{t('history')}</>}
                      </Link>
                    </div>
                  </li>
                </>
              ) : (
                <li>
                  <div>
                    <Link to="./mydate">
                      {" "}
                      <i className="bi bi-justify" />
                      {!isCollapsed && <>{t('myAppointments')}</>}
                    </Link>
                  </div>
                </li>
              )}
              {state?.isLoged && (state?.secretary || state?.specialist) ? (
                <>
                  {/* <li>
                <div>
                  <i className="bi bi-person-fill" />
                  <Link to="./profile">Perfil</Link>
                </div>
              </li> */}
                  <li>
                    <div>
                      <Link to="./settings">
                        {" "}
                        <i className="bi bi-clock-fill" />
                        {!isCollapsed && <>{t('settings')}</>}{" "}
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div>
                      <Link to="../">
                        {" "}
                        <i className="bi bi-globe" />
                        {!isCollapsed && <>{t('website')}</>}{" "}
                      </Link>
                    </div>
                  </li>
                </>
              ) : (
                <li>
                  <div>
                    <Link to="./profile">
                      {" "}
                      <i className="bi bi-person-fill" />
                      {!isCollapsed && <>{('myAccount')}</>}
                    </Link>
                  </div>
                </li>
              )}

              {state?.isLoged && state?.admin && (
                <>
                  <li>
                    <div>{t('specialists')}</div>
                  </li>
                  <li>
                    <div>
                      <Link to="./procedures">
                        {!isCollapsed && <>{t('procedures')}</>}
                      </Link>
                    </div>
                  </li>
                </>
              )}
              <li>
                <div>
                  <Link to="#" rel="noreferrer noopener">
                    {!isCollapsed && <> {t('contactUs')}</>}
                  </Link>
                </div>
              </li>
            </ul>
          </Box>
        </Menu>
      </Sidebar>
      </div>
    </Box>
  );
}
