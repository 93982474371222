import React from "react";
import { Col, Row } from "react-bootstrap";
import { TwitterTimelineEmbed } from "react-twitter-embed";

function TwitterFeed() {
  return (
    <Row>
      <Col md={12} className="mt-4">
        <TwitterTimelineEmbed
        
          sourceType="profile"
          screenName="inmed_co"
          options={{ height: 520 }}
          placeholder="Cargando noticias"
          lang="es"

         
        />
      </Col>
    </Row>
  );
}

export default TwitterFeed;
