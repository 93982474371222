// CalificacionesComponent.jsx

import React, { useEffect, useState } from 'react';
import HttpService from '../../services/http';
import {
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    TextField,
    Button,
    CircularProgress,
    Box,
    Grid,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import logo from '../../assets/images/logo/logoinmed.png';
// Importar los iconos de emoticones
import veryEasyIcon from '../../assets/icons/questions/5.png';
import easyIcon from '../../assets/icons/questions/4.png';
import normalIcon from '../../assets/icons/questions/3.png';
import difficultIcon from '../../assets/icons/questions/2.png';
import veryDifficultIcon from '../../assets/icons/questions/1.png';
import { Confirmation, IWarning, Message } from '../../components/message';
import { useParams, useSearchParams } from 'react-router-dom';

const difficultyIcons = [
    veryDifficultIcon, difficultIcon, normalIcon, easyIcon, veryEasyIcon,




];
const Questions = () => {
    const  [datarequest]= useSearchParams();
    const token=datarequest.get("token")
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [ratings, setRatings] = useState<{ [key: string]: number | null }>({});
    const [comments, setComments] = useState<{ [key: string]: string }>({});
    const [anonymous, setAnonymous] = useState<{ [key: string]: boolean }>({});
    const [selectedOption, setSelectedOption] = useState<number | null>(null);
    const [statusConfirm, setStatusConfirm] = useState(false);
    const [warning, setWarning] = useState<IWarning>({
        show: false,
        message: "",
    });
    const [check, setCheck] = useState(false)

    useEffect(() => {
        getData();
    }, []);

    function getData() {
      
        setLoading(true);
        HttpService.get(`questions?token=${token}`)
            .then((result) => {
                if (result.status) {
                    const initialRatings: { [key: string]: number | null } = {};
                    const initialComments: { [key: string]: string } = {};
                    const initialAnonymous: { [key: string]: boolean } = {};
                    Object.keys(result.data).forEach((groupId) => {
                        const questions = result.data[groupId]?.questions ?? [];
                        questions.forEach((question: any) => {
                            initialRatings[question.id] = null; // Inicialmente ninguna calificación seleccionada
                            initialComments[question.id] = ''; // Inicialmente ningún comentario
                            initialAnonymous[question.id] = false; // Inicialmente no es anónimo
                        });
                    });
                    setRatings(initialRatings);
                    setComments(initialComments);
                    setAnonymous(initialAnonymous);
                    setData(result.data);
                } else {
                    setCheck(true)
                }
            })
            .catch((error) => {
                console.log(error?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleRatingChange(questionId: number, rating: number) {
        const updatedRatings = { ...ratings, [questionId]: rating };
        setRatings(updatedRatings);
    }

    function handleCommentChange(questionId: number, comment: string) {
        const updatedComments = { ...comments, [questionId]: comment };
        setComments(updatedComments);
    }

    function handleAnonymousChange(questionId: number) {
        const updatedAnonymous = { ...anonymous, [questionId]: !anonymous[questionId] };
        setAnonymous(updatedAnonymous);
    }

    function handleOptionSelect(questionId: number, optionId: number) {
        setSelectedOption(optionId === selectedOption ? null : optionId);
        const updatedRatings = { ...ratings, [questionId]: optionId + 1 };
        setRatings(updatedRatings);
    }

    function handleSubmit() {
       
        const payload = Object.keys(ratings).map((questionId) => ({
            question_id: parseInt(questionId),
            rating: ratings[questionId] ?? 0,
            comment: comments[questionId],
            anonymous: anonymous[questionId],
        }));

        const data = { token: token, data: payload }
        setLoading(true);

        HttpService.post('ratings', data)
            .then((result) => {
                setStatusConfirm(false);

                if (result.status) {
                    setCheck(true)
                } else {
                    setWarning({ show: true, message: result?.message });
                }
                setLoading(false);

            })
            .catch((error) => {
                setStatusConfirm(false)
                setWarning({ show: true, message: "Error comuniquese con servicio al cliente" });
                setLoading(false);


            });

    }


    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>{token &&
            <>
                {warning?.show && (
                    <Message
                        show={warning?.show}
                        message={warning?.message}
                        hide={() => setWarning({ show: false })}
                    />
                )}
                {statusConfirm && (
                    <Confirmation
                        hide={() => setStatusConfirm(false)}
                        show={statusConfirm}
                        message="¿Estás seguro de que deseas enviar el formulario?"
                        btn={{
                            accept: {
                                text: "Si",
                                event: () => {
                                    handleSubmit();
                                },
                            },
                            cancel: {
                                text: "No",
                                event: () => {
                                    setStatusConfirm(false);
                                },
                            },
                        }}
                    />
                )}
                {!check ?
                    <div className="container question-container">
                        <Box className="logo-container">
                            <img src={logo} alt="Inmed" className="logo-image" />
                            <Typography variant="h5" className="main-text">
                                ¡Tu opinión es importante para nosotros!
                            </Typography>
                        </Box>
                        {data &&
                            Object.keys(data).map((groupId) => {
                                const group = data[groupId]?.group ?? null;
                                const questions = data[groupId]?.questions ?? [];

                                return (
                                    <div key={groupId} className="question-group">
                                        {questions.map((question: any) => (
                                            <Card key={question.id} className="question-card">
                                                <CardContent>
                                                    <Typography className="question-text">
                                                        {question.content}
                                                    </Typography>

                                                    {question.questionType?.id === 1 && (
                                                        <>
                                                            <Grid container alignItems="center" justifyContent="center" className="question-options">

                                                                {[...Array(5)].map((_, index) => (
                                                                    <Grid item key={index} className="rating-star mt-2">
                                                                        <Box
                                                                            className="star-icon-container"
                                                                            onClick={() =>
                                                                                handleRatingChange(
                                                                                    question.id,
                                                                                    index + 1
                                                                                )
                                                                            }
                                                                        >
                                                                            {ratings[question.id] &&
                                                                                index < (ratings[question.id] ?? 0) ? (
                                                                                <StarIcon className="star-icon filled" />
                                                                            ) : (
                                                                                <StarBorderIcon className="star-icon" />
                                                                            )}
                                                                        </Box>
                                                                    </Grid>
                                                                ))}
                                                                <br />
                                                            </Grid>
                                                        </>
                                                    )}

                                                    {question.questionType?.id === 2 && (
                                                        <Grid container className="question-options2" alignItems="center" justifyContent="center">

                                                            <List className="options-list mt-2">
                                                                {question.questionType.description
                                                                    .split('\n')
                                                                    .map((option: any, index: any) => (
                                                                        <ListItem
                                                                            key={index}
                                                                            button
                                                                            onClick={() => handleOptionSelect(question.id, index)}
                                                                            selected={index === selectedOption}
                                                                            className={`option-item ${index === selectedOption ? 'selected' : ''}`}
                                                                        >
                                                                            <ListItemIcon className='item-icon'>
                                                                                <img  style={{width:"100%"}}  src={difficultyIcons[index]} alt={`Difficulty ${index}`} className="difficulty-icon" />
                                                                            </ListItemIcon>
                                                                            <ListItemText
                                                                                primary={option}
                                                                                className="option-text"
                                                                            />
                                                                        </ListItem>
                                                                    ))}
                                                            </List>
                                                        </Grid>

                                                    )}

                                                    <TextField
                                                        label="Comentario"
                                                        variant="outlined"
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                        value={comments[question.id]}
                                                        onChange={(e) =>
                                                            handleCommentChange(question.id, e.target.value)
                                                        }
                                                        className="comment-field mt-2"
                                                    />


                                                    <Grid container spacing={0} alignItems="center" className="question-optionsq">
                                                        <Grid item>
                                                            <Checkbox
                                                                checked={anonymous[question.id]}
                                                                onChange={() => handleAnonymousChange(question.id)}
                                                                inputProps={{ 'aria-label': 'Anónimo' }}
                                                                className="anonymous-checkbox"
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body2" className="anonymous-label">
                                                                Comentario Anónimo
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                </CardContent>
                                            </Card>
                                        ))}
                                    </div>
                                );
                            })}
                        <Box mt={4} className="submit-button-container">
                            <Button className="btn btn-primary " style={{ color: "#FFF" }} onClick={() => setStatusConfirm(true)} >
                                Enviar Calificaciones
                            </Button>
                        </Box>
                    </div>
                    : <> <div className="container question-container">
                        <Box className="logo-container">
                            <img src={logo} alt="Inmed" className="logo-image" />
                            <Typography variant="h4" className="main-text">
                                Gracias por contestar la encuesta
                            </Typography>
                        </Box></div></>}
            </>
        }
            {!token &&
                <div className="container question-container">
                    <Box className="logo-container">
                        <img src={logo} alt="Inmed" className="logo-image" />
                        <Typography variant="h5" className="main-text">
                            Pagina no encontrada
                        </Typography>
                    </Box></div>}
        </>
    );

};

export default Questions;
