import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface DataTableProps {
  data: any[]; // Reemplaza 'any' con el tipo de datos de tu conjunto de datos
  columns: GridColDef[];
  height?: string;
  width?: string;
  visibility?: {};
}

const DataTable: React.FC<DataTableProps> = ({ data, columns,height,width,visibility }) => {
  return (
    <div style={{ height: height??"100%", width: width??"100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        pagination
        pageSizeOptions={[5, 10, 25, 50, 100]}
        columnVisibilityModel={visibility??{}}
      />
    </div>
  );
};

export default DataTable;
