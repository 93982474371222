import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export interface IWarning {
  show: boolean;
  message?: string | null | undefined;
  title?: string
}

export interface IMessage extends IWarning {
  hide?: () => void;
}

export interface IConfirmation extends IMessage {
  btn?: {
    accept?: {
      text?: string;
      event?: () => void;
    };
    cancel?: { text?: string; event?: () => void };
  };
  hide: () => void;
}

function Message({ show, message, hide,title="Advertencia" }: IMessage): JSX.Element {
  const { i18n, t } = useTranslation()
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={hide}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button onClick={hide}>{t('Accept')}</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Confirmation({
  show,
  message,
  hide,
  btn,
}: IConfirmation): JSX.Element {
  const { i18n, t } = useTranslation()
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={hide}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('attention')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {btn?.accept && (
          <Button
            onClick={() => {
              if (btn?.accept?.event) btn?.accept?.event();
              else hide();
            }}
          >
            {btn?.accept?.text ? btn?.accept?.text : t("Accept")}
          </Button>
        )}
        {btn?.cancel && (
          <Button
            onClick={() => {
              if (btn?.cancel?.event) btn?.cancel?.event();
              else hide();
            }}
          >
            {btn?.cancel?.text ? btn?.cancel?.text : t("cancel")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export { Message, Confirmation };
