import { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { IMessage, Message } from "../../../../../components/message";
import {
  updateAppointmentsPendingConfirmation,
  useAppointmentsPendingConfirmation,
  useAppointmentsPendingConfirmationId,
} from "../../../../../services/appointment";
import { usePanelPage } from "../../../panelPage";

const PAYMENT_ON_SITE = 0,
  PAYMENT_WITH_TRANSFER = 1;

export function ModalAppointmentPending({
  appointmentId,
  showModal,
  setShowModal,
  setRefetch,
  setMessage,
  onHide,
}: {
  appointmentId: number | null;
  showModal: boolean;
  setShowModal: (hideModal: boolean) => void;
  setRefetch?: () => void;
  setMessage: (message: IMessage) => void;
  onHide?: () => void;
}): JSX.Element {
  const { appoinment } = useAppointmentsPendingConfirmationId(appointmentId);

  const [showAppoinment, setShowAppointment] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [paymentType, setPaymentType] = useState<number>(PAYMENT_ON_SITE);

  const [sending, setSending] = useState<boolean>(false);

  useEffect(() => {
    setComment("");
    setPaymentType(PAYMENT_ON_SITE);
    setShowAppointment(appoinment !== null);
  }, [appoinment]);

  function handleDecline() {
    if (sending) return;
    setSending(true);
    handleUpdate(true);
  }

  function handleConfirm() {
    if (sending) return;
    setSending(true);
    handleUpdate(false);
  }

  function handleUpdate(isDecline: boolean) {
    updateAppointmentsPendingConfirmation({
      appointment_id: appoinment?.appointment_id,
      appointments_status: isDecline ? 30 : 27,
      comment,
      electronic_payment: isDecline
        ? null
        : paymentType === PAYMENT_WITH_TRANSFER,
      payment_on_site: isDecline ? null : paymentType === PAYMENT_ON_SITE,
    }).then(() => {
      setSending(false);
      if (setRefetch) setRefetch();
      setMessage({
        show: true,
        message: isDecline
          ? "La cita ha sido actualizada a estado rechazada"
          : "La cita ha sido cambiado a estado confirmada",
      });
      setShowModal(false);
      if (onHide) onHide();
    });
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      show={showModal && showAppoinment}
      onHide={() => {
        setShowModal(false);
        if (onHide) onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="bi bi-check-square m-2" />
          Confirmación de cita
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Label className="title-form">Tipo Documento:</Form.Label>
            <Form.Label>{appoinment?.name ?? ""}</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="title-form">Nùmero Documento:</Form.Label>
            <Form.Label>{appoinment?.document ?? ""}</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="title-form">Nombres y apellidos:</Form.Label>
            <Form.Label>{`${appoinment?.first_name ?? ""} ${
              appoinment?.last_name ?? ""
            }`}</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="title-form">Teléfono:</Form.Label>
            <Form.Label>{appoinment?.phone}</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="title-form">Correo:</Form.Label>
            <Form.Label>{appoinment?.email}</Form.Label>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Label className="title-form">
              Fecha y hora cita solicitada:
            </Form.Label>
            <Form.Label>
              {appoinment?.start_dateformatted} -
              {appoinment?.start_datetimeFormated}{" "}
            </Form.Label>
          </Col>
        </Row>
        <div style={{ display: "none" }}>
          <Row
            style={{
              height: "4px",
              borderRadius: "10px",
              background: "linear-gradient(90deg, #e14594 0%, #7045af 100%)",
            }}
          />
          <Row>
            <Col>
              <Form.Label>Pago</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup>
                <InputGroup.Radio
                  checked={paymentType === PAYMENT_ON_SITE}
                  disabled={sending}
                  name="payMethod"
                  onChange={() => setPaymentType(PAYMENT_ON_SITE)}
                />
                <InputGroup.Text>Pago en sitio</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col>
              <InputGroup>
                <InputGroup.Radio
                  checked={paymentType === PAYMENT_WITH_TRANSFER}
                  disabled={sending}
                  name="payMethod"
                  onChange={() => setPaymentType(PAYMENT_WITH_TRANSFER)}
                />
                <InputGroup.Text>Pago eléctronico</InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <Form.Label className="title-form">Comentario</Form.Label>
            <Form.Control
              as="textarea"
              disabled={sending}
              onChange={(event) => setComment(event.target.value)}
              style={{ height: "100px", resize: "none" }}
              value={comment}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={sending} onClick={handleDecline}>
          <i className="bi bi-x m-2" />
          Rechazar
        </Button>
        <Button disabled={sending} onClick={handleConfirm}>
          <i className="bi bi-check  m-2" />
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function PendingAppointment(): JSX.Element {
  const { specialistSelected } = usePanelPage();

  const [refetch, setRefetch] = useState<number>(0);
  const [appointmentId, setAppointmentId] = useState<number | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  const tableRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  const {
    appoinments,
    error: errorAppointments,
    loading: loadingAppointments,
  } = useAppointmentsPendingConfirmation({
    specialistSelected,
    refetch,
  });

  useEffect(() => {
    setShowModal(appointmentId != null);
  }, [appointmentId]);

  useEffect(() => {
    if (tableRef.current) {
      const windowHeight = window.innerHeight;
      const tableOffsetTop = tableRef.current.offsetTop;
      const tableHeight = windowHeight - tableOffsetTop - 200; // 50 is an arbitrary value for padding
      setMaxHeight(tableHeight);
    }
  }, []);

  return (
    <>
      <section className="mt-4 colpanel p-3">
        <Row>
          <Col>
            <h6>
              <i className="bi bi-calendar2-check m-2" /> Citas pendientes de
              confirmación
            </h6>
            {errorAppointments && loadingAppointments && (
              <h6>
                Ocurrio un error cargando las citas pendientes del especialista
                seleccionado.
              </h6>
            )}
          </Col>
        </Row>
        {loadingAppointments && !errorAppointments && <h6>Cargando....</h6>}
        <div ref={tableRef} className="table-responsive">
          <Table responsive bordered hover>
            <thead>
              <tr>
                <th>Paciente</th>
                <th>Fecha/hora </th>
                <th>Validar</th>
              </tr>
            </thead>
            <tbody>
              {appoinments?.map((item) => (
                <tr key={item?.appointment_id}>
                  <td>{`${item?.first_name ?? ""} ${
                    item?.last_name ?? ""
                  }`}</td>
                  <td>
                    {item?.start_dateformatted} -{item?.start_datetimeFormated}
                  </td>

                  <td className="justify-content-center">
                    <button
                      title="Validar cita"
                      onClick={() => setAppointmentId(item?.appointment_id)}
                      className="btnicon"
                    >
                      <i className="bi bi-calendar2-check" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </section>

      {showModal && (
        <ModalAppointmentPending
          appointmentId={appointmentId}
          showModal={showModal}
          setShowModal={setShowModal}
          setRefetch={() => setRefetch((prev) => prev + 1)}
          setMessage={setMessage}
        />
      )}

      {message?.show ? (
        <Message
          show={message?.show}
          message={message?.message}
          hide={() => setMessage((prev) => ({ ...prev, show: false }))}
        />
      ) : null}
    </>
  );
}

export default PendingAppointment;
