import { ApiResponse } from "../types/http";
import { Payer } from "../types/payment";
import HttpService from "./http";

function createPatient(payerInfo: Payer): Promise<string | null | undefined> {
  return new Promise((resolve, reject) => {
    HttpService.post("patients/create", {
      document: payerInfo.document,
      document_type_id: payerInfo.documentType,
      email: payerInfo.email,
      first_name: payerInfo.name,
      last_name: payerInfo.lastName,
      phone:payerInfo.phone
    }).then((result) => {
      const { status } = result;
      if (status) resolve(result?.data?.id);
      else reject(result);
    });
  });
}

async function createPayment(
  amount: number,
  id: number | undefined,
  referenceCode: string,
  typepayments:number,
  data:string
) {
  return HttpService.post("payments/create", {
    amount,
    appointment_id: id,
    referenceCode,
    payment_method_id:typepayments,
    data
  });
}

function retrieveUser(userId: string | undefined) {
  return HttpService.get(`patients/getPatient/${userId}`);
}

function updateAppoinment(
  id: number | undefined,
  patientId: string | null | undefined,
  appointments_status: number
) {
  return new Promise((resolve, reject) => {
    HttpService.post("appointments/asignament", {
      id,
      patient_id: patientId,
      appointments_status,
    }).then((result) => {
      const { status } = result;
      if (status) resolve(result?.data);
      else reject(result?.message);
    });
  });
}

function getStatus({
  referenceCode,
  transactionId,
}: {
  referenceCode: string | null;
  transactionId: string | null;
}): Promise<ApiResponse | null> {
  return new Promise((resolve, reject) => {
    HttpService.get(`payments/getStatus/${referenceCode}/${transactionId}`)
      .then((result) => {
        resolve(result);
      })
      .catch(() => reject(null));
  });
}

export {
  createPatient,
  createPayment,
  getStatus,
  retrieveUser,
  updateAppoinment,
};
