import { useEffect, useState } from "react";
import { IProcedure } from "../../procedure/interface";
import HttpService from "../../../services/http";

export function useFetchProcedures() {
  const [procedures, setProcedures] = useState<IProcedure[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    HttpService.postPaginate(`procedures/getListPaginate`, {
      name: "",
      types: "",
      orderBy: "asc",
      page: 0,
    })
      .then((result) => {
        const data = result?.data as IProcedure[];
        setProcedures(data.map(({ id, name }) => ({ id, name })));
      })
      .catch(() => setProcedures([]))
      .finally(() => setLoading(false));
  }, []);

  return { procedures, loading };
}
