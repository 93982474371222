import { createContext, useState } from "react";
import { useAuth } from "../../../../middlewares/auth";
import EditorContainer from "./components/editorContainer";
import SearchContainer from "./components/searchContainer";

export const PanelProcedureContext = createContext<any>({});

function PanelProcedurespage(): JSX.Element {
  const { state } = useAuth();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [idProcedure, setIdProcedure] = useState<string>();

  if (!state?.isLoged) return <h2>Ups... Algo ha salido mal...</h2>;

  return (
    <PanelProcedureContext.Provider
      value={{ editMode, setEditMode, idProcedure, setIdProcedure }}
    >
      <SearchContainer />
      <EditorContainer />
    </PanelProcedureContext.Provider>
  );
}

export default PanelProcedurespage;
