export interface IUserLoged {
  id?: number;
  isLoged: boolean;
  xToken?: string | null | undefined;
  fullName?: string | null | undefined;
  userId?: string;
  admin?: boolean | null | undefined;
  specialist?: boolean | null | undefined;
  secretary?: boolean | null | undefined;
  patient?: boolean | null | undefined;
}

export interface IUser {
  email: string;
  password: string;
}

export enum LOGIN_MODE {
  ADMIN = 1,
  SPECIALIST = 2,
  SECRETARY = 3,
  PATIENT = 4,
}
