import { useEffect, useState } from "react";
import { ISpecialist } from "../../../interfaces/specialist/specialist.interface";
import HttpService from "../../../services/http";

export default function useFetchSpecialistsData(
  search: boolean,
  name: string,
  procedures: string,
  cities: string,
  from: string,
  to: string
) {
  const [specialist, setSpecialist] = useState<ISpecialist[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setSpecialist([]);
    HttpService.postPaginate("specialists/getSpecialists", {
      name,
      procedures,
      cities,
      from,
      to,
    })
      .then((result) => {
        if (result.status) {
          const data = result?.data as ISpecialist[];
          setSpecialist(data);
        }
        setLoading(false);
      })
      .catch(() => {
        setSpecialist([]);
        setLoading(false);
      });
  }, [search]);

  return { specialist, loading };
}
