import { useLanguage } from "./LanguageContext";  // Ajusta la ruta según sea necesario
import { LANGUAGES } from "../constants";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, makeStyles } from "@mui/material";

export default function LanguageSelection(): JSX.Element {
  const { language, setLanguage } = useLanguage();
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedLanguage = event.target.value as string;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <FormControl variant="standard" sx={{ minWidth: 2, border: 'none' }}>
      <Select
        labelId="language-select-label"
        id="language-select"
        value={language}
        onChange={handleChange}
        displayEmpty
        disableUnderline
        sx={{
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            padding: 0,
          },
        }}
      >
        {LANGUAGES.map(({ code }) => (
          <MenuItem key={code} value={code} sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`/resources/images/${code}.png`}
              alt={`${code}flag`}
              style={{ width: 20, height: 20 }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
