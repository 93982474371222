import { useState } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import Util from "../../utils/util";
import HttpService from "../../services/http";
import { IWarning, Message } from "../../components/message";
import { useTranslation } from "react-i18next";

export default function ContactUspage(): JSX.Element {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [warning, setWarning] = useState<IWarning>({
    show: false,
    message: "",
  });
  const { i18n, t } = useTranslation()
  const [sending, setSending] = useState<boolean>(false);

  async function send() {
    setSending(true);
    const isValidEmail = Util.isValidEmail(email);
    const data = JSON.stringify({
      name: name,
      email: email,
      message: message,
    })
    if (isValidEmail) {
      //ToDO send data
        HttpService.post("contact",data).then((result)=>{
          setWarning({
            show: true,
            message: `${t('thankYou')}`,
          });
          setName('');
          setEmail('');
          setMessage('');
          setSending(false);
        }).catch(() => {
          setWarning({
            show: true,
            message: `${t('messageNotSent')}`,
          });
          setSending(false);
        });
    } else{
    setWarning({
      show: true,
      message: `${t('emailEntered')}: ${email} ${t('notValid')}`,
    });
    setSending(false);
  }  
  }

  return (
    <Container>
      <h2>{t('contact')}</h2>
      <Form.Group>
        <Form.Label>{t('firstName')}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t('firstName')}
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={sending}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('email')}</Form.Label>
        <Form.Control
          type="email"
          placeholder={t('email')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={sending}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('message')}</Form.Label>
        <Form.Control
          as="textarea"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={sending}
          style={{ height: "250px", resize: "none" }}
        />
      </Form.Group>
      <Col
        xs={{ offset: 10, span: 2 }}
        sm={{ offset: 10, span: 2 }}
        md={{ offset: 10, span: 2 }}
        lg={{ offset: 10, span: 2 }}
        xl={{ offset: 10, span: 2 }}
        xxl={{ offset: 10, span: 2 }}
      >
        <Button onClick={send} disabled={sending}>
          {t('send')}
        </Button>
      </Col>
      {warning?.show ? (
        <Message
          show={warning?.show}
          message={warning?.message}
          hide={() => setWarning({ show: false })}
        />
      ) : null}
    </Container>
  );
}
