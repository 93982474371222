import { useEffect, useState } from "react";
import { Notification } from "../types/notification";
import HttpService from "./http";

function useFetchNotifications() {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    getNotifications();
    const interval = setInterval(() => getNotifications(), 30000);
    return () => clearInterval(interval);
  }, []);

  function getNotifications() {
    HttpService.get("notification")
      .then((result) => setNotifications(result?.data as Notification[]))
      .catch(() => setNotifications([]));
  }

  return { notifications };
}

function isNotification(id: number) {
  return HttpService.get(`notification/${id}`);
}

export { useFetchNotifications, isNotification };
