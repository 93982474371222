import { ChangeEvent, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import { ISpecialist } from "../../../../interfaces/specialist/specialist.interface";
import { useAuth } from "../../../../middlewares/auth";

export default function EditSpecialist(): JSX.Element {
  const { state } = useAuth();

  const [editedSpecialist, setEditedSpecialist] = useState<ISpecialist | null>(
    null
  );

  const handleInputChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    setEditedSpecialist((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      // Perform save logic here
    } catch (error) {
      console.error("Error updating specialist:", error);
      // Error handling
    }
  };

  return (
    <Container>
      <Form>
        <Row>
          <Col md={6}>
            <Form.Group controlId="short_description">
              <Form.Label>Descripción corta (máximo 60 caracteres):</Form.Label>
              <Form.Control
                as="textarea"
                name="short_description"
                maxLength={60}
                onChange={handleInputChange}
                value={editedSpecialist?.short_description ?? ""}
              />
            </Form.Group>

            <Form.Group controlId="office_address">
              <Form.Label>Dirección:</Form.Label>
              <Form.Control
                type="text"
                name="office_address"
                onChange={handleInputChange}
                value={editedSpecialist?.office_address ?? ""}
              />
            </Form.Group>

            <Form.Group controlId="city_id">
              <Form.Label>Ciudad:</Form.Label>
              <Form.Control
                as="select"
                name="city_id"
                onChange={handleInputChange}
                value={editedSpecialist?.city_id ?? ""}
              >
                <option value="city1">Ciudad 1</option>
                <option value="city2">Ciudad 2</option>
                {/* Add more city options */}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="gender">
              <Form.Label>Sexo:</Form.Label>
              <Form.Control
                as="select"
                name="gender"
                onChange={handleInputChange}
                value={editedSpecialist?.gender ?? ""}
              >
                <option value="male">Masculino</option>
                <option value="female">Femenino</option>
                {/* Add more gender options */}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="phones">
              <Form.Label>Teléfonos:</Form.Label>
              <Form.Control
                type="text"
                name="phones"
                onChange={handleInputChange}
                value={editedSpecialist?.phones ?? ""}
              />
            </Form.Group>

            <Form.Group controlId="photo">
              <Form.Label>Foto:</Form.Label>
              <Form.Control type="file" name="photo" accept="image/*" />
              {editedSpecialist?.photo && (
                <img src={editedSpecialist.photo} alt="Especialista" />
              )}
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="about" style={{ height: "200px" }}>
          <Form.Label>Acerca de mí:</Form.Label>
          <ReactQuill
            value={editedSpecialist?.about ?? ""}
            onChange={(value) =>
              setEditedSpecialist({ ...editedSpecialist, about: value })
            }
            style={{ height: "100px" }} // Adjust the height value as needed
          />
        </Form.Group>

        <Button variant="primary" onClick={handleSave} className="m-3">
          Guardar Cambios
        </Button>
      </Form>
    </Container>
  );
}
