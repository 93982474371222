import { useContext, useEffect, useState } from "react";
import { Form, Pagination, Row, Table } from "react-bootstrap";
import HttpService from "../../../../../services/http";
import { Meta } from "../../../../../types/http";
import { IProcedure } from "../../../../procedure/interface";
import { PanelProcedureContext } from "../panelProcedures";

function useFetchTable(name: string, filterType: string, page: number) {
  const [procedures, setProcedures] = useState<IProcedure[]>([]);
  const [meta, setMeta] = useState<Meta>({
    current_page: 1,
    first_page: 1,
    first_page_url: "/?page=1",
    last_page: 1,
    last_page_url: "/?page=1",
    next_page_url: "/?page=1",
    per_page: 0,
    previous_page_url: "/?page=1",
    total: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let sending = true;
    if (sending) {
      setLoading(true);
      HttpService.postPaginate(`procedures/getProcedures`, {
        name,
        types: filterType,
        orderBy: "asc",
        page,
        limit: 5,
      })
        .then((result) => {
          const info = result?.data;
          const { data, meta } = info;
          setProcedures(data);
          setMeta(meta);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setProcedures([]);
          setMeta({
            current_page: 1,
            first_page: 1,
            first_page_url: "/?page=1",
            last_page: 1,
            last_page_url: "/?page=1",
            next_page_url: "/?page=1",
            per_page: 0,
            previous_page_url: "/?page=1",
            total: 0,
          });
        });
    }
    return () => {
      sending = false;
    };
  }, [name, filterType, page]);
  return { procedures, meta, loading };
}

interface TableInmedProps {
  name: string;
  filterType: string;
}

function TableInmed({ name, filterType }: TableInmedProps): JSX.Element {
  const {
    setEditMode,
    setIdProcedure,
  }: {
    setEditMode: (editMode: boolean) => void;
    setIdProcedure: (idProcedure: string | null | undefined) => void;
  } = useContext(PanelProcedureContext);

  const [page, setPage] = useState<number>(1);
  const { procedures, meta, loading } = useFetchTable(name, filterType, page);

  useEffect(() => setPage(1), [name, filterType]);

  function FooterPagination(props: Meta): JSX.Element {
    let lastPage = props.last_page <= 5 ? props.last_page : 5;
    let firstPage = 1;

    if (props.current_page > 3) {
      lastPage = props.current_page + 2;
      if (lastPage > props.last_page) {
        const difference = lastPage - props.last_page;
        firstPage = props.current_page - 2 - difference;
        lastPage = props.last_page;
      } else firstPage = props.current_page - 2;
    }

    const itemPages = [];
    firstPage = firstPage === 0 ? 1 : firstPage;

    if (firstPage > props.first_page)
      itemPages.push(<Pagination.Ellipsis disabled />);

    for (let iterator = firstPage; iterator <= lastPage; iterator++) {
      itemPages.push(
        <Pagination.Item
          key={`${new Date().getTime()}_${iterator}`}
          active={props.current_page === iterator}
          onClick={() => setPage(iterator)}
        >
          {iterator}
        </Pagination.Item>
      );
    }

    if (lastPage < props.last_page)
      itemPages.push(<Pagination.Ellipsis disabled />);

    return (
      <Row style={{ flexWrap: "nowrap" }}>
        <Pagination style={{ width: "50%" }}>
          <Pagination.First onClick={() => setPage(1)} />
          <Pagination.Prev onClick={() => setPage(page > 1 ? page - 1 : 1)} />

          {itemPages.length > 0 && itemPages}

          <Pagination.Next
            onClick={() =>
              setPage(page < props.last_page ? page + 1 : props.last_page)
            }
          />
          <Pagination.Last onClick={() => setPage(props.last_page)} />
        </Pagination>
        <div style={{ width: "50%" }}>{`${
          (meta?.current_page - 1) * meta?.per_page + 1
        } - ${meta?.current_page * meta?.per_page} de ${meta?.total} elemento${
          meta?.total > 1 ? `s` : ``
        }`}</div>
      </Row>
    );
  }

  return (
    <>
      <Table responsive bordered striped>
        <thead>
          <tr>
            <th>Activo</th>
            <th>Nombre</th>
            <th>Tipo</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody>
          {procedures.map(({ id, name, procedureType }) => (
            <tr key={id}>
              <td>
                <Form.Check type="switch" disabled />
              </td>
              <td>{name}</td>
              <td>{procedureType?.name}</td>
              <td>
                <button
                  onClick={() => {
                    setIdProcedure(id);
                    setEditMode(true);
                  }}
                >
                  E
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {procedures.length > 0 && <FooterPagination {...meta} />}
    </>
  );
}

export default TableInmed;
