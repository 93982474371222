import { useEffect, useState } from "react";
import { IProcedure } from "../../procedure/interface";
import HttpService from "../../../services/http";

export function useFetchProcedure() {
  const [procedures, setProcedures] = useState<IProcedure[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let sending = true;
    if (sending) {
      setLoading(true);
      setProcedures([]);
      HttpService.post("procedures/getListPaginate", {
        name: "",
        types: "",
        orderBy: "asc",
        page: 0,
      })
        .then((result) => {
          const data = result?.data as IProcedure[];
          setProcedures(data.map(({ id, name }) => ({ id, name })));
        })
        .finally(() => setLoading(false));
    }
    return () => {
      sending = false;
    };
  }, []);

  return { procedures, loading };
}
