import { createContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { IMessage, Message } from "../../../../../../components/message";
import { useAuth } from "../../../../../../middlewares/auth";

import useFetchSchedule from "./hooks/useFetchSchedule";

import ExcludedDays from "./components/excludedDays/excludedDays";
import ExcludedHours from "./components/excludedHours/excludedHours";
import WorkingDays from "./components/workingDays/workingDays";

import dayjs from "dayjs";
import "dayjs/locale/es";
import isBetween from "dayjs/plugin/isBetween";
import HttpService from "../../../../../../services/http";
import { usePanelPage } from "../../../../panelPage";
dayjs.extend(isBetween);
dayjs.locale("es");

interface ScheduleProps {
  hidden: boolean;
}

export const DAYS_OF_WEEK = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

export const ScheduleContext = createContext<any>({});

export function checkTimeInRange(
  time: string,
  startTime: string,
  endTime: string
) {
  const checkTime = dayjs(time, "HH:mm");
  const checkStart = dayjs(startTime, "HH:mm");
  const checkEnd = dayjs(endTime, "HH:mm");

  return (
    checkTime.isSame(checkStart) ||
    checkTime.isSame(checkEnd) ||
    (checkTime.isAfter(checkStart) && checkTime.isBefore(checkEnd))
  );
}

export function checkDateInRange(
  date: string,
  startDate: string,
  endDate: string
) {
  const checkDate = dayjs(date);
  const checkStart = dayjs(startDate);
  const checkEnd = dayjs(endDate);

  return (
    checkDate.isSame(checkStart) ||
    checkDate.isSame(checkEnd) ||
    (checkDate.isAfter(checkStart) && checkDate.isBefore(checkEnd))
  );
}

export default function Schedule({ hidden }: ScheduleProps): JSX.Element {
  const { state } = useAuth();

  const { specialistSelected } = usePanelPage();

  const [idSpecialist, setIdSpecialist] = useState<number | null | undefined>(
    state?.specialist ? state?.id : state?.secretary ? specialistSelected : null
  );

  useEffect(
    () =>
      setIdSpecialist(
        state?.specialist
          ? state?.id
          : state?.secretary
          ? specialistSelected
          : null
      ),
    [state?.specialist, state?.secretary, specialistSelected]
  );

  const [refreshSchedule, setRefreshSchedule] = useState<boolean>(false);

  const {
    workingDays,
    setWorkingDays,
    excludedDays,
    setExcludedDays,
    excludedHours,
    setExcludedHours,
    loading,
    setLoading,
  } = useFetchSchedule(idSpecialist, refreshSchedule);

  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  function handleSave() {
    if (
      workingDays.some(
        (workingDay) =>
          workingDay?.startTime === "" && workingDay?.endTime !== ""
      )
    )
      setMessage({
        show: true,
        message:
          "Revisa el horario de trabajo ya que falta ajustar la hora de inicio.",
      });
    else if (
      workingDays.some(
        (workingDay) =>
          workingDay?.startTime !== "" && workingDay?.endTime === ""
      )
    )
      setMessage({
        show: true,
        message:
          "Revisa el horario de trabajo ya que falta ajustar la hora de fin.",
      });
    else if (
      workingDays?.some(({ startTime, endTime }) => {
        const dayjsStartTime = dayjs(startTime, "HH:mm"),
          dayjsEndTime = dayjs(endTime, "HH:mm");
        return dayjsStartTime.isAfter(dayjsEndTime);
      })
    )
      setMessage({
        show: true,
        message: "La hora inicial es mayor a la hora final.",
      });
    else {
      setLoading(true);
      HttpService.post("calendars", {
        workingDays,
        excludedDays,
        excludedHours,
        specialistId: idSpecialist,
      }).then((result) => {
        const { status } = result;
        setLoading(false);
        if (status) {
          setMessage({ show: true, message: "Guardado." });
          setRefreshSchedule((prev) => !prev);
        } else setMessage({ show: true, message: "Error." });
      });
    }
  }

  return (
    <ScheduleContext.Provider
      value={{
        workingDays,
        setWorkingDays,
        excludedDays,
        setExcludedDays,
        excludedHours,
        setExcludedHours,
      }}
    >
      <Container hidden={hidden}>
        <Row>
          <Col
            xs={{ offset: 9, span: 3 }}
            sm={{ offset: 9, span: 3 }}
            md={{ offset: 9, span: 3 }}
            lg={{ offset: 9, span: 3 }}
            xl={{ offset: 9, span: 3 }}
            xxl={{ offset: 9, span: 3 }}
            className="text-end"
          >
            <Button onClick={handleSave} className={"m-2"} disabled={loading}>
              <i className="bi bi-floppy"></i> Actualizar horario
            </Button>
          </Col>
        </Row>
        <WorkingDays />
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
            <ExcludedDays />
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7}>
            <ExcludedHours />
          </Col>
        </Row>
      </Container>
      {message?.show ? (
        <Message
          show={message?.show}
          message={message?.message}
          hide={() => setMessage((prev) => ({ ...prev, show: false }))}
        />
      ) : null}
    </ScheduleContext.Provider>
  );
}
