import { useState } from "react";
import { Button, NavDropdown, Navbar } from "react-bootstrap";
import logo from "../../assets/images/logo/Logo_Blanco.svg";
import { IWarning, Message } from "../../components/message";
import Placeholder from "../../components/placeholder/placeholder";
import { useAuth } from "../../middlewares/auth";
import HttpService from "../../services/http";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants";
import LanguageSelection from "../../i18n/LanguageSelection";


function Header(): JSX.Element {
  const { state, logOut, menuBackground, loadingUser } = useAuth();
  const { i18n, t } = useTranslation()
  const [sending, setSending] = useState<boolean>(false);

  const [warning, setWarning] = useState<IWarning>({
    show: false,
    message: "",
  });

  function logout() {
    setSending(true);
    HttpService.post("logout")
      .then((result) => {
        if (result?.status) logOut();
        setSending(true);
      })
      .catch(() => {
        setWarning({
          show: true,
          message: `${t('logoutError')}`,
        });
        setSending(false);
      });
  }

  return (
    <>
      <Navbar expanded className={menuBackground}>
        <a href="/" className="navbar-logo">
          <img src={logo} className="img-fluid" alt="Logo" />
        </a>
        <div className="menu">
          <Navbar.Brand href={`${process.env.REACT_APP_URL_BASENAME}/`}>
            {t('home')}
          </Navbar.Brand>
         {/**  <Navbar.Brand
            href={`${process.env.REACT_APP_URL_BASENAME}/specialists`}
          >
            {t('specialists')}
          </Navbar.Brand>*/}
          <Navbar.Brand
            href={`${process.env.REACT_APP_URL_BASENAME}/procedures`}
          >
            {t('procedures')}
          </Navbar.Brand>
          <Navbar.Brand
            href={`${process.env.REACT_APP_URL_BASENAME}/contact-us`}
          >
            {t('contactUs')}
          </Navbar.Brand>
          <LanguageSelection />
        </div>
        {loadingUser ? (
          <div style={{ width: "100px" }}>
            <Placeholder />
          </div>
        ) : (
          <div className="item-login">
            {!state?.isLoged ? (
              <Navbar.Brand href="/login">
                <Button className="button-primary">{t('login')}</Button>

              </Navbar.Brand>
            ) : (
              <NavDropdown
                className="btn btn-primary btn-loginOn"
                title={`${state?.fullName}`}
                disabled={sending}
              >
                <NavDropdown.Item
                  className="panel"
                  disabled={sending}
                  href={`${process.env.REACT_APP_URL_BASENAME}/panel`}
                >
                  <i className="bi bi-layout-wtf"></i>
                  {t('myAccount')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  disabled={sending}
                  className="changepassword"
                  href={`${process.env.REACT_APP_URL_BASENAME}/change-password`}
                >
                  <i className="bi bi-file-lock2"></i>
                  {t('changePassword')}
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout} disabled={sending}>
                  <i className="bi bi-box-arrow-in-left"></i>
                  {t('logout')}
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </div>
        )}
      </Navbar>
      {warning?.show ? (
        <Message
          show={warning?.show}
          message={warning?.message}
          hide={() => setWarning({ show: false })}
        />
      ) : null}
    </>
  );
}

export default Header;
