
     import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const MapGoogle = () => {
  const [marcadores, setMarcadores] = useState([
    { lat: 6.2442, lng: -75.5812 }, // Medellín, Colombia
    { lat: 6.2518, lng: -75.5636 }, // Parque Lleras
    { lat: 6.2675, lng: -75.5681 }, // Pueblito Paisa
    // Agrega más posiciones de marcadores si es necesario
  ]);

  useEffect(() => {
    // Calcular la media de latitudes y longitudes
    const latitudes = marcadores.map((marcador) => marcador.lat);
    const longitudes = marcadores.map((marcador) => marcador.lng);
    const centerLat = latitudes.reduce((acc, lat) => acc + lat, 0) / latitudes.length;
    const centerLng = longitudes.reduce((acc, lng) => acc + lng, 0) / longitudes.length;

    // Actualizar el centro del mapa
    setMapCenter({ lat: centerLat, lng: centerLng });
  }, [marcadores]);

  const mapOptions = {
    disableDefaultUI: true,
    mapTypeControl: true,
    zoomControl: true,
    streetViewControl: true,
  };

  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const mapZoom = 16;

  return (
    <>
      <LoadScript googleMapsApiKey="AIzaSyATjozl7y1EIh2QeFOIsBWe2W36ogtgtDk">
        <GoogleMap
          mapContainerStyle={{ height: "100vh", width: "100%" }}
          center={mapCenter}
          zoom={mapZoom}
          options={mapOptions}
        >
          {marcadores.map((marcador, index) => (
            <Marker key={index} position={marcador} />
          ))}
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default MapGoogle;
