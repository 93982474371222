import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { Route, Routes } from "react-router-dom";
import { AuthMiddleware } from "../../middlewares/auth";
import Header from "./layout/header";
import SideNav from "./layout/sideNav";
import PanelProcedurespage from "./pages/procedures/panelProcedures";
import PanelSchedulepage from "./pages/schedule/panelSchedule";

import css from "./css/styles.module.css";
import PanelAppointmentpage from "./pages/appointment/panelAppointment";
import PanelHomepage from "./pages/home/panelHome";

import ViewPay from "../purchase/viewPay";
import Notification from "./components/notification";
import PanelProfile from "./pages/webprofile/panelProfile";
import PanelPatient from "./pages/patients/PanelPatient";

const PanelPageContext = createContext<{
  specialistSelected: number | null;
  setSpecialistSelected: Dispatch<SetStateAction<number | null>>;
  isShowNotifications: boolean;
  setIsShowNotifications: Dispatch<SetStateAction<boolean>>;
  countNotifications: number;
  setCountNotifications: Dispatch<SetStateAction<number>>;
}>({
  specialistSelected: null,
  setSpecialistSelected: () => {},
  isShowNotifications: false,
  setIsShowNotifications: () => {},
  countNotifications: 0,
  setCountNotifications: () => {},
});

export function usePanelPage() {
  const context = useContext(PanelPageContext);
  if (!context) throw new Error("There is no Context provider");
  return context;
}

export default function Panelpage(): JSX.Element {
  const [specialistSelected, setSpecialistSelected] = useState<number | null>(
    null
  );
  const [isShowNotifications, setIsShowNotifications] =
    useState<boolean>(false);
  const [countNotifications, setCountNotifications] = useState<number>(0);

  return (
    <AuthMiddleware>
      <PanelPageContext.Provider
        value={{
          specialistSelected,
          setSpecialistSelected,
          isShowNotifications,
          setIsShowNotifications,
          countNotifications,
          setCountNotifications,
        }}
      >
        <div className={css.panel}>
          <SideNav />
            
          <Header />
          <div className={css.panelComponents}>
            <Routes>
              <Route path="/*" element={<PanelHomepage />} />
              <Route path="/appointment" element={<PanelAppointmentpage />} />
              <Route path="/procedures" element={<PanelProcedurespage />} />
              <Route path="/settings" element={<PanelSchedulepage />} />
              <Route path="/profile" element={<PanelProfile  hidden={false}/>} />
              <Route path="/mydate" element={<ViewPay />} />
              <Route path="/historical" element={<ViewPay />} />
              <Route path="/patients" element={<PanelPatient/>}/>
            </Routes>
          </div>
        </div>
        <Notification />
      </PanelPageContext.Provider>
    </AuthMiddleware>
  );
}
