import { useEffect, useState } from "react";
import { IProcedure } from "../interface";
import HttpService from "../../../services/http";

export function useFetchSearch(procedure: string) {
  const [procedures, setProcedures] = useState<IProcedure[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let sending = true;
    if (sending) {
      setLoading(true);
      setProcedures([]);
      HttpService.post("procedures/getListName", {
        name: procedure,
        limit: 10,
      })
        .then((result) => {
          const data = result?.data as IProcedure[];
          setProcedures(data.map(({ id, name }) => ({ id, name })));
        })
        .finally(() => setLoading(false));
    }
    return () => {
      sending = false;
    };
  }, [procedure]);

  return { procedures, loading };
}
