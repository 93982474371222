import { useEffect, useState } from "react";
import HttpService from "../../../../../../../services/http";

export interface ICurrencyType {
  id: number;
  name: string;
}

export default function useFetchCurrencyType() {
  const [currencyTypes, setCurrencyTypes] = useState<ICurrencyType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    HttpService.post("domains/getDomainForType", { id: 9 })
      .then((result) => {
        const data = result?.data as ICurrencyType[];
        setCurrencyTypes(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return { currencyTypes, loading };
}
