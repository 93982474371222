import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Question {
  id: number;
  question: string;
  response: string;
  type: string;
}

function SatisfactionSurvey(): JSX.Element {
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [responses, setResponses] = useState<Question[]>([]);
  const { i18n, t } = useTranslation()
  const questions: Question[] = [
    {
      id: 1,
      question: `${t('rateService')}`,
      response: "",
      type: "stars",
    },
    {
      id: 2,
      question: `${t('satisfiedQualityProduct')}`,
      response: "",
      type: "thumbs",
    },
    {
      id: 3,
      question: `${t('recommendProductService')}`,
      response: "",
      type: "faces",
    },
  ];

  const nextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const prevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleResponse = (value: string) => {
    const newResponses: Question[] = [...responses];
    newResponses[currentQuestion] = {
      ...questions[currentQuestion],
      response: value,
    };
    setResponses(newResponses);
  };

  const renderQuestion = (question: Question) => {
    switch (question.type) {
      case "stars":
        return (
          <>
            <div>{question.question}</div>
            <div>
              <button onClick={() => handleResponse("5")} ><i className="bi bi-star-fill"></i></button>
              <button onClick={() => handleResponse("4")} ><i className="bi bi-star-fill"></i></button>
              <button onClick={() => handleResponse("3")} ><i className="bi bi-star-fill"></i></button>
              <button onClick={() => handleResponse("2")} ><i className="bi bi-star-fill"></i></button>
              <button onClick={() => handleResponse("1")} ><i className="bi bi-star-fill"></i></button>
            </div>
          </>
        );
      case "thumbs":
        return (
          <>
            <div>{question.question}</div>
            <div>
              <button onClick={() => handleResponse("Bueno")} ><i className="bi bi-hand-thumbs-up-fill"></i></button>
              <button onClick={() => handleResponse("Malo")} ><i className="bi bi-hand-thumbs-down-fill"></i></button>
            </div>
          </>
        );
      case "faces":
        return (
          <>
            <div>{question.question}</div>
            <div>
              <span
                role="img"
                aria-label="Muy Malo"
                onClick={() => handleResponse("Muy Malo")}
              >
                😞
              </span>
              <span
                role="img"
                aria-label="Malo"
                onClick={() => handleResponse("Malo")}
              >
                😐
              </span>
              <span
                role="img"
                aria-label="Regular"
                onClick={() => handleResponse("Regular")}
              >
                😊
              </span>
              <span
                role="img"
                aria-label="Bueno"
                onClick={() => handleResponse("Bueno")}
              >
                😃
              </span>
              <span
                role="img"
                aria-label="Excelente"
                onClick={() => handleResponse("Excelente")}
              >
                😍
              </span>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="survey-container">
      <h2>{t('satisfactionSurvey')}</h2>
      <Form>
        <Form.Group>{renderQuestion(questions[currentQuestion])}</Form.Group>
        <Button variant="primary" onClick={prevQuestion}>
          {t('previous')}
        </Button>{" "}
        {currentQuestion < questions.length - 1 && (
          <Button variant="primary" onClick={nextQuestion}>
            {t('next')}
          </Button>
        )}
        {currentQuestion === questions.length - 1 && (
          <Button variant="success">{t('send')}</Button>
        )}
      </Form>
    </div>
  );
}

export default SatisfactionSurvey;
