import { useEffect, useState } from "react";
import { ISpecialist } from "../../../interfaces/specialist/specialist.interface";
import HttpService from "../../../services/http";

export default function useFetchSpecialist(id: any) {
  const [specialist, setSpecialist] = useState<ISpecialist>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    HttpService.post("specialists/getProfileId", { id })
      .then((result) => {
        const data = result?.data || {};
        setSpecialist(data);
        setLoading(false);
      })
      .catch(() => setError(true));
  }, [id]);

  return { specialist, loading, error };
}
