
import { Col, Container, Row } from "react-bootstrap";

import css from "./about-us.module.css";
import { useTranslation } from "react-i18next";


function AboutusPage(): JSX.Element {
  const { i18n, t } = useTranslation()
  return (
    <>
      <Container fluid className={css.container}>
        <Row className={css.row}>
          <Col xs sm md lg xl xxl>
            <h3>{t('aboutUs')}</h3>
            <h4>
              {t('aboutUsContent')}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs sm md lg xl xxl>
            <h3>{t('vision')}</h3>
            <h4>
              {t('visionContent')}
            </h4>
          </Col>
          <Col xs sm md lg xl xxl>
            <h3>{t('mission')}</h3>
            <h4>
              {t('missionContent')}
            </h4>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AboutusPage;
