import { createContext, useContext, useEffect, useState, Dispatch, SetStateAction, PropsWithChildren } from "react";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";  // Importa otros idiomas que puedas necesitar

const LanguageContext = createContext<{
  language: string;
  setLanguage: Dispatch<SetStateAction<string>>;
}>({
  language: "es",
  setLanguage: () => {},
});

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (!context) throw new Error("No se reconoce el contexto de idioma.");
  return context;
}

export function LanguageProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem("app-language") || "es";
  });

  useEffect(() => {
    localStorage.setItem("app-language", language);
    dayjs.locale(language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
