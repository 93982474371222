import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { IUserLoged } from "../interfaces/user/user.interface";
import HttpService from "../services/http";
import { IAction } from "./auth.interface";
import { ACTIONS } from "./auth.enum";

export const AuthContext = createContext<{
  loadingUser: boolean;
  menuBackground: string;
  state: IUserLoged | null;
  logIn: (user: IUserLoged) => void;
  logOut: () => void;
  setMenuBackground: Dispatch<SetStateAction<string>>;
}>({
  loadingUser: false,
  menuBackground: "",
  state: null,
  logIn: () => {},
  logOut: () => {},
  setMenuBackground: () => {},
});

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) throw new Error("There is no Context provider");
  return context;
}

const initialState: IUserLoged = {
  isLoged: false,
  xToken: null,
  fullName: null,
};

function reducer(user: IUserLoged, action: IAction) {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.ATTRIBUTES:
      return { ...user, ...payload };
    default:
      return { ...user };
  }
}

function useFetchUser(token: string | undefined) {
  const [user, setUser] = useState<IUserLoged>();
  const [loading, setLoading] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (token) {
      setLoading(true);
      HttpService.post("user/getUser")
        .then((result) => {
          const { status, data } = result;
          if (status)
            setUser({
              id: data?.id,
              isLoged: true,
              fullName: `${data?.name ?? data?.first_name ?? ""} ${
                data?.surname ?? data?.last_name ?? ""
              }`,
              xToken: token,
              admin: data?.profile_id === 1,
              specialist: data?.profile_id === 2,
              secretary: data?.profile_id === 3,
              patient: data?.profile_id === 4,
              userId: data?.user_id,
            });
          else setUser({ isLoged: false });
          setLoading(false);
        })
        .catch(() => {
          const user = initialState;
          dispatch({ type: ACTIONS.ATTRIBUTES, payload: user });
          window.localStorage.removeItem("inmed-token");
          window.location.href = "/";
          setUser({ isLoged: false });
        });
    }
  }, [token]);

  return { user, loading };
}

export function AuthMiddleware({ children }: PropsWithChildren): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [menuBackground, setMenuBackground] = useState("blue");

  const [token, setToken] = useState<string>();

  const { user, loading } = useFetchUser(token);

  const location = useLocation();

  useEffect(() => {
    if (location?.pathname === "/") setMenuBackground("");
    else setMenuBackground("blue");
  }, [location]);

  useEffect(() => {
    const currentToken = window.localStorage.getItem("inmed-token");
    if (currentToken) setToken(currentToken);
  }, []);

  useEffect(() => {
    if (user?.isLoged) logIn(user);
  }, [user?.isLoged]);

  function logIn(user: IUserLoged) {
    if (user?.xToken) {
      dispatch({
        type: ACTIONS.ATTRIBUTES,
        payload: { ...initialState, ...user },
      });
      window.localStorage.setItem("inmed-token", user?.xToken ?? "");
    }
  }

  function logOut() {
    const user = initialState;
    dispatch({ type: ACTIONS.ATTRIBUTES, payload: user });
    window.localStorage.removeItem("inmed-token");
    window.location.href = "/";
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        loadingUser: loading,
        logIn,
        logOut,
        menuBackground,
        setMenuBackground,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
