import { useEffect, useState } from "react";
import { ISpecialist } from "../../../interfaces/specialist/specialist.interface";
import HttpService from "../../../services/http";

export function useFetchSpecialist(specialist: string) {
  const [specialists, setSpecialists] = useState<ISpecialist[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let sending = true;
    if (sending) {
      setLoading(true);
      setSpecialists([]);
      HttpService.post("specialists/getListName", {
        name: specialist,
        limit: 10,
      })
        .then((result) => {
          const data = result?.data as ISpecialist[];
          setSpecialists(
            data.map(({ id, name, surname }) => ({
              id,
              name,
              surname,
            }))
          );
        })
        .finally(() => setLoading(false));
    }
    return () => {
      sending = false;
    };
  }, [specialist]);

  return { specialists, loading };
}
