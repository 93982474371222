import axios from "axios";
import { ApiResponse, ApiResponsePaginate } from "../types/http";

class HttpService {
  public static getHeader(): any {
    const inmedToken = window.localStorage.getItem("inmed-token");
    const config = { "Content-Type": "application/json" };

    if (!inmedToken) return { ...config };

    return {
      ...config,
      Authorization: `Bearer ${inmedToken}`,
    };
  }

  public static get(url: string) {
    const xhr: Promise<ApiResponse> = axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/api/${url}`,
      headers: HttpService.getHeader(),
    }).then((res) => res.data);
    return xhr;
  }

  public static post(url: string, data = {}, headers = {}) {
    const xhr: Promise<ApiResponse> = axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/api/${url}`,
      data,
      headers: { ...HttpService.getHeader(), ...headers },
    }).then((res) => res.data);
    return xhr;
  }

  public static postPaginate(url: string, data = {}) {
    const xhr: Promise<ApiResponsePaginate> = axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/api/${url}`,
      data,
      headers: HttpService.getHeader(),
    }).then((res) => res.data);
    return xhr;
  }
}

export default HttpService;
