import React, { useEffect, useState } from "react";
import { ListGroup, Form, Button, Row, Col, Container } from "react-bootstrap";
import { usePanelPage } from "../../../../panelPage";
import { useAuth } from "../../../../../../middlewares/auth";
import { IMessage, Message } from "../../../../../../components/message";
import {
  ISpecialist,
  INetwork,
} from "../../../../../../interfaces/specialist/specialist.interface";
import ReactSelect from "react-select";
import { styleselectreact } from "../../../../../../styles";

const SocialMediaSection = ({
  socialMedia,
  setSocialMedia,
  idSpecialist,
  list,
}: {
  socialMedia: INetwork[];
  setSocialMedia: any;
  idSpecialist: any;
  list: any;
}) => {
  const [newSocialMedia, setNewSocialMedia] = useState<any>(null);

  const [options, setoptions] = useState<any[]>([]);

  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  const handleAddSocialMedia = () => {
    if (newSocialMedia) {
      // Verificar si la red social ya existe en el array de socialMedia
      const socialMediaExists = socialMedia?.some(
        (media) => media?.domain?.id === newSocialMedia.value
      );

      if (!socialMediaExists) {
        // Obtener el ID del especialista
        const specialistId: number | undefined = idSpecialist ?? undefined;
        const socialMediaicon = list?.find(
          (media:any) => media.id === newSocialMedia.value  
        );
        // Crear el nuevo objeto de red social con un ID temporal (-1)
        const newSocialMediaObject: INetwork = {
          id: -1,
          network_type: newSocialMedia.value,
          specialist_id: specialistId,
          domain: {
            id: newSocialMedia.value,
            name: newSocialMedia.label,
            icon: socialMediaicon?.icon,
          },
        };

        // Actualizar el array de redes sociales
        const updatedSocialMedia: INetwork[] = [
          ...socialMedia,
          newSocialMediaObject,
        ];
        setSocialMedia(updatedSocialMedia);
        setNewSocialMedia(""); // Limpiar el valor de la nueva red social
      } else {
        setMessage({ show: true, message: "¡La red social ya existe!" });
      }
    } else {
      setMessage({
        show: true,
        message: "Por favor, seleccione una red social válida",
      });
    }
  };

  const handleRemoveSocialMedia = (id: number) => {
    const updatedSocialMedia = socialMedia?.filter(
      (data) => data.network_type !== id
    );
    setSocialMedia(updatedSocialMedia);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewSocialMedia(e.target.value);
  };
  useEffect(() => {
    debugger;
    const selectOptions: any[] = list.map((option: any) => ({
      value: option.id,
      label: option.name,
    }));
    setoptions(selectOptions);
  }, [list]);

  const handleUrlChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    networkType: number
  ) => {
    const updatedSocialMedia = socialMedia.map((item) => {
      if (item.network_type === networkType) {
        return {
          ...item,
          url: e.target.value,
        };
      }
      return item;
    });
    setSocialMedia(updatedSocialMedia);
  };

  return (
    <>
      <fieldset className="border p-2 mt-3 mb-3">
        <legend className="w-auto">Redes Sociales</legend>
        <Row>
          <Col xs={10} sm={10} className="pt-2">
            <ReactSelect
              value={newSocialMedia}
              onChange={setNewSocialMedia}
              options={options}
              placeholder="Seleccionar"
              noOptionsMessage={({ inputValue }) => {
                return inputValue
                  ? "No se encontraron opciones"
                  : "Ingrese un valor para comenzar la búsqueda";
              }}
              styles={styleselectreact}
              isSearchable
              menuPortalTarget={document.body}
            />
          </Col>
          <Col xs={2} sm={2}>
            <button
              title="Agregar"
              className="btnadd"
              onClick={handleAddSocialMedia}
            >
              <i className="bi bi-node-plus-fill"></i>
            </button>
          </Col>
        </Row>

        <ListGroup className="profilecontainer">
          {socialMedia.map((data) => (
            <ListGroup.Item key={data.network_type} className="pt-1">
              <Row>
                <Col xs={2} sm={2}>
                  <i className={`bi ${data?.domain?.icon}   profileIcon`}></i>
                </Col>
                <Col xs={8} sm={8}>
                  <Form.Control
                    type="text"
                    placeholder="URL"
                    value={data.url || ""}
                    onChange={(e: any) =>
                      handleUrlChange(e, data.network_type ?? 0)
                    }
                  />
                </Col>
                <Col xs={2} sm={2}>
                  <button
                    title="Eliminar"
                    className="btndelete"
                    onClick={() =>
                      handleRemoveSocialMedia(data.network_type ?? 0)
                    }
                  >
                    <i className="bi bi-trash3"></i>
                  </button>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </fieldset>
      {message?.show ? (
        <Message
          show={message?.show}
          message={message?.message}
          hide={() => setMessage((prev) => ({ ...prev, show: false }))}
        />
      ) : null}
    </>
  );
};
export default SocialMediaSection;
