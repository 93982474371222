import { Patient } from "../types/patient";
import { AppointmentType, AppointmentTypeInfo } from "../types/specialist";
import HttpService from "./http";

async function getAppointmentTypes({
  specialistId,
}: {
  specialistId: number | null;
}) {
  if (!specialistId) return [];
  const response = await HttpService.post("specialists/getTypeAppointment", {
    specialist_id: specialistId,
  });
  const data: AppointmentType[] = response?.data ?? [];
  return data;
}

async function getAppointmentTypesInfo({
  specialistId,
}: {
  specialistId: number | null;
}) {
  const result = await getAppointmentTypes({ specialistId });
  const data: AppointmentTypeInfo[] = [];
  result?.forEach((value) => {
    if (!data?.some((info) => info?.id === value?.appointments_type_id?.id))
      data.push(value?.appointments_type_id);
  });
  return data;
}

async function getPatients({ specialistId }: { specialistId: number | null }) {
  const response = await HttpService.get(
    `specialists/getPatients?specialistId=${specialistId}`
  );
  const data: Patient[] = response?.data ?? [];
  return data;
}

export { getAppointmentTypesInfo, getPatients };
