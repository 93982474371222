import { BrowserRouter, Navigate, Route, Routes, useParams } from "react-router-dom";

import ContactUspage from "../pages/contact-us/contact-us";
import Homepage from "../pages/home/home";
import Procedurepage from "../pages/procedure/procedure";
import Procedurespage from "../pages/procedure/procedures";
import Rootpage from "../pages/root";
import Specialistpage from "../pages/specialist/specialist";
import Specialistspage from "../pages/specialist/specialists";

import Login from "../features/login/login";
import AboutusPage from "../pages/about-us/about-us";
import ChangePasswordPage from "../pages/login/changePassword";
import Register from "../pages/login/register";
import EditSpecialist from "../pages/panel/pages/specialist/editspecialist";
import Panelpage from "../pages/panel/panelPage";
import Purchasepage from "../pages/purchase/purchase";
import Responsepage from "../pages/purchase/response";
import ResponseSitepage from "../pages/purchase/responseSite";
import ViewPay from "../pages/purchase/viewPay";
import ContextPage from "../pages/AddContext";
import Survey from "../pages/survey/survey";
import Questions from "../pages/ratings/Questions";
interface RedirectToSpecialistProps {
  params: {
    code: string;
  };
}
function RedirectToSpecialist(): JSX.Element {
  const { code } = useParams();
  const decodedCode = atob(code?.toString() || '');
  // Eliminar los ceros no significativos del código
  const id = parseInt(decodedCode || "0", 10).toString(); // Convertir a número y luego a cadena para eliminar los ceros no significativos
  return <Navigate to={`/specialist/${id}`} replace />;
}
export default function AppRoutes(): JSX.Element {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route
          path="/"
          element={
            <Rootpage>
              <Homepage />
            </Rootpage>
          }
        />

        <Route
          path="/Question"
          element={
              <Questions />
          }
        />
        <Route
          path="/about-us"
          element={
            <Rootpage>
              <AboutusPage />
            </Rootpage>
          }
        />
        <Route
          path="/specialists"
          element={
            <Rootpage>
              <Specialistspage />
            </Rootpage>
          }
        />
        <Route
          path="/specialist/:id"
          element={
            <Rootpage>
              <Specialistpage />
            </Rootpage>
          }
        />
        <Route
          path="/cp/:code"
          element={
            <Rootpage>
              <RedirectToSpecialist />
            </Rootpage>
          }
        />
        <Route
          path="/procedures"
          element={
            <Rootpage>
              <Procedurespage />
            </Rootpage>
          }
        />
        <Route
          path="/procedure/:id"
          element={
            <Rootpage>
              <Procedurepage />
            </Rootpage>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Rootpage>
              <ContactUspage />
            </Rootpage>
          }
        />
        <Route path="/survey" element={<Survey />} />
        <Route
          element={
            <Rootpage>
              <Login />
            </Rootpage>
          }
          path="/login"
        />
        <Route
          path="/register-patient"
          element={
            <Rootpage>
              <Register />
            </Rootpage>
          }
        />
        <Route
          path="/change-password"
          element={
            <Rootpage>
              <ChangePasswordPage />
            </Rootpage>
          }
        />
        <Route
          path="/test"
          element={
            <Rootpage>
              <ViewPay />
            </Rootpage>
          }
        />
        <Route path="/panel/*" element={<Panelpage />} />
        <Route
          path="/purchase"
          element={
            <Rootpage>
              <Purchasepage />
            </Rootpage>
          }
        />
        <Route
          path="/editspecialist"
          element={
            <Rootpage>
              <EditSpecialist />
            </Rootpage>
          }
        />
        <Route
          path="/purchase/response"
          element={
            <ContextPage>
              <Responsepage />
            </ContextPage>
          }
        />
        <Route
          path="/purchase/responseSite"
          element={
            <ContextPage>
              <ResponseSitepage />
            </ContextPage>
          }
        />
        <Route path="*" element={<h2>Ups... Algo ha salido mal...</h2>} />
      </Routes>
    </BrowserRouter>
  );
}
