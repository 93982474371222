import { Col, Container, Row } from "react-bootstrap";

import { useAuth } from "../../../../middlewares/auth";
import AppointmentsThreeMonths from "./components/AppointmentsThreeMonths";
import PendingAppointment from "./components/pendingAppointment";
import UpcomingAppointments from "./components/upcomingAppointments";
import TwitterFeed from "./components/TwitterFeed";

function HomeSpecialist(): JSX.Element {
  return (
    <Container>
      <Row>
        <Col md={8}>
          <UpcomingAppointments />
          <PendingAppointment />
          {/*  <AppointmentsThreeMonths />*/}
        </Col>
        <Col md={4}>
          <TwitterFeed />
        </Col>
      </Row>
    </Container>
  );
}

export default function PanelHomepage(): JSX.Element {
  const { state } = useAuth();
  return <>{(state?.specialist || state?.secretary) && <HomeSpecialist />}</>;
}
