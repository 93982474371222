import { useEffect, useState } from "react";
import HttpService from "../../../services/http";
import { IImageVideo, IProcedure } from "../interface";

export function useFetchProcedure(
  id: string | undefined,
  previewProcedure?: {
    procedure: IProcedure;
    images: IImageVideo[];
    videos: IImageVideo[];
  }
) {
  const [procedure, setProcedure] = useState<IProcedure>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    let sending = true;
    if (sending) {
      setLoading(true);

      HttpService.post("procedures/getProcedure", { id })
        .then((result) => {
          const data = result?.data as IProcedure;
          setProcedure(data);
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
    return () => {
      sending = false;
    };
  }, [id, previewProcedure]);

  return { procedure, loading, error };
}
