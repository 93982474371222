import { useEffect, useState } from "react";
import HttpService from "../../../../../../../services/http";

export interface ITypePay {
  specialist_id: number;
  pay_id: number;
}
export default function useFetchTypePay(
  specialist_id: number | null | undefined
) {
  const [typePay, setTypePay] = useState<ITypePay[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    HttpService.get("specialists/getSpecialistTypePays/" + specialist_id)
      .then((result) => {
        const data = result?.data as ITypePay[];
        setTypePay(data);
        setLoading(false);
      })
      .catch(() => {
        setTypePay([]);
        setLoading(true);
      });
  }, [specialist_id]);

  return { typePay, loading };
}
