import { DateCalendar, LocalizationProvider, esES } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/es";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import {
  getAppointmentTypesInfo,
  getPatients,
} from "../../../../services/specialist";
import { Patient } from "../../../../types/patient";
import { AppointmentTypeInfo } from "../../../../types/specialist";
import { usePanelPage } from "../../panelPage";
import Calendar from "./components/calendar";
import ScheduleAppointment from "../scheduleappointment/ScheduleAppointment";
import { IMessage, Message } from "../../../../components/message";
dayjs.locale("es");

const AppointmentContext = createContext<{
  date: dayjs.Dayjs | null;
  setDate: Dispatch<SetStateAction<dayjs.Dayjs | null>>;
  patientDocument: string | null;
  appointmentType: string | null;
  refresh: boolean;
  setRefresh: any;
}>({
  date: null,
  setDate: () => {},
  patientDocument: null,
  appointmentType: null,
  refresh: false,
  setRefresh: () => {},
});

export function useAppointment() {
  const context = useContext(AppointmentContext);
  if (!context) throw new Error("No se reconoce el contexto.");
  return context;
}

export default function PanelAppointmentpage(): JSX.Element {
  const { specialistSelected } = usePanelPage();

  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [appoinmentTypes, setAppointmentTypes] = useState<
    AppointmentTypeInfo[]
  >([]);
  const [patients, setPatients] = useState<Patient[]>([]);

  const [appointmentType, setAppointmentType] = useState<string | null>(null);
  const [patientDocument, setPatientDocument] = useState<string | null>(null);

  const [showCalendar, setShowCalendar] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setAppointmentTypes(
        await getAppointmentTypesInfo({
          specialistId: specialistSelected,
        })
      );
      setPatients(await getPatients({ specialistId: specialistSelected }));
    };
    fetchData();
  }, [specialistSelected, showNew]);

  const calendar = (
    <Popover id="popover-positioned-bottom" title="Seleccionar fecha">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={esES}>
        <DateCalendar value={date} onChange={(value) => setDate(value)} />
      </LocalizationProvider>
    </Popover>
  );
  return (
    <AppointmentContext.Provider
      value={{
        date,
        setDate,
        patientDocument,
        appointmentType,
        refresh,
        setRefresh,
      }}
    >
      <Container fluid>
        <Row className="align-items-center">
          <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <h2 className="mt-2 title-form">
              <i className="bi bi-calendar-week m-2"></i>Calendario
            </h2>
          </Col>
          <Col className="text-end" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Row>
              <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                {" "}
                <Form.Label className="title-form">Tipo de cita</Form.Label>
                <Form.Select
                  onChange={(event) =>
                    setAppointmentType(event.target.value ?? null)
                  }
                >
                  <option value="">Todos</option>
                  {appoinmentTypes?.map((value) => (
                    <option key={value?.id} value={value?.id}>
                      {value?.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                <Form.Label className="title-form">Paciente</Form.Label>
                <Form.Select
                  onChange={(event) =>
                    setPatientDocument(event.target.value ?? null)
                  }
                >
                  <option value="">Todos</option>
                  {patients?.map((value) => (
                    <option key={value?.document} value={value?.document}>
                      {`${value?.first_name} ${value?.last_name}`}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Col className="mt-3" xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={calendar}
                >
                  <button
                    className={"btn-calendar-open"}
                    onClick={() => setShowCalendar(!showCalendar)}
                  >
                    Fecha
                    <i className="bi bi-calendar-date m-3"></i>
                  </button>
                </OverlayTrigger>
              </Col>
              <Col className="mt-3" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <button
                  className={"btn-calendar-open"}
                  onClick={() => setShowNew(true)}
                >
                  Nueva Cita
                  <i className="bi bi-calendar2-plus m-3"></i>
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="m-2" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Calendar />
          </Col>
        </Row>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          show={showNew}
          onHide={() => setShowNew(false)}
          className="modalAppointment"
        >
          <Modal.Header closeButton>
            <Modal.Title>Nueva Cita</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showNew && (
              <ScheduleAppointment
                appointmentId={null}
                handleAction={(action: any, message: any) => {
                  setShowNew(false);
                  setRefresh(true);
                  if (message !== "" && message !== undefined) {
                    setMessage({
                      show: true,
                      message,
                    });
                  }
                }}
              ></ScheduleAppointment>
            )}
          </Modal.Body>
        </Modal>
        {message?.show ? (
          <Message
            show={message?.show}
            message={message?.message}
            hide={() => setMessage((prev: any) => ({ ...prev, show: false }))}
          />
        ) : null}
      </Container>
    </AppointmentContext.Provider>
  );
}
