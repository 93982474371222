import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { IMessage, Message } from "../../../../../../components/message";
import { useAuth } from "../../../../../../middlewares/auth";
import HttpService from "../../../../../../services/http";
import { usePanelPage } from "../../../../panelPage";
import useFetchAppointmentTypes from "./hooks/useFetchAppointmentTypes";
import useFetchCurrencyType from "./hooks/useFetchCurrencyType";

interface IAppointmentTypeSpecialist {
  id: number | null;
  appointment_type_id?: number;
  duration?: number;
  price?: NumberFormatValues;
  currency_type?: number;
  principal_appointment?: boolean;
}

function useFetchAppointmentTypeId(id: number | null) {
  const [appointmentType, setAppointmentType] =
    useState<IAppointmentTypeSpecialist>({
      id: null,
      appointment_type_id: -1,
      duration: 0,
      price: undefined,
      currency_type: -1,
      principal_appointment: false,
    });

  useEffect(() => {
    let activo = true;
    if (activo && id)
      HttpService.post("specialists/getTypeAppointment", { id })
        .then((result) => {
          const data = result?.data[0] as IAppointmentTypeSpecialist;

          const formattedPrice = new Intl.NumberFormat("es-ES", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
          }).format(result?.data[0]?.price ?? 0);
       
          setAppointmentType({
            ...data,
            price: {
              value: formattedPrice,
              floatValue: 0,
              formattedValue: formattedPrice,
            },
          });
        })
        .catch(() =>
          setAppointmentType({
            id: null,
            appointment_type_id: -1,
            duration: 0,
            price: undefined,
            currency_type: -1,
            principal_appointment: false,
          })
        );
    return () => {
      activo = false;
    };
  }, [id]);

  return { appointmentType, setAppointmentType };
}

interface ModalProps {
  showModal: boolean;
  id: number | null;
  handleClose: () => void;
}

export default function ModalAppointment({
  showModal,
  id,
  handleClose,
}: ModalProps): JSX.Element {
  const { state } = useAuth();
  const { specialistSelected } = usePanelPage();

  const [idSpecialist, setIdSpecialist] = useState<number | null | undefined>(
    state?.specialist ? state?.id : state?.secretary ? specialistSelected : null
  );

  useEffect(() => {
    setIdSpecialist(
      state?.specialist
        ? state?.id
        : state?.secretary
        ? specialistSelected
        : null
    );
  }, [state?.specialist, state?.secretary, specialistSelected]);

  const { appointmentTypes } = useFetchAppointmentTypes();
  const { currencyTypes } = useFetchCurrencyType();

  const { appointmentType, setAppointmentType } = useFetchAppointmentTypeId(id);

  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  useEffect(() => {
    if (!showModal)
      setAppointmentType({
        id: null,
        appointment_type_id: -1,
        duration: 0,
        price: undefined,
        currency_type: -1,
        principal_appointment: false,
      });
  }, [showModal]);

  function handleSave() {
    if (appointmentType?.appointment_type_id === -1)
      setMessage({
        show: true,
        message: "Debe seleccionar el tipo de cita.",
      });
    else if (!appointmentType?.price)
      setMessage({
        show: true,
        message: "Debe ingresar el precio.",
      });
    else if (appointmentType?.currency_type === -1)
      setMessage({
        show: true,
        message: "Debe seleccionar el tipo de monera.",
      });
    else {
      HttpService.post(
        `specialists/${id ? "update" : "create"}TypeAppointment`,
        {
          specialist_id: idSpecialist,
          appointment_type_id: appointmentType?.appointment_type_id,
          duration: appointmentType?.duration,
          price: appointmentType?.price?.floatValue,
          currency_type: appointmentType?.currency_type,
          principal_appointment: appointmentType?.principal_appointment,
          id,
        }
      )
        .then((result) => {
          const { status, message } = result;
          if (status) {
            setMessage({ show: true, message: "Tipo de cita creada." });
            handleClose();
          } else
            setMessage({
              show: true,
              message,
            });
        })
        .catch(() =>
          setMessage({
            show: true,
            message: "No se pudo crear el tipo de cita.",
          })
        );
    }
  }

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="h6">{`${
            id ? "Editar" : "Adicionar"
          } Cita`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={4}>
              <Form.Label>Tipo de cita</Form.Label>
              <Form.Select
                value={appointmentType?.appointment_type_id}
                onChange={(event) =>
                  setAppointmentType((prev) => ({
                    ...prev,
                    appointment_type_id: Number(event.target.value),
                  }))
                }
              >
                <option value={-1}>Seleccione</option>
                {appointmentTypes?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col sm={4}>
              <Form.Label>Tiempo (minutos)</Form.Label>
              <Form.Control
                type="number"
                value={appointmentType?.duration}
                min={0}
                onChange={(event) => {
                  const value = event.target.value;
                  if (/^\d+(\.\d{1,2})?$/.test(value) || value === "")
                    setAppointmentType((prev) => ({
                      ...prev,
                      duration: Number(value),
                    }));
                }}
                pattern="^\d+(\.\d{1,2})?$"
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Precio</Form.Label>
              <NumericFormat
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                prefix={"$"}
                value={appointmentType?.price?.formattedValue}
                min={0}
                onValueChange={(values) =>
                  setAppointmentType((prev) => ({ ...prev, price: values }))
                }
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Tipo de moneda</Form.Label>
              <Form.Select
                value={appointmentType?.currency_type}
                onChange={(event) =>
                  setAppointmentType((prev) => ({
                    ...prev,
                    currency_type: Number(event.target.value),
                  }))
                }
              >
                <option value={-1}>Seleccione</option>
                {currencyTypes?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col sm={4}>
              <div className="d-flex align-items-center mt-4">
                <Form.Check type={"checkbox"}>
                  <Form.Check.Input
                    type={"checkbox"}
                    isValid
                    checked={appointmentType?.principal_appointment}
                    onChange={(event) =>
                      setAppointmentType((prev) => ({
                        ...prev,
                        principal_appointment: event.target.checked,
                      }))
                    }
                  />
                  <Form.Check.Label>Cita principal</Form.Check.Label>
                </Form.Check>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleClose}>Cancelar</Button>
          <Button onClick={handleSave}>Confirmar</Button>
        </Modal.Footer>
      </Modal>

      <Message
        show={message?.show}
        message={message?.message}
        hide={() => setMessage((prev) => ({ ...prev, show: false }))}
      />
    </>
  );
}
