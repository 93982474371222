import { useEffect, useState } from "react";
import { ICity } from "../../../interfaces/specialist/specialist.interface";
// import HttpService from "../../../services/http";

export function useFetchCity() {
  const [cities, setCities] = useState<ICity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let sending = true;
    if (sending) {
      setLoading(true);
      setCities([{ id: "1", name: "Medellín" }]);
      setLoading(false);
    }
    return () => {
      sending = false;
    };
  }, []);

  return { cities, loading };
}
