interface IType {
  id: number;
  name: string;
}

export interface IImageVideo {
  id?: number;
  name?: string;
  procedure_id?: number;
  url?: string;
}

export interface IProcedure {
  id?: string | null;
  name?: string | null;
  image?: string | null;
  descripcion?: string | null;
  images?: IImageVideo[];
  procedureType?: IType | null;
  procedure_type?: number;
  video?: IImageVideo[];
}

export const emptyProcedure: IProcedure = {
  id: null,
  name: "",
  image: "",
  descripcion: "",
  images: [],
  procedureType: null,
  procedure_type: -1,
  video: [],
};
