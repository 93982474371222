import Patterns from "./patterns";

class Util {

    public static encode64(value: string): string {
        return Buffer.from(value, 'utf-8').toString('base64');
    }

    public static decode64(value: string): string {
        return Buffer.from(value, "base64").toString("binary");
    }

    public static isValidEmail(email: string): boolean {
        return Patterns.EMAIL.test(email);
    }

    public static formatCurrency(value: number | bigint, locale = "es-CO", currency = "COP"): string {
        return Intl.NumberFormat(locale, { style: "currency", currency }).format(value);
    }

}

export default Util;