import { useEffect, useState } from "react";
import { ISpecialist } from "../../../interfaces/specialist/specialist.interface";
import HttpService from "../../../services/http";
import { Meta } from "../../../types/http";

export function useFetchSpecialists(id: string | undefined, page: number) {
  const [specialists, setSpecialists] = useState<ISpecialist[]>([]);
  const [meta, setMeta] = useState<Meta>({
    current_page: 1,
    first_page: 1,
    first_page_url: "/?page=1",
    last_page: 1,
    last_page_url: "/?page=1",
    next_page_url: "/?page=1",
    per_page: 4,
    previous_page_url: "/?page=1",
    total: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let sending = true;
    if (sending) {
      setLoading(true);
      HttpService.postPaginate(`procedures/getProcedureSpecilist`, {
        id,
        page,
        limit: 3,
      })
        .then((result) => {
          const info = result?.data || {};
          const { data, meta } = info;
          setSpecialists(data);
          setMeta(meta);
        })
        .catch(() => setSpecialists([]))
        .finally(() => setLoading(false));
    }
    return () => {
      sending = false;
    };
  }, [id, page]);

  return { specialists, meta, loading };
}
