import { useContext, useState } from "react";
import { PanelProcedureContext } from "../panelProcedures";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import Multiselect from "multiselect-dropdownx";
import {
  IProcedureType,
  useFetchProceduresTypes,
} from "../../../../../hooks/useFetchProceduresTypes";
import TableInmed from "./table";

function SearchContainer() {
  const {
    editMode,
    setEditMode,
    setIdProcedure,
  }: {
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    setIdProcedure: (idProcedure: string | undefined) => void;
  } = useContext(PanelProcedureContext);
  const [name, setName] = useState<string>("");
  const deboucedName = useDebounce(name);

  const [filterType, setFilterType] = useState<IProcedureType[]>([]);
  const { types } = useFetchProceduresTypes();

  return (
    <Container hidden={editMode}>
      <Row>
        <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
          <InputGroup>
            <InputGroup.Text>Procedimientos</InputGroup.Text>
            <button
              onClick={() => {
                setIdProcedure(undefined);
                setEditMode(true);
              }}
            >
              +
            </button>
          </InputGroup>
        </Col>
        <Col
          xs={{ offset: 3, span: 3 }}
          sm={{ offset: 3, span: 3 }}
          md={{ offset: 3, span: 3 }}
          lg={{ offset: 3, span: 3 }}
          xl={{ offset: 3, span: 3 }}
          xxl={{ offset: 3, span: 3 }}
        >
          <Form.Label>Tipos de procedimientos</Form.Label>
          <Multiselect
            options={types}
            showCheckbox
            displayValue="name"
            hidePlaceholder
            placeholder=""
            emptyRecordMsg="No se encontraron coincidencias"
            selectedValues={filterType}
            onSelect={(selectedList: IProcedureType[]) =>
              setFilterType(selectedList)
            }
            onRemove={(selectedList: IProcedureType[]) =>
              setFilterType(selectedList)
            }
          />
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
          <Form.Label>Buscar</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Col>
      </Row>
      <TableInmed
        name={deboucedName}
        filterType={filterType.map(({ id }) => id).join(",")}
      />
    </Container>
  );
}

export default SearchContainer;
