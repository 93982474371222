import { useState } from "react";
import { Container, Nav } from "react-bootstrap";
import { useAuth } from "../../../../middlewares/auth";
import { usePanelPage } from "../../panelPage";
import Prices from "./components/prices/prices";
import Schedule from "./components/schedule/schedule";
import Typepay from "./components/typepay/Typepay";
import PanelProfile from "../webprofile/panelProfile";

export default function PanelSchedulepage(): JSX.Element {
  const { state } = useAuth();

  const { specialistSelected } = usePanelPage();

  const [activeKey, setActiveKey] = useState<string | null>("0");

  if (state?.secretary && !specialistSelected)
    return <h2>Debe seleccionar el especialista a modificar.</h2>;

  return (
    <Container fluid>
      <Nav
        variant="tabs"
        activeKey={activeKey || "0"}
        onSelect={(newKey) => setActiveKey(newKey)}
      >
        <Nav.Item>
          <Nav.Link eventKey="0">Info web</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2">Horario</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="3">Citas</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="4">Metodos de pago</Nav.Link>
        </Nav.Item>
      </Nav>
      <PanelProfile hidden={activeKey !== "0"}/>
      <Schedule hidden={activeKey !== "2"} />
      <Prices hidden={activeKey !== "3"} />
      <Typepay hidden={activeKey !== "4"} />
    </Container>
  );
}
