import dayjs from "dayjs";
import React, { useState } from "react";
import { Col, Modal, Row, Table } from "react-bootstrap";
import { IMessage, Message } from "../../../../../components/message";
import { AppointmentUpcoming } from "../../../../../enum/appointment.enum";
import {
  updateAppointmentsStatus,
  useAppointment,
  useAppointmentsUpcoming,
} from "../../../../../services/appointment";
import { usePanelPage } from "../../../panelPage";
import ScheduleAppointment from "../../scheduleappointment/ScheduleAppointment";

function UpcomingAppointments(): JSX.Element {
  const { specialistSelected } = usePanelPage();

  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  const [refetch, setRefetch] = useState<number>(1);
  const [appointmentId, setAppointmentId] = useState<number | null>(null);

  const { groupedAppointments, loading } = useAppointmentsUpcoming({
    specialistSelected,
    refetch,
  });

  const { appointment, isOpenModal, setIsOpenModal } =
    useAppointment(appointmentId);

  async function handleDidNotAssist(appointment_id: number) {
    handleUpdate(appointment_id, AppointmentUpcoming.DID_NOT_ASSIST_STATUS);
  }

  async function handleAttended(appointment_id: number) {
    handleUpdate(appointment_id, AppointmentUpcoming.ATTENDED_STATUS);
  }

  async function handleUpdate(
    appointment_id: number,
    appointments_status: number
  ) {
    updateAppointmentsStatus({ appointment_id, appointments_status }).then(
      () => {
        let message = "";
        if (appointments_status === AppointmentUpcoming.DID_NOT_ASSIST_STATUS)
          message = AppointmentUpcoming.DID_NOT_ASSIST;
        else message = AppointmentUpcoming.ATTENDED;
        setMessage({
          show: true,
          message,
        });
        setRefetch((prev) => (prev += 1));
      }
    );
  }

  return (
    <>
      <section className="mt-4 colpanel p-3">
        <Row>
          <Col>
            <h6>
              <i className="bi bi-calendar-week m-2" /> Próximas citas
            </h6>
          </Col>
        </Row>
        <Table responsive hover>
          <tbody>
            {loading && (
              <tr>
                <td
                  colSpan={6}
                  style={{ fontWeight: "bold", textAlign: "center" }}
                >
                  Cargando...
                </td>
              </tr>
            )}
            {Object.entries(groupedAppointments).map(([date, appointments]) => (
              <React.Fragment key={date}>
                <tr>
                  <td colSpan={6} style={{ fontWeight: "bold" }}>
                    {`${date}:`}
                  </td>
                </tr>
                {appointments.map(
                  ({
                    appointment_id,
                    first_name,
                    last_name,
                    start_datetimeFormated,
                    payment_method_id,
                  }) => (
                    <tr key={appointment_id}>
                      <td>
                        <span
                          style={{
                            display: "block",
                            height: "12px",
                            width: "12px",
                            background: `${
                              payment_method_id === AppointmentUpcoming.ON_SITE
                                ? "#C53DE8"
                                : "#0B435B"
                            }`,
                            borderRadius: "50px",
                          }}
                        />
                      </td>
                      <td>{`${first_name} ${last_name}`}</td>
                      <td>{`${start_datetimeFormated}`}</td>
                      <td>
                        <i
                          className="bi bi-pencil-fill icon-button"
                          title="Editar"
                          onClick={() => setAppointmentId(appointment_id)}
                        />
                      </td>
                      <td>
                        <i
                          className="bi bi-x-circle-fill icon-button"
                          title="No asistio"
                          onClick={() => handleDidNotAssist(appointment_id)}
                        />
                      </td>
                      <td>
                        <i
                          className="bi bi-check-circle-fill icon-button"
                          title="Asistio"
                          onClick={() => handleAttended(appointment_id)}
                        />
                      </td>
                    </tr>
                  )
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            xxl={4}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  height: "12px",
                  width: "12px",
                  background: "#0B435B",
                  borderRadius: "50px",
                }}
              />
              <p className="mb-0 mx-1"> Pago Inmed </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  height: "12px",
                  width: "12px",
                  background: "#C53DE8",
                  borderRadius: "50px",
                }}
              />
              <p className="mb-0 mx-1"> Pago Sitio </p>
            </div>
          </Col>
          <Col
            xs={8}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            xxl={8}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <span
              className="bi bi-pencil-fill"
              title="Editar"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p className="mb-0 mx-1"> Editar </p>
            </span>
            <span
              className="bi bi-x-circle-fill"
              title="No asistio"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p className="mb-0 mx-1">No asistio</p>
            </span>
            <span
              className="bi bi-check-circle-fill"
              title="Confirmar asistencia"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p className="mb-0 mx-1">Confirmar asistencia</p>
            </span>
          </Col>
        </Row>
      </section>
      {message?.show && (
        <Message
          show={message?.show}
          message={message?.message}
          hide={() => setMessage((prev) => ({ ...prev, show: false }))}
        />
      )}

      {isOpenModal && (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          show={isOpenModal}
          onHide={() => {
            setIsOpenModal(false);
            setAppointmentId(null);
          }}
          className="modalAppointment"
        >
          <Modal.Header closeButton>
            <Modal.Title>Información de la cita</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ScheduleAppointment
              appointmentId={appointmentId}
              handleAction={(action: any, message: any) => {
                setIsOpenModal(false);
                setAppointmentId(null);
                setRefetch((prev) => (prev += 1));
                             if (message !== "" && message!==undefined) {
                  setMessage({
                    show: true,
                    message,
                  });
                }
              }}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default UpcomingAppointments;
