import { useEffect, useState } from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import { IMessage, Message } from "../../../components/message";
import HttpService from "../../../services/http";
import {
  isNotification,
  useFetchNotifications,
} from "../../../services/notification";
import { Notification as NotificationType } from "../../../types/notification";
import { ModalAppointmentPending } from "../pages/home/components/pendingAppointment";
import ScheduleAppointment from "../pages/scheduleappointment/ScheduleAppointment";
import { usePanelPage } from "../panelPage";

function NotificationItem(notification: NotificationType): JSX.Element {
  const { specialistSelected } = usePanelPage();

  const [appointmentId, setAppointmentId] = useState<number | null>(null);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const [isModalPending, setIsModalPending] = useState<boolean>(false);
  const [isModalCalendar, setIsModalCalendar] = useState<boolean>(false);

  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  useEffect(() => {
    verifyToShow();
  }, [appointmentId]);

  useEffect(() => {
    if (!isModalCalendar || !isModalPending) setAppointmentId(null);
  }, [isModalCalendar, isModalPending]);

  function readNotification() {
    HttpService.post("notification", { id: notification.id })
      .then((result) => setIsHidden(result?.status))
      .catch(() => setIsHidden(false));
  }

  function verifyToShow() {
    if (!appointmentId) return;

    isNotification(notification.id).then((result) => {
      if (result.status) {
        const data = result?.data as { appointments_status: number };
        const { appointments_status } = data;
        if (appointments_status === 22) setIsModalPending(true);
        else if (appointments_status === 27) setIsModalCalendar(true);
        else {
          setMessage({
            show: true,
            message: "La notificación ya fue revisada.",
          });
          readNotification();
        }
      } else setAppointmentId(null);
    });
  }

  return (
    <>
      <div className="item-notification" hidden={isHidden}>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <label className="title-notification flex-grow-1">
            {notification?.notification?.title}
          </label>
          <button
            className="close-notification ml-auto"
            title="Quitar notificación"
            onClick={readNotification}
          >
            <i className="bi bi-x" />
          </button>
        </section>
        <section style={{ display: "flex", flexDirection: "column" }}>
          <label className="p-2">{notification?.notification?.message}</label>
          <label
            style={{ fontSize: "10px", fontStyle: "italic" }}
            onClick={() =>
              setAppointmentId(
                notification?.notification?.appointment_id ?? null
              )
            }
          >
            <i className="bi bi-info-circle"></i> Dar clic aquí para más
            información
          </label>
        </section>
      </div>

      {message?.show && (
        <Message
          show={message?.show}
          message={message?.message}
          hide={() => setMessage((prev) => ({ ...prev, show: false }))}
        />
      )}

      {isModalPending && (
        <ModalAppointmentPending
          appointmentId={appointmentId}
          showModal={isModalPending}
          setShowModal={setIsModalPending}
          setMessage={setMessage}
          onHide={() => {
            setAppointmentId(null);
          }}
        />
      )}

      {isModalCalendar && (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          show={isModalCalendar}
          onHide={() => {
            setIsModalCalendar(false);
            setAppointmentId(null);
          }}
          className="modalAppointment"
        >
          <Modal.Header closeButton>
            <Modal.Title>Información de la cita</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ScheduleAppointment
              appointmentId={appointmentId}
              handleAction={(action: any, message: any) => {
                setIsModalCalendar(false);
                setAppointmentId(null);
              }}
              hideFotter={specialistSelected == null}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default function Notification(): JSX.Element {
  const { isShowNotifications, setIsShowNotifications, setCountNotifications } =
    usePanelPage();
  const { notifications } = useFetchNotifications();

  useEffect(() => {
    setCountNotifications(notifications?.length ?? 0);
  }, [notifications]);

  return (
    <Offcanvas
      show={isShowNotifications}
      onHide={() => setIsShowNotifications(false)}
      placement="end"
      scroll
    >
      <Offcanvas.Header
        className="header-notification"
        closeButton
        closeVariant="black"
      >
        <i className="bi bi-bell-fill"></i> Notificaciones
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        {notifications?.map((notification) => (
          <NotificationItem key={notification.id} {...notification} />
        ))}
      </Offcanvas.Body>
    </Offcanvas>
  );
}
