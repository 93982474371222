import { useContext } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { DAYS_OF_WEEK, ScheduleContext } from "../../schedule";
import { IWorkingDays } from "../../../../../../../../interfaces/schedule/schedule.interface";
import dayjs from "dayjs";

export default function WorkingDays(): JSX.Element {
  const {
    workingDays,
    setWorkingDays,
  }: {
    workingDays: IWorkingDays[];
    setWorkingDays: React.Dispatch<React.SetStateAction<IWorkingDays[]>>;
  } = useContext(ScheduleContext);

  function handleChangeStartTime(indexDay: number, valueTime: string) {
    const copyWorkingDays: IWorkingDays[] = JSON.parse(
      JSON.stringify(workingDays)
    );
    const workingDay = copyWorkingDays?.find(
      ({ dayOfWeek }) => dayOfWeek === indexDay
    );
    const time = dayjs(valueTime, "HH:mm");
    const timeHours = time?.get("hour")?.toString()?.padStart(2, "0");
    const timeMinutes = time?.get("minute")?.toString()?.padStart(2, "0");
    const startTime = `${timeHours}:${timeMinutes}:00`;
    if (!workingDay)
      copyWorkingDays.push({ dayOfWeek: indexDay, startTime, endTime: "" });
    else workingDay.startTime = startTime;
    setWorkingDays(copyWorkingDays);
  }

  function handleChangeEndTime(indexDay: number, valueTime: string) {
    const copyWorkingDays: IWorkingDays[] = JSON.parse(
      JSON.stringify(workingDays)
    );
    const workingDay = copyWorkingDays?.find(
      ({ dayOfWeek }) => dayOfWeek === indexDay
    );
    const time = dayjs(valueTime, "HH:mm");
    const timeHours = time?.get("hour")?.toString()?.padStart(2, "0");
    const timeMinutes = time?.get("minute")?.toString()?.padStart(2, "0");
    const endTime = `${timeHours}:${timeMinutes}:00`;
    if (!workingDay)
      copyWorkingDays.push({ dayOfWeek: indexDay, startTime: "", endTime });
    else workingDay.endTime = endTime;
    setWorkingDays(copyWorkingDays);
  }

  function handleClearStartTime(indexDay: number) {
    const copyWorkingDays: IWorkingDays[] = JSON.parse(
      JSON.stringify(workingDays)
    );
    const workingDay = copyWorkingDays?.find(
      ({ dayOfWeek }) => dayOfWeek === indexDay
    );
    if (workingDay) {
      workingDay.startTime = "";
      if (workingDay?.endTime === "")
        setWorkingDays(
          copyWorkingDays?.filter(({ dayOfWeek }) => dayOfWeek !== indexDay)
        );
      else setWorkingDays(copyWorkingDays);
    }
  }

  function handleClearEndTime(indexDay: number) {
    const copyWorkingDays: IWorkingDays[] = JSON.parse(
      JSON.stringify(workingDays)
    );
    const workingDay = copyWorkingDays?.find(
      ({ dayOfWeek }) => dayOfWeek === indexDay
    );
    if (workingDay) {
      workingDay.endTime = "";
      if (workingDay?.startTime === "")
        setWorkingDays(
          copyWorkingDays?.filter(({ dayOfWeek }) => dayOfWeek !== indexDay)
        );
      else setWorkingDays(copyWorkingDays);
    }
  }

  return (
    <Container>
      <h6>Horario de trabajo</h6>
      <Table responsive bordered  className="tableschedule">
        <thead>
          <tr>
            <th></th>
            {DAYS_OF_WEEK?.map((day, indexDay) => (
              <th className="title" key={`${day}-head-${indexDay}`}>
                {day}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          <tr>
            <th className="title"> Hora de inicio</th>
            {DAYS_OF_WEEK?.map((day, indexDay) => (
              <th key={`${day}-startTime-${indexDay}`}>
                <div className="input-wrapper">
                  <Form.Control
                    className="pr-2"
                    type="time"
                    value={
                      workingDays?.some(
                        ({ dayOfWeek }) => dayOfWeek === indexDay
                      )
                        ? workingDays?.find(
                            ({ dayOfWeek }) => dayOfWeek === indexDay
                          )?.startTime || ""
                        : ""
                    }
                    onChange={(event) =>
                      handleChangeStartTime(indexDay, event.target.value)
                    }
                  />
                  <button
                    title="Limpiar hora"
                    className="btnclean"
                    onClick={() => handleClearStartTime(indexDay)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </button>
                </div>
              </th>
            ))}
          </tr>
          <tr>
            <th  className="title"> Hora de Fin</th>
            {DAYS_OF_WEEK?.map((day, indexDay) => (
              <th key={`${day}-endTime-${indexDay}`}>
                <div className="input-wrapper">
                  <Form.Control
                    type="time"
                    value={
                      workingDays?.some(
                        ({ dayOfWeek }) => dayOfWeek === indexDay
                      )
                        ? workingDays?.find(
                            ({ dayOfWeek }) => dayOfWeek === indexDay
                          )?.endTime || ""
                        : ""
                    }
                    onChange={(event) =>
                      handleChangeEndTime(indexDay, event.target.value)
                    }
                  />
                  <button
                    title="Limpiar hora"
                    className="btnclean"
                    onClick={() => handleClearEndTime(indexDay)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </button>
                </div>
              </th>
            ))}
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}
