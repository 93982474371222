import { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { IMessage, Message } from "../../../../../../components/message";
import { useAuth } from "../../../../../../middlewares/auth";
import HttpService from "../../../../../../services/http";
import { usePanelPage } from "../../../../panelPage";
import useFetchAppointmentTypeSpecialist from "./hooks/useFetchAppointmentTypeSpecialist";
import ModalAppointment from "./modalAppointment";

interface PricesProps {
  hidden: boolean;
}

export default function Prices({ hidden }: PricesProps): JSX.Element {
  const { state } = useAuth();

  const { specialistSelected } = usePanelPage();

  const [idSpecialist, setIdSpecialist] = useState<number | null | undefined>(
    state?.specialist ? state?.id : state?.secretary ? specialistSelected : null
  );

  useEffect(() => {
    setIdSpecialist(
      state?.specialist
        ? state?.id
        : state?.secretary
        ? specialistSelected
        : null
    );
  }, [state?.specialist, state?.secretary, specialistSelected]);

  const [refresh, setRefresh] = useState<boolean>(false);

  const { appointmentTypesSpecialist } = useFetchAppointmentTypeSpecialist(
    idSpecialist,
    refresh
  );

  const [showModal, setShowModal] = useState<boolean>(false);
  const [id, setId] = useState<number | null>(null);

  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  useEffect(() => {
    if (!showModal) setRefresh((prev) => !prev);
  }, [showModal]);

  function handleAdd() {
    setId(null);
    setShowModal(true);
  }

  function handleEdit(id: number) {
    setId(id);
    setShowModal(true);
  }

  function handleDelete(id: number) {
    HttpService.post("specialists/deleteTypeAppointment", {
      specialist_id: idSpecialist,
      id,
    })
      .then((result) => {
        if (result?.status) {
          setMessage({ show: true, message: "Se ha eliminado correctamente." });
          setRefresh((prev) => !prev);
        } else
          setMessage({
            show: false,
            message: "No se pudo eliminar el registro.",
          });
      })
      .catch(() => {
        setMessage({
          show: false,
          message: "No se pudo eliminar el registro.",
        });
      });
  }

  const handleClose = () => {
    setId(null);
    setShowModal(false);
  };

  return (
    <>
      <Container hidden={hidden}>
        <div className="contain_add">
          <h6>Adicionar Cita</h6>
          <button title="Agregar cita" className="btnadd" onClick={handleAdd}>
            <i className="bi bi-node-plus-fill" />
          </button>
        </div>
        <Table responsive bordered className="tableschedule">
          <thead>
            <tr>
              <th>Tipo de cita</th>
              <th>Tiempo (minutos)</th>
              <th>Precio</th>
              <th>Moneda</th>
              <th>Cita principal</th>
              <th>Editar</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {appointmentTypesSpecialist?.map(
              ({
                id,
                appointments_type_id,
                duration,
                price,
                currencys_type,
                principal_appointment,
              }) => (
                <tr key={id}>
                  <td>
                    {principal_appointment ? (
                      <i
                        title="Esta cita es la principal, se usa para las citas por el sitio web inmed."
                        className="bi bi-star-fill fill-primary"
                      />
                    ) : null}
                    {appointments_type_id?.name}
                  </td>
                  <td>{duration}</td>
                  <td>{price}</td>
                  <td>{currencys_type?.name}</td>
                  <td>{principal_appointment ? "Sí" : "No"}</td>
                  <td>
                    <button
                      className="btndelete"
                      title="Editar cita"
                      disabled={showModal}
                      onClick={() => handleEdit(id)}
                    >
                      <i className="bi bi-pencil" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="btndelete"
                      title="Eliminar cita"
                      disabled={showModal}
                      onClick={() => handleDelete(id)}
                    >
                      <i className="bi bi-trash3" />
                    </button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </Container>

      <ModalAppointment
        showModal={showModal}
        id={id}
        handleClose={handleClose}
      />

      {message?.show ? (
        <Message
          show={message?.show}
          message={message?.message}
          hide={() => setMessage((prev) => ({ ...prev, show: false }))}
        />
      ) : null}
    </>
  );
}
