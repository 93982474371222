import { useEffect, useState } from "react";
import HttpService from "../../../../../../../services/http";

export interface IOptions {
  id: number;
  name: string;
  id_origin:number
}

export default function useFetchGetListProcedure() {
  const [procedures, setProcedures] = useState<IOptions[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    HttpService.get("procedures/getListProcedure")
      .then((result) => {
        const fetchedProcedures: IOptions[] = result?.data.map((option: any) => ({
          id: option,
          name: option.procedure_type?option.procedureType.name:option.name,
          id_origin:option.procedure_type,
        }));
        const data = result?.data as IOptions[];
        setProcedures(data);
      })
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  }, []);

  return { procedures, loading };
}
