import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Figure,
  Form,
  InputGroup,
  OverlayTrigger,
  Pagination,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../hooks/useDebounce";
import { useFetchProceduresTypes } from "../../hooks/useFetchProceduresTypes";
import { Meta } from "../../types/http";
import { useFetchProcedures } from "./hooks/useFetchProcedures";
import { useFetchSearch } from "./hooks/useFetchSearch";
import css from "./procedures.module.css";
import { useTranslation } from "react-i18next";

function Procedurespage(): JSX.Element {
  const navigate = useNavigate();

  const [filterTypes, setFilterTypes] = useState<number[]>([]);
  const [filterAsc, setFilterAsc] = useState<boolean>(true);

  const [filterName, setFilterName] = useState<string>("");
  const debouncedFilterName = useDebounce(filterName);

  const { procedures: autoProcedures, loading: loadingAutoProcedures } =
    useFetchSearch(debouncedFilterName);
  const { i18n, t } = useTranslation();
  const [filterPage, setFilterPage] = useState<number>(1);

  const [search, setSearch] = useState<number>(1);
  const [showTooltip, setShowTooltip] = useState(false);
  useEffect(() => setFilterPage(1), [filterTypes, filterAsc, filterName]);

  function FooterPagination(props: Meta): JSX.Element {
    let lastPage = props.last_page <= 5 ? props.last_page : 5;
    let firstPage = 1;

    if (props.current_page > 3) {
      lastPage = props.current_page + 2;
      if (lastPage > props.last_page) {
        const difference = lastPage - props.last_page;
        firstPage = props.current_page - 2 - difference;
        lastPage = props.last_page;
      } else firstPage = props.current_page - 2;
    }

    const itemPages = [];
    firstPage = firstPage === 0 ? 1 : firstPage;

    if (firstPage > props.first_page)
      itemPages.push(<Pagination.Ellipsis disabled />);

    for (let iterator = firstPage; iterator <= lastPage; iterator++) {
      itemPages.push(
        <Pagination.Item
          key={`${new Date().getTime()}_${iterator}`}
          active={props.current_page === iterator}
          onClick={() => setFilterPage(iterator)}
        >
          {iterator}
        </Pagination.Item>
      );
    }

    if (lastPage < props.last_page)
      itemPages.push(<Pagination.Ellipsis disabled />);

    return (
      <Row>
        <Pagination>
          <Pagination.First onClick={() => setFilterPage(1)} />
          <Pagination.Prev
            onClick={() => setFilterPage(filterPage > 1 ? filterPage - 1 : 1)}
          />

          {itemPages.length > 0 && itemPages}

          <Pagination.Next
            onClick={() =>
              setFilterPage(
                filterPage < props.last_page ? filterPage + 1 : props.last_page
              )
            }
          />
          <Pagination.Last onClick={() => setFilterPage(props.last_page)} />
        </Pagination>
      </Row>
    );
  }

  const { types, loading: loadingTypes } = useFetchProceduresTypes();
  const {
    procedures,
    meta,
    loading: loadingProcedures,
  } = useFetchProcedures(
    search,
    debouncedFilterName,
    filterTypes.join(","),
    filterAsc ? "asc" : "desc",
    filterPage,
    12
  );

  return (
    <Container fluid className="p-5">
    
      <Row>

        <Col className="procedure-filter" xs={6} sm={4} md={4} lg={3} xl={3} xxl={3}>
          <Row className={css.filter}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              
              <Form.Label className="procedure-w-title" title={filterAsc ? 'Ordenar de Z a A' : 'Ordenar de A a Z'} onClick={() => setFilterAsc(!filterAsc)}>
                {t("sortBy")} {filterAsc ? <i className="bi bi-sort-alpha-down"></i> : <i className="bi bi-sort-alpha-down-alt"></i>}

              </Form.Label>
              <hr></hr>
            </Col>

            <h6>Filtros</h6>

            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <label className="procedure-w-title">Tipos procedimientos</label>
              {types.map((type) => (
                <InputGroup key={type.id} className={css.inputgroup}>
                  <InputGroup.Checkbox
                    checked={
                      filterTypes.findIndex((value) => value === type.id) !== -1
                    }
                    onChange={(e: { target: { checked: boolean } }) => {
                      const checked = e.target.checked;
                      const copy = [...filterTypes];
                      if (checked) copy.push(type.id);
                      else
                        copy.splice(
                          copy.findIndex((value) => value === type.id),
                          1
                        );
                      setFilterTypes(copy);
                    }}
                  />
                  <Form.Label>{type.name}</Form.Label>
                </InputGroup>
              ))}
            </Col>
          </Row>
        </Col>
        <Col xs={6} sm={8} md={8} lg={9} xl={9} xxl={9}>
          <Row>
            <Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6} >
            </Col>
            <Col xs={10} sm={10} md={5} lg={5} xl={5} xxl={5} >
              <Autocomplete
                options={autoProcedures.map(({ name }) => name)}
                inputValue={filterName}
                onInputChange={(event, value) => setFilterName(value)}
                loading={loadingAutoProcedures}
                freeSolo
                className="autocompleteinmed"
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("procedure")}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                      endAdornment: (
                        <Fragment>
                          {loadingAutoProcedures ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
                style={{ width: "100%", minWidth: "0" }}
              />
              </Col>
              <Col xs={2} sm={2} md={1} lg={1} xl={1} xxl={1} >
                 <Button className={css.buttonse} style={{"minWidth":"30px"}} onClick={() => setSearch((prev) => prev + 1)}><i className="bi bi-search"></i>
</Button>
            </Col>
          </Row>
          <br />
          <Row>
            {procedures.map((procedure) => (
              <Col
                key={procedure?.id}
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={4}
                xxl={4}
                style={{"textAlign": "center"}}
              >
                <Figure className={css.figure} onClick={() =>
                  navigate(`/procedure/${procedure.id}`, {
                    preventScrollReset: true,
                  })}>
                  <div className={css.background}></div>
                  <Figure.Image
                    className={css.img}
                    src={procedure.image || ""}
                    loading="lazy"
                    title="Ver"
                  />
                  <Figure.Caption className={css.caption}>
                    {procedure.name}
                  </Figure.Caption>

                </Figure>
              </Col>
            ))}
          </Row>
          {!loadingTypes && !loadingProcedures && meta?.last_page > 1 && (
        <FooterPagination {...meta} />
      )}
        </Col>
      </Row>
     
    </Container>
  );
}

export default Procedurespage;
