import { Col, Container, Form, Row } from "react-bootstrap";

import css from "./footer.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer(): JSX.Element {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation()
  return (
    <Container className={css.container} fluid={true}>
      <Container>
        <Row>
          <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className={css.panel}>
            <Row className={css.rowFooter}>
              <Form.Label
                className={css.aboutUs}
                onClick={() => navigate("/about-us")}
              >
                <i className={`${css.icon} bi-people-fill`} />
               {t('aboutUs')}
              </Form.Label>
              <Form.Label
                className={css.termsAndConditions}
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_TERMINOS_Y_CONDICIONES,
                    "_blank"
                  )
                }
              >
                <i className={`${css.icon} bi-pencil-square`} />
               {t('termsAndConditions')}
              </Form.Label>
              <Form.Label
                className={css.privacyPolicy}
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_POLITICA_PRIVACIDAD,
                    "_blank"
                  )
                }
              >
                <i className={`${css.icon} bi-file-text`} />
                {t('termsConditions')}
              </Form.Label>
            </Row>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className={css.panel}>
            <Row>
              <Form.Label className={css.ourNetworks}>
                {t('ourSocialMedia')}
              </Form.Label>
              <div>
                <a
                  href="https://instagram.com/inmed.co"
                  className={css.redSocial}
                  title="Seguir en Instagram"
                  target="_blank"
                >
                  <span className="bi bi-instagram" aria-hidden="true" />
                </a>
                <a
                  href="https://www.facebook.com/lnmed.co/"
                  className={css.redSocial}
                  title="Seguir en Facebook"
                  target="_blank"
                >
                  <span className="bi bi-facebook" aria-hidden="true" />
                </a>
                <a
                  href="https://twitter.com/inmed_co"
                  className={css.redSocial}
                  title="Seguir en Twitter"
                  target="_blank"
                >
                  <span className="bi bi-twitter" aria-hidden="true" />
                </a>
                <a
                  href="https://youtube.com/@inmed.colombia?si=U0vDDGIYu-TznLT4"
                  className={css.redSocial}
                  title="Seguir en Youtube"
                  target="_blank"
                >
                  <span className="bi bi-youtube" aria-hidden="true" />
                </a>
                <a
                  href=" https://api.whatsapp.com/send/?phone=%2B573007416631&text=Hola%20quiero%20agendar%20una%20cita"
                  className={css.redSocial}
                  title="Escribenos al whatsapp"
                  target="_blank"
                >
                  <span className="bi bi-whatsapp" aria-hidden="true" />
                </a>
               
              </div>
            </Row>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className={css.panel}>
            <Row className={css.payU} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Footer;
