import {
  Autocomplete,
  Checkbox,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

interface Option {
  id: number;
  name: string;
  id_origin:number
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectOptions = ({ data, list,setData}: any) => {
  const [usersOptions, setUsersOptions] = useState<Option[]>([]);

  useEffect(() => {
    setUsersOptions(list);
  }, [list]);

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={usersOptions}
      getOptionLabel={(option) => option.name}
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField {...params} label="" placeholder="" />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      value={data}
      onChange={(event, newValue) => {
        setData(newValue);
      }}
      // Add These props
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(options, params) => {
        const filtered = createFilterOptions()(options, params);
        const inputValue = params.inputValue.trim().toLowerCase();

        // Verificar si la opción escrita por el usuario ya existe en la lista de opciones
        const optionExists = options.some(
          (option) => option.name.toLowerCase() === inputValue
        );

        // Si la opción no existe, agregarla a la lista filtrada
        if (inputValue && !optionExists) {
          let countNew =
            (data !== undefined ? data.length + 1 : 0) * -1;

          return [{ id: countNew, name: inputValue, id_origin:-1}, ...filtered];
        }

        return filtered;
      }}
    />
  );
};

export default SelectOptions;
