import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { IWarning, Message } from "../../components/message";
import { LOGIN_MODE } from "../../interfaces/user/user.interface";
import { useAuth } from "../../middlewares/auth";
import HttpService from "../../services/http";
import { ACTION_MODE } from "./login.enum";
import { IPopMessageRecover } from "./login.interface";
import css from "./login.module.css";
import { useTranslation } from "react-i18next";

function PopMessageRecover({
  show,
  onHide,
  email,
}: IPopMessageRecover): JSX.Element {
  const { i18n, t } = useTranslation()
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton />
      <Modal.Body>{t('dataSentMail')}Sus datos se han enviado al correo {email}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('Accept')}</Button>
      </Modal.Footer>
    </Modal>
  );
}

function ByPatient(): JSX.Element {
  const { state: stateLocation } = useLocation();
  const { i18n, t } = useTranslation()
  const { logIn } = useAuth();

  const [mode, setMode] = useState<number>(ACTION_MODE.LOGIN);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [popMessageRecover, setPopMessageRecover] = useState<boolean>(false);

  const [sending, setSending] = useState<boolean>(false);
  const [warning, setWarning] = useState<IWarning>({
    show: false,
    message: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    setUsername("");
    setPassword("");
    setEmail("");
  }, [mode]);

  function recoverPassword() {
    HttpService.get(`password/reset?email=${email}`)
      .then((result) => {
        if (result.status) setPopMessageRecover(true);
        else setWarning({ show: true, message: result?.message });
      })
      .catch(() =>
        setWarning({
          show: true,
          message: t('pwNotRecovery'),
        })
      );
  }

  function validateLogIn() {
    setSending(true);
    HttpService.post("login", {
      email: username,
      password,
      method: ACTION_MODE.PATIENT,
    })
      .then((result) => {
        if (result?.status) {
          logIn({
            isLoged: true,
            xToken: result?.data?.token ?? "",
          });
          if (stateLocation?.redirect)
            navigate(
              stateLocation?.redirect?.url,
              stateLocation?.redirect?.props
            );
        } else
          setWarning({
            show: true,
            message: t('userPwFail'),
          });
        setSending(false);
      })
      .catch(() => {
        setSending(false);
      });
  }
 function handlekey(event:any){
  if (event.key === 'Enter') {
    validateLogIn()
  }
  
 }
  return (
    <>
      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} className={css.container}>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
            {mode === ACTION_MODE.LOGIN && (
              <>
                <Row>
                  <label className={`${css.title} mt-0`}>{t('login')}</label>
                </Row>
                <Row>
                  <FloatingLabel label={t('user')} className={css.label_login}>
                    <Form.Control
                      type="text"
                      placeholder={t('user')} 
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      disabled={sending}
                      className={css.input_login}
                    />
                  </FloatingLabel>
                </Row>
                <Row>
                  <FloatingLabel label={t('password')} className={css.label_login}>
                    <Form.Control
                      type="password"
                      placeholder={t('password')}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={sending}
                      className={css.input_login}
                      onKeyDown={handlekey}
                    />
                  </FloatingLabel>
                </Row>
                <Row>
                  <Col
                    xs={{ offset: 4, span: 4 }}
                    sm={{ offset: 4, span: 4 }}
                    md={{ offset: 4, span: 4 }}
                    lg={{ offset: 4, span: 4 }}
                    xl={{ offset: 4, span: 4 }}
                    xxl={{ offset: 4, span: 4 }}
                  >
                    <Button disabled={sending} onClick={validateLogIn}>
                      {t('login')}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={{ offset: 3, span: 6 }}
                    sm={{ offset: 3, span: 6 }}
                    md={{ offset: 3, span: 12 }}
                    lg={{ offset: 3, span: 12 }}
                    xl={{ offset: 3, span: 12 }}
                    xxl={{ offset: 3, span: 12 }}
                  >
                    {sending ? (
                      <label className={css["lg-label"]}>
                        {t('forgotPassword')}
                      </label>
                    ) : (
                      <label
                        className={css["lg-label"]}
                        onClick={() => setMode(ACTION_MODE.RECOVER_PASSWORD)}
                      >
                        {t('forgotPassword')}
                      </label>
                    )}
                  </Col>
                </Row>
              </>
            )}
            {mode === ACTION_MODE.RECOVER_PASSWORD && (
              <>
                {sending ? (
                  <label>{t('back')}</label>
                ) : (
                  <label
                    className={css["back"]}
                    onClick={() => setMode(ACTION_MODE.LOGIN)}
                  />
                )}

                <Row>
                  <label className={css.title}>{t('recoveryPassword')}</label>
                  <label className={css["lg-label-2"]}>
                    {t('messageLogin')}
                  </label>
                </Row>
                <Row>
                  <FloatingLabel
                    label={t('email')}
                    className={css.label_login}
                  >
                    <Form.Control
                      type="email"
                      placeholder={t('email')}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={sending}
                      className={css.input_correo}
                    />
                  </FloatingLabel>
                </Row>
                <Row>
                  <Col
                    xs={{ offset: 4, span: 4 }}
                    sm={{ offset: 4, span: 4 }}
                    md={{ offset: 4, span: 4 }}
                    lg={{ offset: 4, span: 4 }}
                    xl={{ offset: 4, span: 4 }}
                    xxl={{ offset: 4, span: 4 }}
                  >
                    <Button onClick={recoverPassword} disabled={sending}>
                      {t('send')}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <Col
            className={css.register}
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
          >
            <Row>
              <label className={css.registertitle}>{t('createAccount')}</label>
              <label>{t('joinCommunity')}</label>
            </Row>
            <Row>
              <label className={css["calendar"]}>
               {t('scheduleAppointment')}
              </label>
              <label className={css["dolar"]}>
                {t('payAppointment')}
              </label>
              <label className={css["table"]}>
                {t('accessWaitingList')}
              </label>
              <label className={css["subtract"]}>
                {t('rateExperience')}
              </label>
            </Row>
            <Row>
              <Col
                xs={{ offset: 4, span: 4 }}
                sm={{ offset: 4, span: 4 }}
                md={{ offset: 4, span: 4 }}
                lg={{ offset: 4, span: 4 }}
                xl={{ offset: 4, span: 4 }}
                xxl={{ offset: 4, span: 4 }}
              >
                <Button
                  onClick={() => {
                    if (stateLocation?.redirect)
                      navigate(`/register-patient`, { state: stateLocation });
                    else navigate(`/register-patient`);
                  }}
                  disabled={sending}
                >
                  {t('signUp')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      {popMessageRecover && (
        <PopMessageRecover
          show={popMessageRecover}
          onHide={() => setPopMessageRecover(false)}
          email={email}
        />
      )}
      {warning?.show && (
        <Message
          show={warning?.show}
          message={warning?.message}
          hide={() => setWarning({ show: false })}
        />
      )}
    </>
  );
}

function BySpecialist(): JSX.Element {
  const { logIn } = useAuth();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation()
  const [loginMode, setLoginMode] = useState<number>(LOGIN_MODE.SPECIALIST);
  const [mode, setMode] = useState<number>(ACTION_MODE.LOGIN);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [popMessageRecover, setPopMessageRecover] = useState<boolean>(false);

  const [sending, setSending] = useState<boolean>(false);
  const [warning, setWarning] = useState<IWarning>({
    show: false,
    message: "",
  });

  useEffect(() => {
    setUsername("");
    setPassword("");
    setEmail("");
  }, []);

  function recoverPassword() {
    HttpService.get(`password/reset?email=${email}`)
      .then((result) => {
        if (result.status) setPopMessageRecover(true);
        else setWarning({ show: true, message: result?.message });
      })
      .catch(() =>
        setWarning({
          show: true,
          message: t('pwNotRecovery'),
        })
      );
  }

  function validateLogIn() {
    setSending(true);
    HttpService.post("login", {
      email: username,
      password,
      method: ACTION_MODE.SPECIALIST_SECRETARY,
    })
      .then((result) => {
        if (result?.status) {
          logIn({
            isLoged: true,
            xToken: result?.data?.token ?? "",
          });
          navigate("/panel");
        } else
          setWarning({
            show: true,
            message: t('userPwFail'),
          });
        setSending(false);
      })
      .catch(() => {
        setSending(false);
      });
  }
  function handlekey(event:any){
    if (event.key === 'Enter') {
      validateLogIn()
    }
    
   }
  return (
    <>
      <Col
        xs={{ offset: 4, span: 8 }}
        sm={{ offset: 4, span: 8 }}
        md={{ offset: 4, span: 8 }}
        lg={{ offset: 4, span: 8 }}
        xl={{ offset: 4, span: 8 }}
        xxl={{ offset: 4, span: 8 }}
        className={css.containeres}
      >
        <Row>
          <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
            {mode === ACTION_MODE.LOGIN ? (
              <>
                <Row>
                  <label className={`${css.title} mt-0`}>{t('login')}</label>
                </Row>
                <Row style={{ display: "none" }}>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className={`d-flex justify-content-center ${
                      loginMode === LOGIN_MODE.SPECIALIST && "active"
                    }`}
                  >
                    <span onClick={() => setLoginMode(LOGIN_MODE.SPECIALIST)}>
                      {t('specialist')}
                    </span>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className={`d-flex justify-content-center ${
                      loginMode === LOGIN_MODE.SECRETARY && "active"
                    }`}
                  >
                    <span onClick={() => setLoginMode(LOGIN_MODE.SECRETARY)}>
                      {t('secretary')}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <FloatingLabel label={t('email')} className={css.label_login}>
                    <Form.Control
                      type="email"
                      placeholder={t('email')}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      disabled={sending}
                      className={css.input_login}
                    />
                  </FloatingLabel>
                </Row>
                <Row>
                  <FloatingLabel label={t('password')} className={css.label_login}>
                    <Form.Control
                      type="password"
                      placeholder={t('password')}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={sending}
                      className={css.input_login}
                      onKeyDown={handlekey}
                    />
                  </FloatingLabel>
                </Row>
                <Row>
                  <Col
                    xs={{ offset: 4, span: 4 }}
                    sm={{ offset: 4, span: 4 }}
                    md={{ offset: 4, span: 4 }}
                    lg={{ offset: 4, span: 4 }}
                    xl={{ offset: 4, span: 4 }}
                    xxl={{ offset: 4, span: 4 }}
                  >
                    <Button disabled={sending} onClick={validateLogIn}>
                      {t('login')}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={{ offset: 3, span: 6 }}
                    sm={{ offset: 3, span: 6 }}
                    md={{ offset: 3, span: 12 }}
                    lg={{ offset: 3, span: 12 }}
                    xl={{ offset: 3, span: 12 }}
                    xxl={{ offset: 3, span: 12 }}
                  >
                    {sending ? (
                      <label className={css["lg-label"]}>
                        {t('forgotPassword')}
                      </label>
                    ) : (
                      <label
                        className={css["lg-label"]}
                        onClick={() => setMode(ACTION_MODE.RECOVER_PASSWORD)}
                      >
                        {t('forgotPassword')}
                      </label>
                    )}
                  </Col>
                </Row>
              </>
            ) : null}
            {mode === ACTION_MODE.RECOVER_PASSWORD ? (
              <>
                <Row>
                  {sending ? (
                    <label>{t('back')}</label>
                  ) : (
                    <label
                      className={css["back"]}
                      onClick={() => setMode(ACTION_MODE.LOGIN)}
                    />
                  )}
                </Row>
                <Row>
                  <label className={css.title}>{t('recoveryPassword')}</label>
                  <label className={css["lg-label-2"]}>
                    {t('messageLogin')}
                  </label>
                </Row>
                <Row>
                  <FloatingLabel
                    label={t('email')}
                    className={css.label_login}
                  >
                    <Form.Control
                      type="email"
                      placeholder={t('email')}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={sending}
                      className={css.input_correo}
                    />
                  </FloatingLabel>
                </Row>
                <Row>
                  <Col
                    xs={{ offset: 4, span: 4 }}
                    sm={{ offset: 4, span: 4 }}
                    md={{ offset: 4, span: 4 }}
                    lg={{ offset: 4, span: 4 }}
                    xl={{ offset: 4, span: 4 }}
                    xxl={{ offset: 4, span: 4 }}
                  >
                    <Button onClick={recoverPassword} disabled={sending}>
                      {t('send')}
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
          <Col
            className={css.register}
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
          >
            <Row>
              <label className={css.registertitle}>
                {t('joinInmed')}
              </label>
              <label className={css.unir}>
               {t('joinInmedText')}
              </label>
            </Row>
            <Row>
              <Col
                xs={{ offset: 4, span: 4 }}
                sm={{ offset: 4, span: 4 }}
                md={{ offset: 4, span: 4 }}
                lg={{ offset: 4, span: 4 }}
                xl={{ offset: 4, span: 4 }}
                xxl={{ offset: 4, span: 4 }}
              >
                <Button
                  disabled={sending}
                  onClick={() =>
                    window.open("https://forms.gle/Ryc2su91zj7oiQck7", "_blank")
                  }
                >
                  {t('join')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      {warning?.show && (
        <Message
          show={warning?.show}
          message={warning?.message}
          hide={() => setWarning({ show: false })}
        />
      )}
      {popMessageRecover && (
        <PopMessageRecover
          show={popMessageRecover}
          onHide={() => setPopMessageRecover(false)}
          email={email}
        />
      )}
    </>
  );
}

export default function Login(): JSX.Element {
  const { state } = useAuth();

  const [isByPatient, setIsByPatient] = useState(true);
  const { i18n, t } = useTranslation()

  useEffect(() => {
    if (state?.isLoged) window.location.href = "/";
  }, [state?.isLoged]);
  return (
    <Container
      fluid
      className={`${
        isByPatient ? css.background_patient : css.background_specialist
      }`}
    >
      <Row className={`${!isByPatient ? "specialistActive" : ""}`}>
        <Col>
          <button
            className={`${css.btnLogin} ${isByPatient ? "loginactive" : ""}`}
            onClick={() => setIsByPatient(true)}
            type="button"
          >
            {t('patient')}
          </button>
          <button
            className={`${css.btnLogin} ${!isByPatient ? "loginactive" : ""}`}
            onClick={() => setIsByPatient(false)}
            type="button"
          >
            {t('specialist')}
          </button>
        </Col>
      </Row>
      {isByPatient && <ByPatient />}
      {!isByPatient && <BySpecialist />}
    </Container>
  );
}
