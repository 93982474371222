import { useEffect, useState } from "react";
import HttpService from "../../../../../../../services/http";

export interface IGender{
  id: number;
  name: string;
}

export default function useFetchGender() {
  const [gender, setGender] = useState<IGender[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    HttpService.post("domains/getDomainForType", { id: 3 })
      .then((result) => {
        const data = result?.data as IGender[];
        setGender(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return { gender, loading };
}
