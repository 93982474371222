import { useEffect, useState } from "react";
import { IAppointmentType } from "./useFetchAppointmentTypeSpecialist";
import HttpService from "../../../../../../../services/http";

export default function useFetchAppointmentTypes() {
  const [appointmentTypes, setAppointmentTypes] = useState<IAppointmentType[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    HttpService.get("appointment/getTypeAppointmentList")
      .then((result) => {
        const data = result?.data as IAppointmentType[];
        setAppointmentTypes(data);
        setLoading(false);
      })
      .catch(() => {
        setAppointmentTypes([]);
        setLoading(true);
      });
  }, []);

  return { appointmentTypes, loading };
}
