import { useEffect, useState } from "react";
import HttpService from "../../../../../../../services/http";
import { ICurrencyType } from "./useFetchCurrencyType";

export interface IAppointmentTypeSpecialist {
  id: number;
  specialist_id: number;
  appointment_type_id: number;
  appointments_type_id: IAppointmentType;
  duration: number;
  price: number;
  currency_type: number;
  currencys_type: ICurrencyType;
  principal_appointment: boolean;
}

export interface IAppointmentType {
  id: number;
  name: string;
}

export default function useFetchAppointmentTypeSpecialist(
  specialist_id: number | null | undefined,
  refresh: boolean
) {
  const [appointmentTypesSpecialist, setAppointmentTypesSpecialist] = useState<
    IAppointmentTypeSpecialist[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let activo = true;
    if (activo)
      HttpService.post("specialists/getTypeAppointment", { specialist_id })
        .then((result) => {
          const data = result?.data as IAppointmentTypeSpecialist[];
          setAppointmentTypesSpecialist(data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    return () => {
      activo = false;
    };
  }, [specialist_id, refresh]);

  return { appointmentTypesSpecialist, loading };
}
