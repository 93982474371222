import { PropsWithChildren } from "react";

import { AuthMiddleware } from "../middlewares/auth";

export default function ContextPage({ children }: PropsWithChildren): JSX.Element {
  return (
    <AuthMiddleware>
      <>
        {children}
      </>
    </AuthMiddleware>
  );
}
