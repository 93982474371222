import React, { useContext, useEffect, useState } from "react";
import HttpService from "../../services/http";
import DataTable from "../../components/DataTable/DataTable";
import { GridValueGetterParams } from "@mui/x-data-grid";
import SearchForm from "./FormviewPay";
import { AuthContext } from "../../middlewares/auth";
import { useTranslation } from "react-i18next";

export const allColumns = {
  "appointment.patient": true,
};
export const patientColumns = {
  "appointment.patient": false,
};
const ViewPay = () => {
  
  const context = useContext(AuthContext);
  const { i18n, t } = useTranslation()
  const [rowData, setRowData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState<{
    field: string;
    value: string;
  } | null>(null);
  
  const [columnVisible, setColumnVisible] = React.useState(allColumns);
  
  function formaterNumberToCurrency(number: number | string): string {
    const formatter = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 2,
    });

    return formatter.format(Number(number));
  }
  useEffect(() => {
    const fetchData = async () => {
      const info = {
        idUser: context.state?.userId,
      };
      try {
        const response = await HttpService.post(
          "payments/paymentsPaginated",
          info
        );
        const { data } = response;
        setOriginalData(data);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching payment data:", error);
      }
    };
    if(context.state?.patient){
      setColumnVisible(patientColumns)
    }

    fetchData();
  }, [context]);

  const columns = [

    { field: "referenceCode", headerName: `${t('paymentReference')}`, width: 150 },
    {
      field: "payment_method_id_local",
      headerName: `${t('paymentType')}`,
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.appointment.electronic_payment ? "Pasarela de pago" : "En sitio"}`;
      },
    },
    {
      field: "appointment.patient",
      headerName: `${t('patient')}`,
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.appointment.patient.first_name} ${params.row.appointment.patient.last_name}`;
      },
    },
    {
      field: "datetime",
      headerName: `${t('date')}`,
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return `${new Date(params.row.datetime).getDate()}/${new Date(params.row.datetime).getMonth() + 1
          }/${new Date(params.row.datetime).getFullYear()}`;
      },
    },
    {
      field: "amount",
      headerName: `${t('amount')}`,
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return `${formaterNumberToCurrency(params.row.amount)}`;
      },
    },
    {
      field: "state_pol",
      headerName: `${t('paymentStatus')}`,
      width: 150,
    },
    {
      field: "appointment.specialist.name",
      headerName: `${t('specialist')}`,
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.appointment.specialist.name} ${params.row.appointment.specialist.surname}`;
      },
    },
    {
      field: "appointment.specialistAppointmentType.appointments_type_id.name",
      headerName: `${t('appointmentType')}`,
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.appointment.specialistAppointmentType.appointments_type_id.name}`;
      },
    },
    {
      field: "appointment.start_datetime",
      headerName: `${t('bookingDate')}`,
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return `${new Date(
          params.row.appointment.start_datetime
        ).toLocaleString()}`;
      },
    },
    {
      field: "appointment.appointment_status.name",
      headerName: `${t('bookingStatus')}`,
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.appointment.appointment_status.name}`;
      },
    },
  ];

  
  
  const handleSearch = ({ field, value }: { field: string; value: string }) => {
    const newFilteredData = originalData.filter((item) =>
      (item[field] as string)
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    );

    setFilteredData(newFilteredData);
    setSearchCriteria({ field, value });
  };
  return (
    <div className="data-table-view-pay">
      <div className="container-form">
        <SearchForm
          fields={columns.map((column) => {
            return { value: column.field, name: column.headerName };
          })}
          onSearch={handleSearch}
        />
      </div>
      <DataTable columns={columns} data={filteredData} visibility={columnVisible} width="78vw" height="70vh"/>
    </div>
  );
};

export default ViewPay;
