import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CalendarEvents } from "../types/calendar";
import HttpService from "./http";

function useCalendarEvents({
  specialistId,
  date,
  viewDate,
  appointmentType,
  patientDocument,
  refresh,
}: {
  specialistId: number | null;
  date: dayjs.Dayjs | null;
  viewDate: dayjs.Dayjs | null;
  patientDocument: string | null;
  appointmentType: string | null;
  refresh: boolean;
}) {
  const [eventsSource, setEventsSource] = useState<any[]>([]);
  const [startOfMonth, setStartOfMonth] = useState<dayjs.Dayjs | null>(null);

  useEffect(() => {
    if (
      specialistId &&
      date &&
      date?.get("month") !== startOfMonth?.get("month")
    )
      setStartOfMonth(date);
  }, [specialistId, date]);

  useEffect(() => {
    if (
      specialistId &&
      viewDate &&
      viewDate?.get("month") !== startOfMonth?.get("month")
    )
      setStartOfMonth(viewDate);
  }, [specialistId, viewDate]);

  useEffect(() => {
    getAppointments(
      specialistId,
      startOfMonth,
      patientDocument,
      appointmentType
    );
    const newIntervalId = setInterval(() => {
      getAppointments(
        specialistId,
        startOfMonth,
        patientDocument,
        appointmentType
      );
    }, 60000);
    return () => clearInterval(newIntervalId);
  }, [specialistId, startOfMonth, patientDocument, appointmentType, refresh]);

  function getAppointments(
    specialistId: number | null,
    startOfMonth: dayjs.Dayjs | null,
    patientDocument: string | null,
    appointmentType: string | null
  ) {
    if (!specialistId || !startOfMonth) return;
    setEventsSource([]);
    HttpService.post("specialist/getAppointments", {
      specialistId,
      date: startOfMonth?.format("YYYY-MM-DD"),
      patientDocument,
      appointmentType,
    }).then((result) => {
      const data = result?.data as CalendarEvents[];
      setEventsSource(mapEventsSource(data));
    });
  }

  return { eventsSource };
}

function mapEventsSource(appoinments: CalendarEvents[]): any[] {
  const eventsSource: any[] = appoinments.map(
    ({
      id,
      start_datetime,
      end_datetime,
      patient,
      specialistAppointmentType,
    }) => ({
      _id: id,
      id: `${id}`,
      start: start_datetime,
      end: end_datetime,
      backgroundColor:
        specialistAppointmentType?.appointments_type_id?.config?.calendarOptions
          ?.backgroundColor ?? "#13416b",
      textColor:
        specialistAppointmentType?.appointments_type_id?.config?.calendarOptions
          ?.textColor ?? "#ffffff",
      AppointmentType: specialistAppointmentType.appointments_type_id.name,
      AppointmentTypeId: specialistAppointmentType.id,
      patient,
      title: `${patient.first_name} ${patient.last_name}`,
    })
  );
  return eventsSource.filter((value) => value !== null);
}

export { useCalendarEvents };
