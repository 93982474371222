import React, { useEffect, useState } from "react";
import { ListGroup, Form, Button, Row, Col, Container } from "react-bootstrap";
import { usePanelPage } from "../../../../panelPage";
import { useAuth } from "../../../../../../middlewares/auth";
import { IMessage, Message } from "../../../../../../components/message";
import { IStudy,ISpecialist } from "../../../../../../interfaces/specialist/specialist.interface";

const StudySection = ({ studys, setStudys,idSpecialist }: { studys: IStudy[],setStudys:any,idSpecialist:any }) => {
  const [newStudy, setNewStudy] = useState("");

 
  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  const handleAddStudy = () => {
    if (newStudy.trim() !== "") {
      // Comprueba si el estudio ya existe
      const studyExists = studys?.some(
        (study) => study.name === newStudy.trim()
      );
  
      if (!studyExists) {
        // Asignar un valor predeterminado a specialist_id si es nulo o indefinido
        const specialistId = idSpecialist ?? null;
  
        const updatedStudys = [
          ...studys,
          {
            id: -1,
            name: newStudy.trim(),
            specialist_id: specialistId,
          },
        ];
        setStudys(updatedStudys);
        setNewStudy("");
      } else {
        setMessage({ show: true, message: "¡El estudio ya existe!" });
      }
    } else {
      setMessage({ show: true, message: "Por favor, ingrese un estudio válido" });
    }
  };

  const handleRemoveStudy = (id: number) => {
    const updatedStudys = studys?.filter((study) => study.id !== id);
    setStudys(updatedStudys);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewStudy(e.target.value);
  };

  return (
    <>
    <fieldset className="border p-2 mt-3 mb-3">
      <legend className="w-auto">Estudios</legend>
      <Row>
        <Col xs={10} sm={10} className="pt-2">
          <Form.Control
            type="text"
            placeholder="Nueva estudio"
            value={newStudy}
            onChange={handleChange}
          />
        </Col>
        <Col xs={2} sm={2}>
          <button title="Agregar" className="btnadd" onClick={handleAddStudy}>
            <i className="bi bi-node-plus-fill"></i>
          </button>
        </Col>
      </Row>

      <ListGroup className="profilecontainer">
                {studys.map((study) => (
          <ListGroup.Item key={study.id} className="pt-1">
            <Row>
              <Col xs={10} sm={10}>
                {study.name}
              </Col>
              <Col xs={2} sm={2}>
                <button
                  title="Eliminar"
                  className="btndelete"
                  onClick={() => handleRemoveStudy(study.id ?? 0)}
                >
                  <i className="bi bi-trash3"></i>
                </button>
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </fieldset>
     {message?.show ? (
        <Message
          show={message?.show}
          message={message?.message}
          hide={() => setMessage((prev) => ({ ...prev, show: false }))}
        />
      ) : null}
      </>
  );
};
export default StudySection;
