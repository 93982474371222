import { useEffect, useState } from "react";
import HttpService from "../../../services/http";
import { Meta } from "../../../types/http";
import { IProcedure } from "../interface";

export function useFetchProcedures(
  search: number,
  name: string,
  types: string,
  orderBy: string,
  page: number,
  limit: number
) {
  const [procedures, setProcedures] = useState<IProcedure[]>([]);
  const [meta, setMeta] = useState<Meta>({
    current_page: 1,
    first_page: 1,
    first_page_url: "/?page=1",
    last_page: 1,
    last_page_url: "/?page=1",
    next_page_url: "/?page=1",
    per_page: 12,
    previous_page_url: "/?page=1",
    total: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let sending = true;
    if (sending) {
      setLoading(true);
      HttpService.postPaginate(`procedures/getListPaginate`, {
        name,
        types,
        orderBy,
        page,
        limit,
      })
        .then((result) => {
          const info = result?.data || {};
          const { data, meta } = info;
          setProcedures(data);
          setMeta(meta);
        })
        .finally(() => setLoading(false));
    }
    return () => {
      sending = false;
    };
  }, [search, types, orderBy, page, limit]);

  return { procedures, meta, loading };
}
