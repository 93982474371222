import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { IExcludedDays } from "../../../interfaces/schedule/schedule.interface";
import HttpService from "../../../services/http";
import useFetchUnworkingDays from "../../share/hooks/useExcludeDays";

function disabledDatesCalendar(excludedDaysCalendar: IExcludedDays[] | []) {
  const disabledDates: string[] = [];
  excludedDaysCalendar.forEach((excludedDay) => {
    const { startDay, endDay } = excludedDay;
    const startDate = dayjs(startDay);
    const endDate = endDay ? dayjs(endDay) : startDate;
    let currentDate = startDate;
    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, "day")
    ) {
      disabledDates.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }
  });
  return disabledDates;
}

export default function useFetchCalendar({
  id,
  withFirstDateAvailable,
}: {
  id?: string;
  withFirstDateAvailable: boolean;
}) {
  const today = dayjs();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [excludedDays, setExcludedDays] = useState<string[]>([]);
  const [excludedDaysOfWeek, setExcludedDaysOfWeek] = useState<number[]>([]);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
  const [selectMonth, setSelectMonth] = useState<number | null>(
    today.month() + 1
  );
  const [selectYear, setSelectYear] = useState<number | null>(today.year());
  const [availableSlot, setAvailableSlot] = useState<string[]>([]);

  // useEffect(() => {
  //   if (id) {
  //     if (withFirstDateAvailable && selectMonth && selectYear && !selectedDate)
  //       HttpService.get(`first-work-day/${id}`).then((result) => {
  //         if (result?.status && result?.data)
  //           setSelectedDate(dayjs(result?.data));
  //         // "2024-05-24"
  //       });
  //   }
  // }, [id, withFirstDateAvailable, selectMonth, selectYear, selectedDate]);

  // useEffect(() => {
  //   if (id) {
  //     setLoading(true);
  //     HttpService.get(`unworkingDays/${id}`)
  //       .then((result) => {
  //         const { status, data } = result;
  //         if (status) {
  //           setExcludedDaysOfWeek(data.map((weekday: number) => weekday % 7));
  //           if ([1, 2, 3, 4, 5, 6].some((value) => data.includes(value)))
  //             setSelectedDate(null);
  //           else setSelectedDate(today);
  //         }
  //         setLoading(false);
  //       })
  //       .catch(() => setError(true));
  //   }
  // }, [id]);
  const handleData = useCallback((data: number[]) => {
    setExcludedDaysOfWeek(data.map((weekday: number) => weekday % 7));
    if ([1, 2, 3, 4, 5, 6].some((value) => data.includes(value))) {
      setSelectedDate(null);
    } else {
      setSelectedDate(today);
    }
  }, [id]);
  const propsUnworkingDays = {
    specialistId: Number(id),
    callback: handleData,
  }
  const { data } = useFetchUnworkingDays(propsUnworkingDays);

  useEffect(() => {
    if (data && !handleData) {
      setExcludedDaysOfWeek(data.map((weekday: number) => weekday % 7));
      if ([1, 2, 3, 4, 5, 6].some((value) => data.includes(value))) {
        setSelectedDate(null);
      } else {
        setSelectedDate(today);
      }
    }
  }, [data, handleData]);

  useEffect(() => {
    if (id && selectMonth) {
      setLoading(true);
      HttpService.get(
        `specialists/${id}/unavailable-dates/${selectMonth}/${selectYear}/`
      )
        .then((result) => {
          const { status } = result;
          if (status) {
            const data = result?.data as string[];
            setExcludedDays(data);
          }
          setLoading(false);
        })
        .catch(() => setError(true));
    }
  }, [id, selectMonth]);

  useEffect(() => {
    if (id && selectedDate) {
      setLoading(true);
      HttpService.get(
        `available-slots/${selectedDate?.format("YYYY-MM-DD")}/${id}/1`
      )
        .then((result) => {
          const { status, data } = result;
          if (status) setAvailableSlot(data);
          else setAvailableSlot([]);
          setLoading(false);
        })
        .catch(() => setError(true));
    }
  }, [selectedDate, id]);

  return {
    excludedDays,
    excludedDaysOfWeek,
    selectedDate,
    setSelectedDate,
    setSelectMonth,
    setSelectYear,
    selectMonth,
    today,
    availableSlot,
    loading,
    error,
  };
}
