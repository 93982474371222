import { Button, Carousel, Col, Container, Figure, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchProcedure } from "./hooks/useFetchProcedure";
import { IImageVideo } from "./interface";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useFetchSpecialists } from "./hooks/useFetchSpecialists";
import css from "./procedures.module.css";
import { useTranslation } from "react-i18next";


function Procedurepage(): JSX.Element {
  const { i18n, t } = useTranslation()
  const { id } = useParams();
  const navigate = useNavigate();

  const { procedure, loading } = useFetchProcedure(id);

  const [page, setPage] = useState<number>(1);

  const { specialists, meta } = useFetchSpecialists(id, page);

  
  useEffect(() => {
    const interval = setTimeout(() => {
      setPage(
        page < meta?.last_page ? page + 1 : page === meta?.last_page ? 1 : 1
      );
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  });

  if (loading)
    return (
      <Container>{t('loginToContinuePayment')}
        <h2></h2>
      </Container>
    );

  return (
    <Container className="mt-4" style={{minHeight:"60vh"}}>
      <Row>
        <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
          <Row>
            <h3 className={css.procedute_title}><i className="bi bi-info-circle-fill"></i>     {procedure?.name}</h3>
          </Row>
          <Row>
          <div className="p-4" dangerouslySetInnerHTML={{ __html: procedure && procedure.descripcion ? procedure.descripcion : `${t('noInformation')}` }} />
          </Row>
          {procedure?.images && procedure?.images?.length > 0 && (
            <Row>
              <h2>{t('images')}</h2>
              <CarouselChunked
                items={procedure?.images}
                chunkSize={4}
                isImage
                interval={1000}
              />
            </Row>
          )}
          {procedure?.video && procedure?.video?.length > 0 && (
            <Row>
              <h2>{t('videos')}</h2>
              <CarouselChunked items={procedure?.video} chunkSize={2} isVideo />
            </Row>
          )}
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
          {specialists ? <h5>{t('knowMoreAboutOurSpecialists')}</h5> : null}
          {specialists.map((specialist) => (
            <Row key={specialist.id} className={css.specialist}>
              <Col xs={4} sm={4} md={12} lg={12} xl={4} xxl={4} >
                <Figure>
                  <Figure.Image className={css.specialist_photo} src={specialist.photo || ""} loading="lazy" />
                  <div>
                    {new Array(5)
                      .fill(0)
                      .map((_, index) => (
                        <span key={index}><i className="bi bi-star-fill" style={{color:"#7b23ef"}}></i></span>
                      ))}
                  </div>
                </Figure>
              </Col>
              <Col xs={8} sm={8} md={12} lg={12} xl={8} xxl={8}>
                <h6>{`${specialist.name} ${specialist.surname}`}</h6>
                <label>{`${specialist.short_description || ""}`}</label>
                <label>{`${specialist.office_address || ""}`}</label>
                <div>
                  <Button className="mt-2"    onClick={() =>
                  navigate(`/specialist/${specialist.id}`, {
                    preventScrollReset: true,
                  })
                }>{t('viewProfile')}</Button>
                </div>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

interface CarouselChunkedProps {
  items: IImageVideo[];
  chunkSize: 2 | 4;
  isImage?: boolean;
  isVideo?: boolean;
  interval?: number | null;
}

function CarouselChunked({
  items,
  chunkSize,
  isImage,
  isVideo,
  interval = null,
}: CarouselChunkedProps): JSX.Element {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [itemsChunked, setItemsChunked] = useState<IImageVideo[][]>([]);

  useEffect(() => {
    const data = [];
    for (let iterator = 0; iterator < items.length; iterator += chunkSize) {
      const chunk = items.slice(iterator, iterator + chunkSize);
      data.push(chunk);
    }
    setItemsChunked(data);
  }, [items, chunkSize]);

  return (
    <Carousel
      activeIndex={activeIndex}
      onSelect={(selectedIndex) => setActiveIndex(selectedIndex)}
      interval={interval}
    >
      {itemsChunked.map((chunk, index) => (
        <Carousel.Item key={index}>
          <Chunk
            items={chunk}
            span={chunkSize === 4 ? 3 : 6}
            isImage={isImage}
            isVideo={isVideo}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

interface ChunkProps {
  items: IImageVideo[];
  span: 3 | 6;
  isImage?: boolean;
  isVideo?: boolean;
}

function Chunk({ items, span, isImage, isVideo }: ChunkProps): JSX.Element {
  return (
    <Row>
      {items.map((chunk) => (
        <Col
          key={chunk?.id}
          xs={span}
          sm={span}
          md={span}
          lg={span}
          xl={span}
          xxl={span}
        >
          <Figure>
            {isImage && <Figure.Image src={chunk?.url || ""} loading="lazy" />}
            {isVideo && <ReactPlayer url={chunk?.url || ""} />}
          </Figure>
        </Col>
      ))}
    </Row>
  );
}

export default Procedurepage;
