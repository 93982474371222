import { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { IExcludedDays } from "../../../../../../../../interfaces/schedule/schedule.interface";
import { ScheduleContext, checkDateInRange } from "../../schedule";
import { IMessage, Message } from "../../../../../../../../components/message";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import "dayjs/locale/es";
dayjs.extend(isBetween);

export default function ExcludedDays(): JSX.Element {
  const {
    excludedDays,
    setExcludedDays,
  }: {
    excludedDays: IExcludedDays[];
    setExcludedDays: React.Dispatch<React.SetStateAction<IExcludedDays[]>>;
  } = useContext(ScheduleContext);

  const [add, setAdd] = useState<boolean>(false);
  const [byRange, setByRange] = useState<boolean>(false);
  const [startDay, setStartDay] = useState<string>("");
  const [endDay, setEndDay] = useState<string>("");

  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  useEffect(() => {
    setByRange(false);
    setStartDay("");
    setEndDay("");
  }, [add]);

  useEffect(() => {
    setEndDay("");
  }, [byRange]);

  useEffect(() => {
    if (!byRange) setEndDay(startDay);
  }, [startDay, byRange]);

  function handleAdd() {
    if (startDay !== "") {
      const copyExcludedDays: IExcludedDays[] = JSON.parse(
        JSON.stringify(excludedDays)
      );
      if (
        copyExcludedDays.some(
          (excludedDay) =>
            checkDateInRange(
              startDay,
              excludedDay?.startDay,
              excludedDay?.endDay
            ) ||
            checkDateInRange(endDay, excludedDay?.endDay, excludedDay?.endDay)
        )
      ) {
        setMessage({
          show: true,
          message:
            endDay === ""
              ? `El día ${startDay} ya se encuentra excluido.`
              : `Las fechas ${startDay} y ${endDay} ya se encuentran excluidas en rango.`,
        });
        return;
      }
      copyExcludedDays.push({ startDay, endDay });
      setExcludedDays(copyExcludedDays);
      setAdd(false);
    }
  }

  function handleDelete(indexDate: number) {
    const copyExcludedDays: IExcludedDays[] = JSON.parse(
      JSON.stringify(excludedDays)
    );
    setExcludedDays(
      copyExcludedDays?.filter(({}, index) => index !== indexDate)
    );
  }

  const handleClose = () => setAdd(false);
  return (
    <>
      <Container>
        <div className="contain_add">
          <h6>Excluir días</h6>
          {!add ? (
            <button
              title="Agregar configuración"
              className="btnadd"
              onClick={() => setAdd(true)}
            >
              <i className="bi bi-node-plus-fill"></i>
            </button>
          ) : null}
        </div>

        <Modal show={add} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="h6">Días a excluir</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup>
              <Form.Check
                type="switch"
                checked={byRange}
                onChange={(event) => setByRange(event.target.checked)}
              />
              <Form.Label>Rango</Form.Label>
            </InputGroup>
            <Row>
              <Col>
                <Form.Label>{!byRange ? "Fecha" : "Desde"}</Form.Label>
                <Form.Control
                  type="date"
                  max={add && endDay !== "" ? endDay : ""}
                  value={startDay}
                  onChange={(event) => setStartDay(event.target.value)}
                />
              </Col>
              {byRange ? (
                <Col>
                  <Form.Label>Hasta</Form.Label>
                  <Form.Control
                    type="date"
                    min={add && startDay !== "" ? startDay : ""}
                    value={endDay}
                    onChange={(event) => setEndDay(event.target.value)}
                  />
                </Col>
              ) : null}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleAdd}>Confirmar</Button>
          </Modal.Footer>
        </Modal>

        <Table responsive bordered className="tableschedule">
          <thead>
            <tr>
              <th>Desde</th>
              <th>Hasta</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {excludedDays?.map(({ id, startDay, endDay }, indexDate) => (
              <tr key={indexDate}>
                <td>
                  <Form.Label>{startDay}</Form.Label>
                </td>
                <td>
                  <Form.Label>{endDay}</Form.Label>
                </td>
                <td>
                  <button
                    className="btndelete"
                    disabled={add}
                    title="Eliminar configuración"
                    onClick={() => handleDelete(indexDate)}
                  >
                    <i className="bi bi-trash3"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>

      {message?.show ? (
        <Message
          show={message?.show}
          message={message?.message}
          hide={() => setMessage((prev) => ({ ...prev, show: false }))}
        />
      ) : null}
    </>
  );
}
