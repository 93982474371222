import { useEffect, useState } from "react";
import { Col, Nav, NavDropdown, Navbar, Row } from "react-bootstrap";
import { IWarning, Message } from "../../../components/message";
import { ISpecialist } from "../../../interfaces/specialist/specialist.interface";
import { useAuth } from "../../../middlewares/auth";
import HttpService from "../../../services/http";
import { usePanelPage } from "../panelPage";
import css from "./layou.module.css";
import { useTranslation } from "react-i18next";

function useFetchSpecialists(
  userId: number | undefined,
  isSecretary: boolean | null | undefined
) {
  const [specialists, setSpecialists] = useState<ISpecialist[]>([]);

  useEffect(() => {
    if (userId && isSecretary) {
      HttpService.post("secretaries/getSpecialists", { userId }).then(
        (result) => {
          if (result.status) {
            const data = result?.data as ISpecialist[];
            setSpecialists(data);
          } else setSpecialists([]);
        }
      );
    } else setSpecialists([]);
  }, [userId, isSecretary]);

  return { specialists };
}

function useFetchPhoto(
  userId: number | undefined,
  UserType: number | null | undefined
) {
  const [routePhoto, setRoutePhoto] = useState("");

  useEffect(() => {
    if (userId && UserType) {
      HttpService.post("user/getUserPhoto", { userId, UserType }).then(
        (result) => {
          if (result.status) {
            const data = result?.data;
            setRoutePhoto(data?.photo);
          } else setRoutePhoto("");
        }
      );
    } else setRoutePhoto("");
  }, [userId, UserType]);

  return { routePhoto };
}

function Bell(): JSX.Element {
  const { countNotifications, setIsShowNotifications } = usePanelPage();
  return (
    <i className="bi bi-bell-fill icon-notification" onClick={() => setIsShowNotifications(true)}>
      <span>{`${countNotifications}${countNotifications > 1 ? "+" : ""}`}</span>
    </i>
  );
}

export default function Header(): JSX.Element {
  const { state, logOut } = useAuth();

  const { specialistSelected, setSpecialistSelected } = usePanelPage();
  const [sending, setSending] = useState<boolean>(false);
  const { i18n, t } = useTranslation()
  const { specialists } = useFetchSpecialists(state?.id, state?.secretary);

  const idSearch: string | undefined = state?.secretary
    ? state?.userId?.toString()
    : state?.id?.toString();
  const { routePhoto } = useFetchPhoto(parseInt(idSearch || ""), 1);

  const [warning, setWarning] = useState<IWarning>({
    show: false,
    message: "",
  });

  useEffect(() => {
    if (state?.specialist) setSpecialistSelected(state?.id || null);
  }, [state?.specialist]);

  useEffect(() => {
    if (state?.secretary) {
      if (specialists?.length > 0)
        setSpecialistSelected(Number(specialists[0]?.id) ?? null);
      else setSpecialistSelected(null);
    }
  }, [specialists]);

  const [specialist, setSpecialist] = useState<ISpecialist | null>(null);

  useEffect(() => {
    setSpecialist(
      specialists?.find(({ id }) => id === specialistSelected) || null
    );
  }, [specialistSelected]);

  function logout() {
    setSending(true);
    HttpService.post("logout")
      .then((result) => {
        if (result?.status) logOut();
        setSending(true);
      })
      .catch(() => {
        setWarning({
          show: true,
          message: `${t('logoutError')}`,
        });
        setSending(false);
      });
  }

  return (
    <>
      <Navbar expand="lg" style={{ width: "100%" }} className={css.headerpanel}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Row>
            {state?.secretary ? (
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                className="pt-2"
              >
                <NavDropdown
                  className={css.itemspecialist}
                  title={
                    state?.specialist || state?.patient
                      ? state?.fullName
                      : `${t('selectSpecialist')}: 
                ${specialist?.name || ""} ${specialist?.surname || ""}`
                  }
                >
                  {specialists?.map(({ id, name, surname }) => (
                    <NavDropdown.Item
                      className="navSpecialist"
                      key={id}
                      onClick={() => setSpecialistSelected(Number(id))}
                    >{`${name} ${surname}`}</NavDropdown.Item>
                  ))}
                </NavDropdown>
              </Col>
            ) : (
              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} />
            )}
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              className="d-grid justify-content-end"
            >
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Bell />
                  <NavDropdown
                    title={state?.fullName}
                    id="basic-nav-dropdown"
                    style={{
                      paddingRight: "5px",
                      right: "3px",
                      left: "initial",
                    }}
                  >
                    <NavDropdown.Item onClick={logout} disabled={sending}>
                      <i className="bi bi-box-arrow-in-left" />
                     {t('logout')}
                    </NavDropdown.Item>
                  </NavDropdown>
                  <img
                    src={
                      routePhoto && routePhoto !== ""
                        ? routePhoto
                        : "/resources/images/usuario.png"
                    }
                    width="42"
                    height="42"
                    className="d-inline-block align-top rounded-circle"
                    alt="Profile"
                    style={{ marginRight: "30px" }}
                  />
                </Nav>
              </Navbar.Collapse>
            </Col>
          </Row>
        </Col>
      </Navbar>
      {warning?.show ? (
        <Message
          show={warning?.show}
          message={warning?.message}
          hide={() => setWarning({ show: false })}
        />
      ) : null}
    </>
  );
}
